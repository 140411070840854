import ChartContainer from "./ChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import AnnualBarChart from "../HighCharts/AnnualBarChart";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import {numeric} from "../../../utils/utils";
import {
    useLazyGetAccountNegotiationsCountChartQuery,
} from "../../../redux/api/chartsApiSlice";
import {SeriesOptionsType} from "highcharts";
import {selectUser} from "../../../redux/features/userSlice";

export default function AccountNegotiationsCountChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {

    const title = 'Conteggio trattative di account'
    const states = useSelector(selectOptions).states
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetAccountNegotiationsCountChartQuery()
    const [series, setSeries] = useState<SeriesOptionsType[]>([])
    const description = 'Il grafico mostra la distribuzione del conteggio delle trattative per mese nei diversi stati\n'

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group

    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (data)
            setSeries(data ? data.map(el => ({
                name: states.find(state => state.value === el.state)?.text,
                type: 'bar',
                data: el.premiums,
                color: states.find(state => state.value === el.state)?.color,
                isia: isia?.value
            })) : [])
    }, [data, isia?.value, states])

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    return <ChartContainer
        description={description}
        loading={isLoading || isFetching}
        className={props.className}
        title={title}
        buttons={[<DownloadButton key={'download-button'} chartRef={props.chartRef}/>]}>
        <AnnualBarChart
            chartRef={props.chartRef}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        }
                    }
                },
                tooltip: {
                    pointFormatter: function () {
                        const value = numeric(this.y ? this.y : 0)
                        //@ts-ignore
                        const name = this.name ? this.name : ''
                        //@ts-ignore
                        const symbol = this.color ? `<span style='color: ${this.color}'>●</span>` : ''
                        return `${symbol} ${name} <span style='font-weight: bolder'>${value} trattative</span>`
                    },
                    useHTML: true
                },
                series: series
            }}
        />
    </ChartContainer>
}