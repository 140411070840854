import {Button, Col, Modal, Row, Select} from "antd";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";
import React, {useEffect, useState} from "react";
import {NegotiationModel, UpdateNegotiationRequest} from "../../types/negotiations";
import {useUpdateNegotiationMutation} from "../../redux/api/negotiationsApiSlice";

type EditLobModalProps = {
    isOpen: boolean,
    onClose: () => void,
    negotiation: NegotiationModel,
    updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>
}

export default function EditLobModal({
                                         isOpen,
                                         onClose,
                                         negotiation,
                                         updateNegotiation
                                     }: EditLobModalProps) {


    const options = useSelector(selectOptions)
    const [lob, setLob] = useState<string>()
    const [selectLobOptions, setSelectLobOptions] = useState<{ label: string, value: string }[]>([])


    useEffect(() => {
        if (options) {
            const selectOptions = options.lobs.map(el => ({
                label: el.name,
                value: el.uuid
            }))
            if (selectOptions.length) {
                setSelectLobOptions(selectOptions)
                setLob(negotiation.lob.uuid)
            }
        }
    }, [negotiation.lob.uuid, options])

    return <Modal
        title="Modifica LOB"
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        footer={
            <Row align='middle' justify="space-between">
                <Col>
                    <Button onClick={onClose}>
                        Annulla
                    </Button>
                </Col>
                <Col>
                    <Button
                        type='primary'
                        onClick={() => {
                            // side-effect: set underwriter to null, uw assignment in the next states (not RORE)
                            updateNegotiation({underwriter: null, lob: lob})
                            onClose()
                        }}>
                        Modifica
                    </Button>
                </Col>
            </Row>
        }
    >
        <Select
            style={{width: '100%'}}
            options={selectLobOptions}
            value={lob}
            virtual={false}
            onSelect={(value: string) => {
                setLob(value)
            }}
        />
    </Modal>
}