import {
    Card,
    Col,
    Input,
    Row,
    Typography,
    Button,
    Divider,
    Space,
    Tag,
    message,
    Pagination,
    Skeleton,
    List
} from "antd";
import Title from "antd/lib/typography/Title";
import React, {useEffect, useState} from "react";
import {
    useCreateNegotiationMutation,
    useCreateNegotiationStatusTransitionMutation,
    useLazyGetNegotiationsQuery,
    useUpdateNegotiationMutation
} from "redux/api/negotiationsApiSlice";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {AssignNegotiationModal} from "components/negotiationForm/sidebars/status/modals/state";
import {NegotiationModel} from "types/negotiations";
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import debounce from "lodash/debounce";
import {getContrastYIQ} from "../../utils/utils";

export default function AssignNegotiationToUnderwriter() {
    const [negotiations, setNegotiations] = useState<{ count: number, results: NegotiationModel[] }>({
        count: 0,
        results: []
    })
    const [triggerGetNegotiations, {isLoading, isFetching}] = useLazyGetNegotiationsQuery()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debounceSetSearchTerm = debounce((value: string) => setSearchTerm(value), 1000)
    const [currentPage, setCurrentPage] = useState<number>(1);

    async function fetchNegotiationsToAssign(pagination: { pageSize: number, page?: number }, filters: { key: string, range: any[] }[]) {
        try {
            const res = await triggerGetNegotiations({pagination, filters}).unwrap()
            setNegotiations(res)
        } catch (e: any) {
            message.error('Impossibile scaricare le trattative da assegnare')
            console.error('fetchNegotiationsToAssign', e)
        }
    }

    useEffect(() => {
        if (currentPage === 1) {
            fetchNegotiationsToAssign({pageSize: 15}, [{key: 'state', range: [1]}, {
                key: 'search',
                range: [searchTerm]
            }])

        } else {
            setCurrentPage(1)
        }
    }, [])

    useEffect(() => {
        if (currentPage === 1) {
            const filters = searchTerm.length ? [{key: 'state', range: [1]}, {
                key: 'search',
                range: [searchTerm]
            }] : [{key: 'state', range: [1]}]
            fetchNegotiationsToAssign({pageSize: 15, page: currentPage}, filters)
        } else {
            setCurrentPage(1)
        }
    }, [searchTerm])

    useEffect(() => {
        const filters = searchTerm.length ? [{key: 'state', range: [1]}, {
            key: 'search',
            range: [searchTerm]
        }] : [{key: 'state', range: [1]}]
        fetchNegotiationsToAssign({pageSize: 15, page: currentPage}, filters)
    }, [currentPage])


    return <Card
        className={'negotiation-to-assign-cards'}
        headStyle={{border: "none", padding: 0, margin: 0}}
        bodyStyle={{
            margin: 0,
            padding: 0,
            width: '100%',
            backgroundColor: 'transparent',
            minHeight: '40rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}
        style={{
            backgroundColor: 'white',
            width: '100%',
            padding: '0 24px 24px',
            minHeight: '40rem',

        }}
        bordered={false}
        title={
            <Row justify={'space-between'}>
                <Col>
                    <Title level={4}>Assegna trattative ad underwriter</Title>
                </Col>
                <Col>
                    <Input.Search placeholder={'Filtra per contraente...'} enterButton defaultValue={searchTerm}
                                  onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}/>
                </Col>
            </Row>}>
        <div style={{flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <Skeleton loading={isLoading || isFetching} style={{minHeight: '37.5rem'}}>
            {negotiations && negotiations.results.length > 0 &&
                <List
                    grid={{gutter: 8, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 4}}
                    dataSource={negotiations?.results}
                    renderItem={el => <List.Item><NegotiationToAssign key={el.uuid} negotiation={el}
                                                                      negotiations={negotiations}
                                                                      setNegotiations={setNegotiations}/></List.Item>}/>
                }
            {!negotiations || negotiations.results.length === 0 &&
                <Typography.Text>Non sono presenti trattative da assegnare</Typography.Text>}
        </Skeleton>
        <Row style={{marginTop: '0.5rem', marginBottom: '0.5rem'}} justify={'end'}>
            {negotiations?.count ?
                <Pagination
                    pageSize={15}
                    simple
                    total={negotiations.count}
                    current={currentPage}
                    onChange={page => {
                        setCurrentPage(page)
                    }}
                />
                : ""}
        </Row>
        </div>
    </Card>
}


export function NegotiationToAssign({
                                        negotiation,
                                        negotiations,
                                        setNegotiations
                                    }: {
    negotiation: NegotiationModel, negotiations: { count: number, results: NegotiationModel[] }, setNegotiations: ({
                                                                                                                       results,
                                                                                                                       count
                                                                                                                   }: { results: NegotiationModel[], count: number }) => void
}) {
    const navigate = useNavigate()
    const [updateNegotiation, {isLoading: isUpdateNegotiationLoading}] = useUpdateNegotiationMutation()
    const [createStateTransition, {isLoading: isCreateStateTransitionLoading}] = useCreateNegotiationStatusTransitionMutation()
    const options = useSelector(selectOptions)
    const {pathname} = useLocation()
    
    const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false)


    async function handleCreateStateTransition(data: { state_to: number, reason?: string, notes?: string }, newNegotiation?: NegotiationModel) {

        try {
            const createTransitionResult = await createStateTransition({id: negotiation.uuid, data: data}).unwrap()
            message.success('Stato aggiornato')
            setNegotiations({
                results: negotiations.results.filter(neg => neg.uuid !== negotiation.uuid),
                count: negotiations.count - 1
            })
        } catch (e: any) {
            message.error('Impossibile aggiornare lo stato della trattativa')
            console.error('createStateTransition', e)

        }
    }

    async function updateNegotiationUnderwriter(underwriterId: string): Promise<NegotiationModel | undefined> {

        try {
            const updateResult = await updateNegotiation({
                id: negotiation.uuid,
                data: {underwriter: underwriterId}
            }).unwrap()
            return updateResult
        } catch (e: any) {
            message.error('Impossibile assegnare l\'underwriter')
            console.error('updateUnderwriter')
        }
    }


    return <div style={{backgroundColor: 'rgb(240,242,245)', maxHeight: '10rem', borderRadius: '12px'}}>
        <AssignNegotiationModal updateNegotiationUnderwriter={updateNegotiationUnderwriter}
                                handleCreateStateTransition={handleCreateStateTransition} negotiation={negotiation}
                                isOpen={isAssignModalOpen} onClose={() => setIsAssignModalOpen(false)}/>
        <Row align={'middle'} justify={'space-between'} style={{padding: '0.5rem 1rem'}}>
            <Col span={18}>
                <Title
                    ellipsis={true}
                    level={5}>{negotiation.contractor?.name}
                </Title>
            </Col>
            <Col>
                <Button style={{padding: 0, border: 0}} onClick={() => setIsAssignModalOpen(true)} type={'link'}>
                    Assegna
                </Button>
            </Col>

        </Row>
        <Divider style={{margin: 0, width: '100%'}}/>
        <Space direction={'vertical'} style={{width: '100%', padding: '1rem', cursor: 'pointer'}}
               onClick={() => navigate(`../negotiations/${negotiation.uuid}`, {state: {previous_path: pathname}})}>
            <Row justify={'space-between'}>
                <Col style={{width: '50%'}}>{negotiation?.broker?.name}</Col>
                <Col style={{width: '50%', textAlign: 'right'}} >
                    <Tag
                        color={options.lobs.find(lob => negotiation?.lob?.uuid === lob?.uuid)?.color}
                        style={{
                            width: '8rem',
                            height: '1.5rem',
                            borderRadius: '100px',
                            textAlign: 'center',
                            color: getContrastYIQ(options.lobs.find(lob => negotiation?.lob?.uuid === lob?.uuid)?.color || 'white')
                        }}>
                        {negotiation?.lob?.name}
                    </Tag>
                </Col>
            </Row>
            <Row justify={'space-between'}>
                <Col><Typography.Paragraph
                    type='secondary'>Decorrenza: {negotiation?.policy_effective_date ? dayjs(negotiation.policy_effective_date).format('DD/MM/YYYY') : 'Non definita'}</Typography.Paragraph></Col>
                <Col><Typography.Paragraph
                    type='secondary'>Scadenza: {negotiation?.policy_expiring_date ? dayjs(negotiation.policy_expiring_date).format('DD/MM/YYYY') : 'Non definita'}</Typography.Paragraph></Col>
            </Row>
        </Space>
    </div>
}