// Restrict value to be between the range [0, value]

import {States} from "../types";
import dayjs from "dayjs";

export const clamp = (value: number) => Math.max(0, value);

// Check if number is between two values
export const isBetween = (value: number, floor: number, ceil: number) =>
    value >= floor && value <= ceil;

// Format euro
export const euro = (value: number) => {
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
    });
    return formatter.format(value)
}

// Format percentage
export const percentage = (value: number) => {
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(value)
}

// Format percentage
export const precisePercentage = (value: number) => {
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 6
    });
    return formatter.format(value)
}

// Format numeric
export const numeric = (value: number) => {
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'decimal',
    });
    return formatter.format(value)
}

//used in normalize into antd Form.Item otherwise the rnf naturally pass the formatted string value to the form instance 
export const rnfToNumber = (value: string) => {
    return parseFloat(value.replace(".", "").replace('%', '').replace('€', ''))
}

export function roundNumber(x: number, decimals = 2) {
    return parseFloat(x.toFixed(decimals))
}

export const stateToColors: { [key: number]: { label: string, color: string } } = {
    0: { label: 'Rore', color: '--rore-color' },
    1: { label: 'Reported', color: '--reported-color' },
    2: { label: 'Assegnata', color: '--assigned-color' },
    3: { label: 'Working', color: '--working-color' },
    4: { label: 'Quoted', color: '--quoted-color' },
    5: { label: 'Declined', color: '--declined-color' },
    6: { label: 'Bound', color: '--bound-color' },
    7: { label: 'Not taken up', color: '--ntu-color' },
    8: { label: 'Draft', color: '--draft-color' },
    9: { label: 'Issued', color: '--issued-color' },
    10: { label: 'In rinnovo', color: '--renewing-color' },
    11: { label: 'Rinnovata', color: '--tbr-color' },
    12: { label: 'Referred', color: '--referred-color' },
    13: { label: 'Referral approved', color: '--referral-approved-color' },
    14: { label: 'Referral declined', color: '--referral-declined-color' },
    15: { label: 'Stornata', color: '--reversed-color' },
    16: { label: 'Non Rinnovata', color: '--expired-color' }
}

export function isNegotiationExpired(state: States, policyDate: dayjs.Dayjs) : boolean {
    return policyDate.isValid() && !(policyDate.isAfter(dayjs())) && [States.Rore, States.Reported, States. Assegnata, States. Working, States.Quoted, States.Referred].includes(state)
}

export function capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function getContrastYIQ(hexcolor: string){
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0,2),16);
    const g = parseInt(hexcolor.substr(2,2),16);
    const b = parseInt(hexcolor.substr(4,2),16);
    const yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}
