import {useParams} from "react-router-dom";
import {ChangeEventHandler, useCallback, useEffect, useMemo, useState} from 'react';

import {
    Col,
    Row,
    Card,
    Tag,
    Form,
    Input,
    Radio,
    Button,
    Select,
    Divider,
    InputNumber,
    Space,
    FormListFieldData,
    Tooltip,
    message,
    Modal
} from 'antd';

import {Breadcrumb, Layout, Menu, Typography} from 'antd';

import debounce from 'lodash.debounce'

import {useTranslation} from 'react-i18next';
import {CommonFormComponentProps} from "types/negotiations/components";
import {useDispatch, useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";

import {DeleteOutlined, PlusOutlined, DeleteFilled, CheckCircleOutlined} from '@ant-design/icons';
import {ReinsurerModel} from "types/reinsurer";
import {
    useAddReinsurerToNegotiationMutation,
    useCreateReinsurerMutation,
    useLazyGetNegotiationReinsurersQuery,
    useRemoveReinsurerFromNegotiationMutation,
    useUpdateReinsurerInNegotiationMutation
} from "redux/api/reinsurersApiSlice";
import {useCreateReinsurerBrokerMutation} from "redux/api/reinsurerBrokersApiSlice";
import {ReinsurerBrokerModel} from "types/reinsurerBroker";
import {
    NegotiationModel,
    NegotiationReinsurerFormModel, NegotiationReinsurerFormModelPut,
    NegotiationReinsurerModel,
    UpdateNegotiationRequest
} from "types/negotiations";
import _, {cloneDeep, isEqual, isNil} from 'lodash'
import {AntCurrencyFormatInput} from "components/inputNumber";
import {FormInstance} from "antd/es/form/Form";
import {normalizePercTo1, normalizePercTo100} from "utils/form/dataParser";
import CalculateButton from "../../buttons/CalculateButton";
import {ReinsurerBrokerSelect, ReinsurerSelect} from "../selects";
import TextArea from "antd/es/input/TextArea";

const {Header, Content, Footer, Sider} = Layout;
const {Title, Paragraph} = Typography
const {Option} = Select

export default function Reinsurance({
                                        forwaredRef,
                                        formInstance,
                                        debounceInputChange,
                                        negotiation,
                                        onFormValuesChange,
                                        setCalculateField,
                                        getCalculatedField,
                                        disabled
                                    }: CommonFormComponentProps) {

    const {reinsuranceTypes} = useSelector(selectOptions)
    const watchReinsuranceType = Form.useWatch('reinsurance_type', formInstance)

    const watchFixedReinsurancPremium = Form.useWatch('fix_reinsurance_premium', formInstance)

    const isaReinsuranceNetCalc = !isNil(watchFixedReinsurancPremium) ? 'Premio di riassicurazione fisso' : 'Premio imponibile nostra quota * %Riassicurazione - (1 - %Fee - % Provvigioni broker ) '


    return (
        <Card ref={forwaredRef} id="reinsurance" bordered={false} style={{
            // padding: '24px',
            borderRadius: '12px',
            width: '100%'
        }}>
            <Row>
                <Col>
                    <Title level={3}>
                        <CheckCircleOutlined/>
                    </Title>
                </Col>
                <Col style={{marginLeft: '12px'}}>
                    <Title level={3}>Riassicurazione</Title>
                </Col>
            </Row>
            <Form
                form={formInstance}
                disabled={disabled}
                name='reinsurance'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
            >

                <Form.Item style={{marginTop: '24px'}} label="Riassicurazione" name={'reinsurance_type'}>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder="Seleziona un tipo di riassicurazione"
                        optionFilterProp="children"
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {reinsuranceTypes.map(type => <Option key={type.value} value={type.value}>{type.text}</Option>)}
                    </Select>
                </Form.Item>

                {watchReinsuranceType === 'F' &&
                    <>
                      <ReinsurersAndFeesManager negotiation={negotiation}/>
                      <ReinsurerBrokerSelect fieldName="reinsurer_broker"
                                             label='Broker riassicurativo'
                                             disabled={false}
                                             onCreation={(value: string) => {
                                                 formInstance.setFieldsValue({reinsurer_broker: value})
                                                 debounceInputChange({reinsurer_broker: value})
                                             }}/>
                      <Row align='bottom' justify='space-between' style={{marginBottom: '24px'}}>
                        <Col xs={24} md={11}>
                          <Form.Item label="% Fee" name='reinsurer_broker_fee_perc'>
                            <AntCurrencyFormatInput
                                suffix={' %'}/>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={11}>
                          <Form.Item label="% Riassicurazione" name='reinsured_share_perc'>
                            <AntCurrencyFormatInput
                                suffix={' %'}/>
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item label="Premio di riassicurazione fisso" name='fix_reinsurance_premium'>
                            <AntCurrencyFormatInput/>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={11}>
                          <Tooltip placement='top' title='Premio riassicurazione netto * (1 - %Riassicurazione)'>
                            <Form.Item label="Ritenzione netta ISA" name='isa_net_retention'>

                              <AntCurrencyFormatInput
                                  disabled={getCalculatedField('isa_quoted_premium_share')}
                                  calculate={() => setCalculateField('isa_quoted_premium_share')}
                              />

                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={24} md={11}>
                          <Tooltip placement='top'
                                   title='(Premio riassicurazione netto * %Fee) / (1 - %Fee - %Provvigioni Broker)'>
                            <Form.Item label="Fee nostra quota" name='isa_fee'>
                              <AntCurrencyFormatInput
                                  disabled={getCalculatedField('isa_fee')}
                                  calculate={() => setCalculateField('isa_fee')}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={24}>
                          <Tooltip placement='top' title={isaReinsuranceNetCalc}>
                            <Form.Item label="Premio riassicurazione netto prov diretto e fee (nostra quota)"
                                       name='isa_reinsurance_net_premium_direct_and_fee'>
                              <AntCurrencyFormatInput
                                  disabled={getCalculatedField('isa_reinsurance_net_premium_direct_and_fee')}
                                  calculate={() => setCalculateField('isa_reinsurance_net_premium_direct_and_fee')}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={24}>
                          <Tooltip placement='top' title='Premio riassicurazione netto * (1 - %Riassicurazione)'>
                            <Form.Item label="Premio imponibile conservato" name='saved_premium'>
                              <AntCurrencyFormatInput
                                  disabled={getCalculatedField('saved_premium')}
                                  calculate={() => setCalculateField('saved_premium')}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={24}>
                          <Tooltip placement='top' title='Premio imponibile conservato + Fee nostra quota'>
                            <Form.Item label="Conservato + ceeding fee" name='saved_plus_ceeding_fee'>
                              <AntCurrencyFormatInput
                                  disabled={getCalculatedField('saved_plus_ceeding_fee')}
                                  calculate={() => setCalculateField('saved_plus_ceeding_fee')}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={24}>
                          <Form.Item label="Attachment point" name='attachment_point'>
                            <AntCurrencyFormatInput/>
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item label="Nota ai fini riassicurativi" name='reinsurance_notes'>
                            <TextArea autoSize={{minRows: 3}} placeholder="Nota ai fini assicurativi"/>
                          </Form.Item>
                        </Col>
                      </Row>


                    </>
                }
            </Form>
        </Card>

    )
}


export function ReinsurersAndFeesManager({negotiation}: { negotiation: NegotiationModel }) {
    const [negotiationReinsurersAndFees, setNegotiationReinsurersAndFees] = useState<NegotiationReinsurerModel[]>([])
    const [isCreateReinsurerOpen, setIsCreateReinsurerOpen] = useState<boolean>(false)

    const [createReinsurerRequest, {isLoading: isCreateReinsurerLoading}] = useCreateReinsurerMutation()
    const [getReinsurersAndFeesRequest, {isLoading: isGetReinsurersLoading}] = useLazyGetNegotiationReinsurersQuery()
    const [addReinsurerRequest, {isLoading: isAddReinsurerLoading}] = useAddReinsurerToNegotiationMutation()
    const [removeReinsurerRequest, {isLoading: isRemoveReinsurerLoading}] = useRemoveReinsurerFromNegotiationMutation()
    const [updateReinsurerRequest, {isLoading: isUpdateReinsurerLoading}] = useUpdateReinsurerInNegotiationMutation()

    const [notAvailableReinsurers, setNotAvailableReinsurers] = useState<string[]>([])

    const [totalFee, setTotalFee] = useState<number>(0)
    const buttonText = 'Aggiungi riassicuratore'

    useEffect(() => {
        setNotAvailableReinsurers(negotiationReinsurersAndFees.map(el => el.reinsurer.uuid))
    }, [negotiationReinsurersAndFees])

    const fetchNegotiationReinsurersAndFees = useCallback(async () => {
        try {
            const reinsurersAndFees = await getReinsurersAndFeesRequest({negotiation_id: negotiation.uuid}).unwrap()

            //normalize percs from 0-1 to 0-100
            setNegotiationReinsurersAndFees(reinsurersAndFees.map(randFee => ({
                ...randFee,
                fee: normalizePercTo100(randFee.fee || 0)
            })))
        } catch (e: any) {
            console.error('fetchNegotiationReinsurersAndFees', e)
            message.error('Impossibile scaricare i riassicuratori di questa trattativa')

        }
    }, [getReinsurersAndFeesRequest, negotiation.uuid])


    useEffect(() => {
        fetchNegotiationReinsurersAndFees()
    }, [fetchNegotiationReinsurersAndFees])

    useEffect(() => {
        setTotalFee(negotiationReinsurersAndFees.reduce((previous, current) => previous + current.fee, 0))
    }, [negotiationReinsurersAndFees])


    async function createReinsurer(name: string) {
        try {
            const newReinsurer = await createReinsurerRequest({name}).unwrap()

            return newReinsurer
        } catch (e: any) {
            console.error('createReinsurer', e)
            message.error('Impossibile creare il riassicuratore')
            return undefined
        }
    }


    async function addReinsurerToNegotiation(reinsurer: string, normalizedFee: number) {
        try {
            const newReinsurer = await addReinsurerRequest({
                negotiation_id: negotiation.uuid,
                data: {reinsurer, fee: normalizedFee}
            }).unwrap()
            setNegotiationReinsurersAndFees([
                ...negotiationReinsurersAndFees,
                {...newReinsurer, fee: normalizePercTo100(newReinsurer.fee || 0)}
            ])
            message.success('Riassicuratore aggiunto')
        } catch (e: any) {
            console.error('addReinsurerToNegotiation', e)
            message.error('Impossibile aggiungere il riassicuratore')
        }
    }

    async function removeReinsurerFromNegotiation(negotiationReinsurerId: string) {
        try {
            await removeReinsurerRequest({
                negotiation_id: negotiation.uuid,
                reinsurer_id: negotiationReinsurerId
            }).unwrap()
            setNegotiationReinsurersAndFees(negotiationReinsurersAndFees.filter(randFee => randFee.uuid !== negotiationReinsurerId))
            message.success('Riassicuratore eliminato')
        } catch (e: any) {
            console.error('removeReinsurerToNegotiation', e)
            message.error('Impossibile rimuovere il riassicuratore')
        }
    }

    async function updateReinsurerInNegotiation(negotiationReinsurerId: string, data: NegotiationReinsurerFormModelPut) {
        //reinsurers are always updated even if the total fee is > 100
        updateReinsurerRequest({
            negotiation_id: negotiation.uuid,
            reinsurer_id: negotiationReinsurerId,
            data
        }).unwrap().then((updatedReinsurer) => {
            message.success('Riassicuratore aggiornato')
            fetchNegotiationReinsurersAndFees()
        }).catch((error) => {
            message.error('Impossibile aggiornare il riassicuratore')
            const displayData = cloneDeep(negotiationReinsurersAndFees)
            const index = displayData.findIndex(itm => itm.reinsurer.uuid === data.reinsurer)
            displayData[index] = {...displayData[index], fee: normalizePercTo1(data.fee || 0)}
            setNegotiationReinsurersAndFees(displayData)
        })
    }


    return (
        <>
            <CreateReinsurerModal isOpen={isCreateReinsurerOpen} onClose={() => setIsCreateReinsurerOpen(false)}
                                  notAvailableReinsurers={notAvailableReinsurers}
                                  addReinsurer={addReinsurerToNegotiation} createReinsurer={createReinsurer}
                                  isCreateReinsurerLoading={isAddReinsurerLoading} totalFee={totalFee}/>
            <Row align='middle' justify='space-between' style={{marginBottom: '24px'}}>
                <Col>
                    <Title level={5} style={{margin: 0}}>Riassicuratori</Title>
                </Col>
                <Col>
                    <Button disabled={totalFee >= 100} icon={<PlusOutlined/>} type='primary'
                            onClick={() => setIsCreateReinsurerOpen(true)}>{buttonText}</Button>
                </Col>
            </Row>
            {totalFee > 100 &&
                <p style={{color: 'red', transition: 'ease-in'}}> La somma delle percentuali riassicuratore non possono
                  superare il 100%</p>}
            {negotiationReinsurersAndFees.length === 0 && <p style={{fontStyle: 'italic'}}>Nessun riassicuratore presente. Per aggiungerlo cliccare sul pulsante '{buttonText}'</p>}
            {negotiationReinsurersAndFees.map((reinsurerAndFees, idx) => (
                    <ReinsurerAndFeesItem key={idx} idx={idx}
                                          notAvailableReinsurers={notAvailableReinsurers}
                                          reinsurerAndFees={reinsurerAndFees}
                                          updateReinsurer={(data: NegotiationReinsurerFormModelPut) => updateReinsurerInNegotiation(reinsurerAndFees.uuid, data)}
                                          createReinsurer={createReinsurer}
                                          isCreateReinsurerLoading={isCreateReinsurerLoading}
                                          removeReinsurer={() => removeReinsurerFromNegotiation(reinsurerAndFees.uuid)}
                                          isRemoveReinsurerLoading={isRemoveReinsurerLoading}
                                          hasErrorOccourred={totalFee > 100}
                    />
                )
            )}
            <Divider/>
        </>
    )
}

type CreateReinsurerModalProps = {
    isOpen: boolean,
    onClose: () => void,
    notAvailableReinsurers: string[],
    addReinsurer: (reinsurer: string, normalizedFee: number) => void,
    createReinsurer: (name: string) => Promise<ReinsurerModel | undefined>,
    isCreateReinsurerLoading: boolean,
    totalFee: number
}

export function CreateReinsurerModal({
                                         isOpen,
                                         onClose,
                                         notAvailableReinsurers,
                                         addReinsurer,
                                         createReinsurer,
                                         isCreateReinsurerLoading,
                                         totalFee
                                     }: CreateReinsurerModalProps) {

    const [form] = Form.useForm<{ reinsurer: string, fee: number }>()
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);


    const onValuesChange = () => {
        const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
        const fields = form.getFieldsValue()
        const allFieldsOk = !!(fields.reinsurer) && ((fields.fee + totalFee) <= 100)
        setDisabledSubmit(hasErrors || !allFieldsOk);
    }

    async function onFinish(values: { reinsurer: string, fee: number }) {
        addReinsurer(values.reinsurer, normalizePercTo1(values.fee))
        form.resetFields()
        setDisabledSubmit(true)
        onClose()
    }

    return (


        <Modal
            title={'Aggiungi riassicuratore'}
            closable={false}
            centered
            open={isOpen}
            onOk={() => {
                form.resetFields()
                onClose()
            }}
            onCancel={() => {
                form.resetFields()
                onClose()
            }}
            footer={
                <Row align='middle' justify="space-between">
                    <Col>
                        <Button onClick={onClose}>
                            Annulla
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={disabledSubmit} type='primary'
                                onClick={() => onFinish(form.getFieldsValue())}>
                            Aggiungi
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Form form={form} name="addNegotiationReinsurer" onValuesChange={onValuesChange} onFinish={onFinish}
                  style={{width: '100%'}} requiredMark={false} layout='vertical'>
                <Row align='middle' justify='space-between'>
                    <Col style={{width: '100%'}}>
                        <ReinsurerSelect fieldName="reinsurer"
                                         label='Riassicuratore'
                                         disabled={false}
                                         onCreation={(value: string) => form.setFieldsValue({reinsurer: value})}
                                         excludedReinsurers={notAvailableReinsurers}
                        />
                    </Col>
                    <Col style={{width: '100%'}}>
                        <Form.Item name='fee' label='Percentuale Riassicuratore' required initialValue={0}
                                   help={form.getFieldsValue().fee === undefined || form.getFieldsValue().fee + totalFee > 100 ? <span style={{color: 'orange'}}>La somma delle percentuali riassicuratore non possono superare il 100%.</span> : null}
                                   validateStatus={form.getFieldsValue().fee === undefined || ((+form.getFieldsValue().fee + totalFee) <= 100) ? 'success' : 'error'}>
                            <AntCurrencyFormatInput suffix={' %'} max={100 - totalFee} decimalScale={4}/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal>
    )


}

type ReinsurerAndFeesItemProp = {
    idx: number,
    notAvailableReinsurers: string[],
    reinsurerAndFees: NegotiationReinsurerModel,
    updateReinsurer: (data: NegotiationReinsurerFormModelPut) => void,
    createReinsurer: (name: string) => Promise<ReinsurerModel | undefined>,
    isCreateReinsurerLoading: boolean,
    removeReinsurer: () => void,
    isRemoveReinsurerLoading: boolean,
    hasErrorOccourred: boolean,
}

export function ReinsurerAndFeesItem({
                                         idx,
                                         notAvailableReinsurers,
                                         reinsurerAndFees,
                                         updateReinsurer,
                                         createReinsurer,
                                         isCreateReinsurerLoading,
                                         removeReinsurer,
                                         isRemoveReinsurerLoading,
                                         hasErrorOccourred,
                                     }: ReinsurerAndFeesItemProp) {
    const [form] = Form.useForm<NegotiationReinsurerFormModel>()

    useEffect(() => {
        form.setFieldsValue({reinsurer: reinsurerAndFees.reinsurer.uuid, fee: reinsurerAndFees.fee})
    }, [reinsurerAndFees, form])


    const debounceReinsurerChange = useMemo(
        () => debounce((data: NegotiationReinsurerFormModelPut) => updateReinsurer(data), 1500)
        , [updateReinsurer]);

    function onValuesChange(changedValues: Partial<NegotiationReinsurerFormModel>, values: NegotiationReinsurerFormModel) {
        //Due to AntCurrencyInputIssue, otherwise trigger at every form.SetField (despite it shouldn't by design)
        if ((values.fee !== reinsurerAndFees.fee) || (values.reinsurer !== reinsurerAndFees.reinsurer.uuid)) {
            debounceReinsurerChange({...values, fee: values.fee === undefined ? null : +normalizePercTo1(values.fee).toFixed(6)})
        }
    }

    return (
        <Form
            form={form}
            name={`reinsuranceReinsurerAndFees${idx}`}
            layout="vertical"
            requiredMark={false}
            onValuesChange={onValuesChange}
        >
            <Row align='bottom' justify='space-between'>
                <Col xs={24} md={14}>
                    <ReinsurerSelect fieldName="reinsurer"
                                     label={`Riassicuratore ${idx + 1}`}
                                     disabled={false}
                                     onCreation={(value: string) => {
                                         form.setFieldsValue({reinsurer: value})
                                     }}
                                     excludedReinsurers={notAvailableReinsurers.filter(el => el !== reinsurerAndFees.reinsurer.uuid)}
                                     {...!!reinsurerAndFees?.reinsurer && {selectedReinsurer: reinsurerAndFees.reinsurer}}
                    />
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item
                        label="Percentuale Riassicuratore"
                        name={'fee'}
                        required
                        validateStatus={hasErrorOccourred === true ? 'error' : 'success'}
                        trigger={'onValueChange'}
                    >
                        <Row wrap={false}>
                            <Col flex={1}>
                                <AntCurrencyFormatInput
                                    suffix={' %'}
                                    decimalScale={4}
                                    defaultValue={reinsurerAndFees.fee === null ? undefined : reinsurerAndFees.fee}
                                    onChange={(value) => onValuesChange({fee: value as number | undefined}, {
                                        ...form.getFieldsValue(), fee: value as number | undefined
                                    })}
                                /></Col>
                            <Col>
                                <Button icon={<DeleteFilled/>} disabled={isRemoveReinsurerLoading} type='ghost'
                                        onClick={removeReinsurer}/></Col>
                        </Row>
                    </Form.Item>

                </Col>
            </Row>
        </Form>
    )
}