import {
    Routes,
    Route,
    Navigate,
    Outlet,
} from "react-router-dom";

import Negotiations from "pages/negotiations";
import NewNegotiation from "pages/negotiations/new";
import NegotiationDetail from "pages/negotiations/negotiationId";
import Login from "components/login";
import Authorities from "pages/authorities";

import { useSelector } from "react-redux";
import { selectCurrentToken } from "redux/features/authSlice";
import Container from 'components/layout'
import React from "react";
import DashBoard from "./pages/dashboard";
import { selectUser } from "./redux/features/userSlice";
import { ContainerProps } from "./types/negotiations/components";
import TransitionFromRiskApp, {TransitionToRiskApp} from "./components/TransitionFromRiskApp";
import Settings from "./pages/settings";
import {canSeeAuthorities, canSeeUWCharts, canSeePortfolioCharts, canSeeSettings} from "./utils/permission";
import BrokersSettings from "./pages/settings/brokers";
import ReinsurersSettings from "./pages/settings/reinsurers";
import ReinsurerBrokersSettings from "./pages/settings/reinsurerBrokers";
import DelegatedInsurersSettings from "./pages/settings/delegatedInsurers";
import ContractorsSettings from "./pages/settings/contractors";


//https://reactrouter.com/docs/en/v6/getting-started/overview


export default function Router() {

    return (
        <Routes>
            {/* pages that contain /auth/ string don't set redux, check authMiddleWare for further information */}
            <Route path="auth/login" element={<Login />}/>
            <Route path="auth/:token" element={<TransitionFromRiskApp />}/>
            <Route path="sessionexpired" element={<TransitionToRiskApp />}/>

            <Route element={<HeaderContainer />}>
                <Route path="/" element={<Navigate to="negotiations" replace />} />
                <Route path="negotiations" element={<Negotiations />} />
                <Route path="negotiations/new" element={<NewNegotiation />} />
                <Route path="negotiations/:id" element={<NegotiationDetail />} />
                {/*<Route path="documents/:id" element={<Documents />} />*/}
                <Route path="authorities" element={<AuthoritiesPermission><Authorities/></AuthoritiesPermission>}/>
                <Route path="dashboard" element={<DashBoardPermission><DashBoard/></DashBoardPermission>}/>
                <Route element={<SettingsPermission/>}>
                    <Route path="settings" element={<Settings/>}/>
                    <Route path="settings/brokers" element={<BrokersSettings/>}/>
                    <Route path="settings/reinsurers" element={<ReinsurersSettings/>}/>
                    <Route path="settings/reinsurerbrokers" element={<ReinsurerBrokersSettings/>}/>
                    <Route path="settings/delegatedinsurers" element={<DelegatedInsurersSettings/>}/>
                    <Route path="settings/contractors" element={<ContractorsSettings/>}/>
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );

}

function HeaderContainer() {
    const token = useSelector(selectCurrentToken)

    return (
        token ? <Container><Outlet /></Container> : <></>
    )
}



function AuthoritiesPermission({ children }: ContainerProps) {
    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && canSeeAuthorities(user.usertypes.map(el=>el.code))

    if (!isAuthorized) {
        return <Navigate to={'/'} replace />;
    }
    return <>{children}</>;
}

function DashBoardPermission({ children }: ContainerProps) {

    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && (canSeePortfolioCharts(user.usertypes.map(el=> el.code)) || canSeeUWCharts(user.usertypes.map(el=> el.code)))
    if (!isAuthorized) {
        return <Navigate to={'/'} replace />;
    }
    return <>{children}</>;
}

function SettingsPermission({ children }: ContainerProps) {
    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && (canSeeSettings(user.usertypes.map(el=>el.code)))
    if (!isAuthorized) {
        return <Navigate to={'/negotiations'} replace />;
    }
    return <Outlet />;
}



