import {Col, Input, InputNumber, InputNumberProps, InputProps, Row} from "antd";
import { FunctionComponent } from "react";

import NumberFormat, { NumberFormatProps, NumberFormatPropsBase, NumberFormatValues } from 'react-number-format';
import CalculateButton from "./buttons/CalculateButton";

const locale = 'it-IT'
// https://codesandbox.io/s/currency-wrapper-antd-input-3ynzo?file=/src/index.js:436-453
const currencyFormatter = (value: string | number | undefined) => {
    if (!value)
        value = 0
    if (typeof value === 'string')
        value = parseFloat(value)
    return new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
        // style: 'currency',
        // currency: 'EUR',
    }).format(value);
};

const currencyParser = (val: string | undefined) => {
    try {
        // for when the input gets clears
        if (!val || (typeof val === "string" && !val.length)) {
            val = "0.0";
        }

        // detecting and parsing between comma and dot
        var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
        var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
        var reversedVal: string | number = val.replace(new RegExp("\\" + group, "g"), "");
        reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
        //  => 1232.21 €

        // removing everything except the digits and dot
        reversedVal = reversedVal.replace(/[^0-9.]/g, "");
        //  => 1232.21

        // appending digits properly
        const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
        const needsDigitsAppended = digitsAfterDecimalCount > 2;

        if (needsDigitsAppended) {
            reversedVal = parseFloat(reversedVal) * Math.pow(10, digitsAfterDecimalCount - 2);
        }
        if (typeof reversedVal === "string")
            reversedVal = parseFloat(reversedVal)

        return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
        console.error(error);
        return 0
    }
};


// export const LocalizedInputNumber: FunctionComponent<InputNumberProps> = (
//     props
// ) => {
//     return <InputNumber {...props}
//         style={{
//             width: '100%',
//         }}
//         formatter={currencyFormatter}
//         parser={currencyParser}
//     />;
// };

export function isPercentageAllowed(values: NumberFormatValues): boolean {
    const floatValue = values.floatValue
    if (!floatValue || (floatValue >= 0 && floatValue <= 100))
        return true
    else
        return false

}
export const AntCurrencyFormatInput: FunctionComponent<NumberFormatProps<InputProps> & { onChange?: (x: number | undefined) => void, calculate?: () => void }> = ({ onChange, calculate, ...props }) => {
    return !calculate ? <NumberFormat
        allowNegative={false}
        suffix={' €'}
        thousandSeparator={'.'}
        decimalSeparator=','
        customInput={Input}
        decimalScale={props.decimalScale !== undefined ? props.decimalScale : 2}
        fixedDecimalScale={true}
        {...(props.suffix?.includes('%') && { isAllowed: isPercentageAllowed })}
        {...(props.suffix?.includes('%') ? { placeholder: "0.00 %" } : { placeholder: "0.00 €" })}
        {...props}
        // in order to works with antd (because the onChange of NumberFormat passes the formatted string)
        //https://ant.design/components/form/#components-form-demo-customized-form-controls

        onValueChange={(value) => {
            onChange?.(value.floatValue) }}
    // onChange={(e: any) => console.log(e)}
    /> : <Row wrap={false}>
            <Col flex={1}>
                <NumberFormat
                    allowNegative={false}
                    suffix={' €'}
                    thousandSeparator={'.'}
                    decimalSeparator=','
                    customInput={Input}
                    decimalScale={props.decimalScale !== undefined ? props.decimalScale : 2}
                    fixedDecimalScale={true}
                    {...(props.suffix?.includes('%') && { isAllowed: isPercentageAllowed })}
                    {...(props.suffix?.includes('%') ? { placeholder: "0.00 %" } : { placeholder: "0.00 €" })}
                    {...props}
                    // in order to works with antd (because the onChange of NumberFormat passes the formatted string)
                    //https://ant.design/components/form/#components-form-demo-customized-form-controls

                    onValueChange={(value) => {
                        onChange?.(value.floatValue) }}
                />
            </Col>
        <Col>
            <CalculateButton calculated={props.disabled as boolean} calculate={calculate}/>
        </Col>
    </Row>
}
