import type {MenuProps} from "antd";
import {Layout, Menu, Typography} from "antd";
import {ApartmentOutlined, FolderOutlined, HomeOutlined, UserOutlined, SettingOutlined, EllipsisOutlined} from '@ant-design/icons';
import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logOut} from 'redux/features/authSlice'
import {selectUser} from "../redux/features/userSlice";
import {canSeeAuthorities, canSeeSettings} from "../utils/permission";
import Overlay from "./modals/Overlay";

const { Header, Content } = Layout
const { Title } = Typography


{/* todo adjust icons style with new style sheet*/
}

type MenuItem = Required<MenuProps>['items'][number];

export default function Index({ children }: { children: React.ReactNode }) {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedItem, setSelectedItem] = useState<string>('');
    const user = useSelector(selectUser)

    const menuItems: (MenuItem & { link?: string, onClick?: () => void })[] = [
        {
            key: 'logo',
            icon: (
                <div style={{ backgroundColor: 'white', borderRadius: '99px', width: '32px', height: '32px', padding: '4px' }}>
                    <img alt={'Logo di Riskapp'} style={{ width: '100%', display: 'block' }} src={process.env.PUBLIC_URL + '/img/logo.png'} />
                </div>),
            label: <Title id='riskapp-logo' level={4}>RiskApp</Title>
        },
        {
            key: 'customers',
            onClick: () => {
                localStorage.removeItem('accessToken')
                window.location.href = (process.env.REACT_APP_URL || 'https://ayakodev.riskapp.it/webapp') + '/customers'
            },
            icon: <HomeOutlined />,
            label: 'Customers'
        },
        {
            key: 'negotiations',
            // onClick: () => navigate('/negotiations'),
            link: '/negotiations',
            icon: <FolderOutlined />,
            label: 'Tool trattative'
        },
        { key: 'divider', type: 'divider' },
        // { key: '/calendar', icon: <CalendarOutlined />, label: 'Calendario' },
        // {
        //     key: 'profile',
        //     icon: <UserOutlined />,
        //     label: 'Profilo utente',
        //     children: [
        //         { label: 'Logout', type: 'group' }
        //     ]
        // },
        {
            key: 'logout',
            icon: <UserOutlined />,
            // label: <span onClick={() => { logOut(); }}>Logout</span>,
            label: 'Logout',
            // children: [
            //     { label: 'Logout', type: 'group' }
            // ]
        },
    ]

    if (user && user.usertypes.length && canSeeAuthorities(user.usertypes.map(el=>el.code) )) {
        const authoritiesItem: (MenuItem & { link?: string, onClick?: () => void }) =
            {
                key: 'authorities',
                // onClick: () => navigate('/authorities'),
                link: '/authorities',
                icon: <ApartmentOutlined />,
                label: 'Authorities'
            }
        menuItems.splice( 3 ,0, authoritiesItem)
    }

    if (user && user.usertypes.length && canSeeSettings(user.usertypes.map(el=>el.code))) {
        const settingsItem: (MenuItem & { link?: string, onClick?: () => void }) =
            {
                key: 'settings',
                // onClick: () => navigate('/authorities'),
                link: '/settings',
                icon: <SettingOutlined />,
                label: 'Impostazioni'
            }
            menuItems.splice(5, 0, settingsItem)
    }

    useEffect(() => {
        const currentPageMenuItem = menuItems.find(item => item && item.key && location.pathname.includes(item.key.toString()))
        setSelectedItem(currentPageMenuItem?.key?.toString() || '')
    }, [location, menuItems])


    const onMenuItemClick: MenuProps['onClick'] = useCallback(
        (e: any) => {
            const link = e?.item?.props?.link
            const key = e?.key
            if (key === 'customers')
                return
            if (key === 'logout') {
                dispatch(logOut())
                return
            }

            if (link) {
                navigate(link)
            }
        },
        [dispatch, navigate],
    )

    return (
        <>
            {/* <Header style={{ padding: '0 1rem', position: 'sticky', top: 0, zIndex: 999 }} className={'header'}> */}
            <Overlay />
            <Header style={{ padding: '0 1rem' }} className={'header'}>
                <Menu
                    mode='horizontal'
                    //theme influence the selected/hover style 
                    onClick={onMenuItemClick}
                    selectedKeys={[selectedItem]}
                    theme='dark'
                    items={menuItems}
                    overflowedIndicator={<div style={{height: '52px', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}><EllipsisOutlined/></div>}
                />
            </Header>
            <Content className={'basic-content-layout'}>
                {children}
            </Content>
        </>


    )
}