import { apiSlice } from "redux/api/baseApiSlice";
import { ContractorModel, CreateContractorModel, GetContractorsModel } from "types/contractors";
import { GetAtecosModel } from "types/negotiations";


export const contractorsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContractors: builder.query<GetContractorsModel, { search?: string, page?: number, page_size?: number, paginate?: boolean }>({
            query: (args) => {
                return {
                    url: '/negotiation/contractors/',
                    //   params: { start, end },
                    params: args,
                };
            },
        }),
        createContractor: builder.mutation<ContractorModel, CreateContractorModel>({
            query: (args) => {
                return {
                    url: '/negotiation/contractors/',
                    method: 'POST',
                    //   params: { start, end },
                    body: args
                };
            },
        }),
        updateContractor: builder.mutation<ContractorModel, { uuid: string, data: Partial<CreateContractorModel> }>({
            query: ({ uuid, data }) => {
                return {
                    url: `/negotiation/contractors/${uuid}/`,
                    method: 'PUT',
                    //   params: { start, end },
                    body: { ...data }
                };
            },
        }),
        getAtecoCodes: builder.query<GetAtecosModel, { page?: number, page_size?: number, search?: string }>({
            query: (data) => {
                return {
                    url: `/negotiation/ateco`,
                    method: 'GET',
                    params: data,
                };
            },
        }),
    })
})

export const { useLazyGetContractorsQuery, useCreateContractorMutation, useUpdateContractorMutation, useLazyGetAtecoCodesQuery } = contractorsApiSlice
