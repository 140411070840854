import {Button, Col, Form, Modal, Row, Select} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {NegotiationModel} from "../../types/negotiations";

type EditUnderwriterModalProps = {
    isOpen: boolean,
    onClose: () => void,
    negotiation: NegotiationModel,
    updateNegotiationUw: (uwId: string) => void
}

export default function EditUnderwriterModal({
                                                 isOpen,
                                                 onClose,
                                                 negotiation,
                                                 updateNegotiationUw
                                             }: EditUnderwriterModalProps) {

    const [form] = Form.useForm<{ underwriter: string }>()
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

    const onValuesChange = () => {
        const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
        const fields = form.getFieldsValue()
        const allFieldsOk = !!(fields.underwriter && fields.underwriter !== negotiation.underwriter?.uuid)
        setDisabledSubmit(hasErrors || !allFieldsOk);

    }

    const onFinish = (values: { underwriter: string }) => {
        updateNegotiationUw(values.underwriter)
        onClose()
    };

    useEffect(() => {
        form.setFieldsValue({underwriter: negotiation.underwriter?.uuid})
    }, [form, negotiation])

    const watchUw = Form.useWatch('underwriter', form)

    const options = useMemo(() => {
        const choices = negotiation.lob.underwriters.map(uw => <Select.Option key={uw.uuid}
                                                                              value={uw.uuid}>{uw.user_full_name}</Select.Option>)
        const isUwAlreadyPresentInTheChoices = negotiation.underwriter === null || negotiation.lob.underwriters.some(el => el.uuid === negotiation.underwriter?.uuid)
        // if the uw associated to the negotiation is no more active, add the option to the select
        if (!isUwAlreadyPresentInTheChoices) {
            choices.push(<Select.Option key={negotiation.underwriter?.uuid}
                                        value={negotiation.underwriter?.uuid}>{negotiation.underwriter?.user_full_name}</Select.Option>)
        }
        return <>{choices}</>
    }, [negotiation.lob.underwriters, negotiation.underwriter])

    return (
        <Modal
            title="Modifica Underwriter"
            centered
            open={isOpen}
            onOk={onClose}
            onCancel={onClose}
            footer={
                <Row align='middle' justify="space-between">
                    <Col>
                        <Button onClick={onClose}>
                            Annulla
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={disabledSubmit} type='primary'
                                onClick={() => onFinish(form.getFieldsValue())}>
                            Modifica
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Form
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                name="updateNegotiationUw"
                layout='vertical'
                requiredMark={false}
            >
                <Form.Item label='Underwriter'
                           name='underwriter'
                           help={watchUw === negotiation.underwriter?.uuid ? 'Il nome dell\'underwriter coincide con quello attuale' : 'L\' aggiornamento resetterà la procura'}
                           required>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder="Seleziona un underwriter"
                        optionFilterProp="children"
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {options}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}