import { apiSlice } from "redux/api/baseApiSlice";
import {GetPendingEmails} from "../../types/pendingEmails";

export const pendingEmailsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPendingEmails : builder.query<GetPendingEmails, {page?: number, page_size?: number, is_pending?: boolean, email_forwarding_date_from?: string, email_forwarding_date_to?: string, email_from_user_profile?: string, search?: string, ordering?: string, paginate?: boolean}>({
            query: (args) => {
                return {
                    url: 'negotiation/pending_emails/',
                    method: 'GET',
                    params: args
                }

            }
        })

    })
})

export const {useLazyGetPendingEmailsQuery} = pendingEmailsApiSlice