import {Card, Row, Col, Space} from "antd";
import React from "react";
import {ContainerProps} from "../../../types/negotiations/components";
import Title from "antd/lib/typography/Title";
import GraphInfoModal from "../../modals/GraphInfoModal";

export default function ChartContainer(props: ContainerProps) {
    const titleCol = props.title ? <Title ellipsis={true} level={4}>{props.title}</Title> :
        <Col span={0}></Col>
    const buttonsCol = props.buttons ?
        <Col style={{textAlign: 'right', paddingRight: '5px'}}><Space wrap={true}>{props.buttons}</Space></Col> :
        <Col span={0}></Col>
    const rowCol = <Row justify={"space-between"} gutter={[0,16]}>
        <Col>
            <Row align={'middle'}>
                {titleCol}
                {props.noInfo !== true && <GraphInfoModal title={props.title ? props.title : ''} description={props.description}/>}
            </Row>

        </Col>
        <Col>
            {buttonsCol}
        </Col>
    </Row>

    const {buttons, noInfo, description, ...antProps} = props
    return <Card
        {...antProps}
        className={props.className}
        headStyle={{border: 'none'}}
        bodyStyle={{paddingTop: 0, ...antProps.bodyStyle}}
        title={rowCol}
    >
        {props.children}
    </Card>
}