import {Breadcrumb, Card, Col, Divider, Input, List, message, Pagination, Row, Spin} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    useLazyGetPaginatedReinsurerBrokersQuery,
    useCreateReinsurerBrokerMutation,
    useUpdateReinsurerBrokerMutation
} from "../../redux/api/reinsurerBrokersApiSlice";
import EditName from "../../components/settings/EditName";
import {useNavigate} from "react-router-dom";
import CreateNewElement from "../../components/settings/CreateNewElement";
import {debounce} from "lodash";
import {LeftOutlined} from "@ant-design/icons";

const pageSize: number = 22;

export default function ReinsurerBrokersSettings() {

    const [fetchReinsurersBrokers, {
        data: reinsurersBrokersData,
        isLoading: isReinsurerBrokerLoading,
        isFetching: isReinsurerBrokerFetching,
        isError: isReinsurerBrokerError
    }] = useLazyGetPaginatedReinsurerBrokersQuery()
    const [createReinsurerBrokerRequest] = useCreateReinsurerBrokerMutation()
    const [updateReinsurerBrokerRequest] = useUpdateReinsurerBrokerMutation()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debounceSetSearchTerm = debounce((value: string) => setSearchTerm(value), 600)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isOneItemModifing, setIsOneItemModifing] = useState({status: false, key: ""})

    const navigate = useNavigate()

    const fetchReinsurerBrokersData = useCallback((pageParam?: number, searchParam?: string) => {
        try {
            fetchReinsurersBrokers({search: searchParam, page_size: pageSize, page: pageParam}).unwrap()
        } catch (e: any) {
            message.error('Impossibile scaricare i brokers riassicurativi')
            console.error('fetchReinsurersBrokersData', e)
        }
    }, [fetchReinsurersBrokers])

    useEffect(() => {
        searchTerm ? fetchReinsurerBrokersData(1, searchTerm) : fetchReinsurerBrokersData()
        setCurrentPage(1)
    }, [fetchReinsurerBrokersData, searchTerm])

    const addReinsurerBrokers = useCallback((newReinsurerBrokerName: string) => {
        createReinsurerBrokerRequest({name: newReinsurerBrokerName})
            .unwrap()
            .then(() => {
                searchTerm ? fetchReinsurerBrokersData(currentPage, searchTerm) : fetchReinsurerBrokersData(currentPage)
                message.success('Broker riassicurativo creato correttamente')
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiunta del broker riassicurativo')
                console.error('reinsurer broker not created', error)
            })
    }, [createReinsurerBrokerRequest, currentPage, fetchReinsurerBrokersData, searchTerm])

    const updateReinsurerBrokers = useCallback((name: string, uuid: string) => {
        return updateReinsurerBrokerRequest({name, uuid})
            .unwrap()
            .then(() => {
                searchTerm ? fetchReinsurerBrokersData(currentPage, searchTerm) : fetchReinsurerBrokersData(currentPage)
                message.success('Broker riassicurativo aggiornato correttamente')
                return true
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiornamento del broker riassicurativo')
                console.error('reinsurer broker not updated', error)
                return false
            })
    }, [currentPage, fetchReinsurerBrokersData, searchTerm, updateReinsurerBrokerRequest])

    return <div style={{padding: '1.5rem 3.75rem', backgroundColor: 'transparent'}}>
        <Card bodyStyle={{minHeight: '83vh', display: 'flex', flexDirection: 'column'}}
              title={<Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/settings')}><span
                      style={{cursor: "pointer"}}><LeftOutlined/> Impostazioni</span></Breadcrumb.Item>
                  <Breadcrumb.Item>Brokers Riassicurativi</Breadcrumb.Item>
              </Breadcrumb>}
        >
            <Row justify={'space-between'} style={{marginBottom: '1rem'}}>
                <Col style={{width: '20rem'}}><Input.Search placeholder={'Filtra per nome...'} enterButton
                                                            defaultValue={searchTerm}
                                                            onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}/></Col>
                <Col><CreateNewElement buttonContent={"Crea broker riassicurativo"}
                                       placeholder={"Nome broker riassicurativo"}
                                       modalTitle={"Creazione broker riassicurativo"}
                                       addElement={addReinsurerBrokers}/></Col>
            </Row>
            <Divider/>
            <div style={{flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                {!!reinsurersBrokersData?.count &&
                    <><List
                        grid={{column: 2, gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2}}
                        loading={!reinsurersBrokersData || isReinsurerBrokerFetching || isReinsurerBrokerLoading}
                        dataSource={reinsurersBrokersData?.results}
                        renderItem={item => <List.Item
                            style={{borderBottom: '1px solid rgba(0, 0, 0, 0.06)', paddingBottom: '0.1rem'}}>
                            <EditName id={item.uuid} text={item.name ? item.name : ""}
                                      updateText={(name) => updateReinsurerBrokers(name, item.uuid)}
                                      isOneItemModifing={isOneItemModifing}
                                      setIsOneItemModifing={setIsOneItemModifing}/>
                        </List.Item>
                        }
                    />
                      <Row style={{marginTop: '2.5rem'}}
                           justify={'end'}><Pagination
                          simple
                          total={reinsurersBrokersData.count}
                          current={currentPage}
                          pageSize={pageSize}
                          onChange={page => {
                              setIsOneItemModifing({status: false, key: ""})
                              setCurrentPage(page)
                              searchTerm ? fetchReinsurerBrokersData(page, searchTerm) : fetchReinsurerBrokersData(page)
                          }}
                      /></Row></>}
                {reinsurersBrokersData?.count === 0 && searchTerm && <p>La ricerca con "<span style={{fontStyle: 'italic'}}>{searchTerm}</span>" come chiave di ricerca non ha prodotto risultati</p>}
                {reinsurersBrokersData?.count === 0 && !searchTerm && <p>Non sono presenti broker</p>}
            </div>
        </Card>
    </div>
}

