import {createApi, fetchBaseQuery, BaseQueryFn} from '@reduxjs/toolkit/query/react'
import {RootState} from 'redux/store'
import {FetchArgs, FetchBaseQueryArgs, FetchBaseQueryError} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import {BaseQueryArg} from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {useDispatch} from 'react-redux'
import {logOut} from "../features/authSlice";
import {Modal} from "antd";

const baseQuery = fetchBaseQuery({
    // baseUrl: 'https://95.216.170.121/midori/v02',
    baseUrl: process.env.REACT_APP_API_URL || 'https://ayakodev.riskapp.it/midori/v02',
    // credentials: 'include',
    // mode: 'cors',
    prepareHeaders: (headers, {getState}) => {
        const token = (getState() as RootState).auth.token
        if (token)
            headers.set('authorization', `JWT ${token}`)
        // headers.set('Access-Control-Allow-Credentials', 'true')
        return headers
    }

})

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (api, args, extraOptions) => {
    let result = await baseQuery(api, args, extraOptions)
    if (result?.error?.status === 401) {
        // const refreshResult = await baseQuery('/refresh', api, extraOptions)
        // if (refreshResult?.data) {
        //     const user = api.getState().auth.user
        //     //store new token 
        //     api.dispatch(setCredentials({ ...refreshResult.data, user }))
        //     //retry oginal query with new accesstoken
        //     result = await baseQuery(args, api, extraOptions)
        // }
        // else {
        //     api.dispatch(logOut())
        // }
        const modal = Modal.warning({
            title: "Sessione scaduta",
            content: 'Verrai reindirizzato alla pagina di login...',
            onOk: () => {
                modal.destroy();
                args.dispatch(logOut())
            }
        })
        setTimeout(() => {
            modal.destroy();
            args.dispatch(logOut())
        }, 2000);

    }
    if (result?.error?.status === "PARSING_ERROR" || result?.error?.status === 500) {
        Modal.error({
            title: 'Errore nel caricamento dei dati',
            content: 'Se il problema persiste contattaci all\' indirizzo support@riskapp.it',
        })
    }
    if(result?.error?.status === 403) {
        Modal.error({
            title: 'Autorizzazione negata',
            content: 'Non hai il permesso di effetuare questa operazione'
        })
    }
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
    // endpoints: 
})