import React from "react";
import {Button, Modal, Space, Table} from "antd";
import {useNavigate} from "react-router-dom";
import Tag from "antd/es/tag";
import {getContrastYIQ} from "../../utils/utils";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";
import {FileAddOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {ColumnsType} from "antd/es/table";
import {CreateNewNegotiationFormModel, DuplicateNegotiation} from "../../types/negotiations";

export default function DuplicatesModal (props: {isOpen: boolean, close: () => void, source: DuplicateNegotiation[], allData: CreateNewNegotiationFormModel | undefined, onOk: () => Promise<void> | null}) {

    const navigate = useNavigate()
    const options = useSelector(selectOptions)

    const columns: ColumnsType<DuplicateNegotiation> = [
        {
            title: 'Stato',
            dataIndex: 'state',
            width: '20%',
            render: (value: number) => {

                            const foundState = options.states.find(el => el.value === value)
                            if (foundState) {
                                return <Tag
                                    style={{
                                        borderRadius: '100px',
                                        width: '7rem',
                                        height: '1.25rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 0.5rem',
                                        color: getContrastYIQ(foundState.color)
                                    }}
                                    color={foundState.color}
                                >
                                    {foundState.text}
                                </Tag>
                            }
                        }
        },
        {
            title: 'Decorrenza',
            dataIndex: 'policy_effective_date',
            width: '20%',
            render: (value: Date) => {
                return dayjs(value).format("DD-MM-YYYY")
            }
        },
        {
            title: 'Scadenza',
            dataIndex: 'policy_expiring_date',
            width: '20%',
            render: (value: Date) => {
                return dayjs(value).format("DD-MM-YYYY")
            }
        },
        {
            title: 'Broker',
            dataIndex: 'broker',
            width: '20%',
        },
        {
            title: '',
            dataIndex: 'uuid',
            width: '20%',
            render: (id: string) => {
                return <>{id ?
                    <Button type={"default"} size={"small"} onClick={() => navigate(`/negotiations/${id}`)}>Vai alla trattativa</Button> : ""}</>
            }
        }
    ]


    return <Modal open={props.isOpen} width={'80vw'} onCancel={props.close} title={'Conferma creazione'}
                  footer={<Space><Button onClick={props.close}>Annulla</Button><Button onClick={props.onOk} icon={<FileAddOutlined/>} type={"primary"}>Crea trattativa</Button></Space>}>
        <div>Sono state trovate trattative che contengono dati simili:
            <ul style={{marginLeft: "15px"}}>
                <li>Contraente: <span style={{fontWeight: "bold"}}>{props.source[0]?.contractor}</span></li>
                <li>UW Year: <span style={{fontWeight: "bold"}}>{props.source[0]?.uw_year}</span></li>
                <li>Lob: <span style={{fontWeight: "bold"}}>{props.source[0]?.lob}</span></li>
            </ul>
        </div>
        <Table columns={columns} dataSource={props.source} pagination={false} scroll={{x: 0, y: 300}}></Table>
    </Modal>
}