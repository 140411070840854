import PieChartContainer from "./PieChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import SelectMonth from "../../select/SelectMonth";
import {Button, Col, Row} from "antd";
import BrokerDataTable from "../../tables/chartTables/BrokerDataTable";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import PieChart from "../HighCharts/PieChart";
import {capitalizeFirstLetter, euro, numeric} from "../../../utils/utils";
import dayjs from "dayjs";
import {useLazyGetBrokerDataTotalsChartQuery} from "../../../redux/api/chartsApiSlice";
import {PointOptionsObject} from "highcharts";
import SelectPremiumsOrNegotiationsCount from "../../select/SelectPremiumsOrNegotiationsCount";
import {selectUser} from "../../../redux/features/userSlice";
import SelectNewBusinessRenewalAll from "../../select/SelectNewBusinessRenewalAll";


function Description({type}: { type: 1 | 2 }) {
    return <dl>
        Il grafico mostra la distribuzione delle trattative new business, suddivise per stato, delle trattative che arrivano da Broker.
        {type === 1 ? <dt>Numero delle trattative</dt> : <dt>Totale complessivo dei premi</dt>}
        {type === 1 ? <dd>è il numero delle trattative.</dd> :  <dd>è la sommatoria dei premi.</dd>}
        <dd>Premendo il tasto <span style={{fontWeight: 'bold'}}>Mostra dettaglio broker</span>, sarà visibile la
            tabella di dettaglio dei broker. Cliccando sul tasto + di ogni riga, sarà visibile la suddivisione sulle LOB
            visibili dei dati del broker.
        </dd>
    </dl>
}

function ExtraComponent({
                            isModalVisible,
                            setModalVisibility
                        }: { isModalVisible: boolean, setModalVisibility: React.Dispatch<React.SetStateAction<boolean>> }) {
    return <Row justify={'space-between'}>
        <Col>
            <Button key={'broker-details-btn'}
                    type={'primary'}
                    onClick={() => {
                        setModalVisibility(prevState => !prevState)
                    }}>
                {!isModalVisible ? 'Mostra dettaglio broker' : 'Nascondi dettaglio broker'}
            </Button>
        </Col>
    </Row>
}

export default function BrokerDataTotalsChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string, lob: { label: string, value: string } }) {
    const [isVisibleBrokerDetails, setIsVisibleBrokerDetails] = useState<boolean>(false)
    const title = 'Dati broker totali'
    const [date, setDate] = useState(dayjs(new Date()))
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetBrokerDataTotalsChartQuery()
    const [premiumsOrNegotiationsCount, setPremiumsOrNegotiationsCount] = useState<1 | 2>(1)
    const options = useSelector(selectOptions)
    const [series, setSeries] = useState<PointOptionsObject[]>([])
    const [total, setTotal] = useState<string>('')
    const [newBusinessRenewallAll, setnewBusinessRenewallAll] = useState<1 | 2 | 3>(1)

    useEffect(() => {
        if (data?.total) {
            setTotal(premiumsOrNegotiationsCount === 1 ? numeric(data.total) : euro(data.total))
        }
    }, [data?.total, premiumsOrNegotiationsCount])


    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                year: date.year(),
                month: date.month(),
                budget_group: budgetGroup.value,
                isia: isia.value,
                calculation_type: premiumsOrNegotiationsCount,
                negotiations_type: newBusinessRenewallAll
            })
        }
    }, [budgetGroup, date, getData, isia, newBusinessRenewallAll, premiumsOrNegotiationsCount, uwYear])

    useEffect(() => {
        if (data)
            setSeries(data ? data.states.map(el => {
                const state = options.states.find(state => state.value === el.state)
                return {...el, stateName: state?.text, stateColor: state?.color}
            }).map(el => ({
                name: el.stateName,
                y: el.amount,
                color: el.stateColor,
            })) : [])
    }, [data, options])

    const pieChart = <PieChart
        chartRef={props.chartRef}
        options={{
            chart: {
                borderRadius: 12
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: title
                    },
                    subtitle: {
                        text: `Parametri: ${capitalizeFirstLetter(date.format('MMMM YYYY'))} - UW Year:${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                    }
                }
            },
            legend: {
                width: '50%',
                verticalAlign: 'middle',
                align: 'right',
                labelFormat: '{name} - {percentage:.2f}%'
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 768
                    },
                    chartOptions: {
                        legend: {
                            width: '100%',
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        }
                    }
                }]
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                        format: '{point.name} - {percentage:.2f}%'
                    },
                    showInLegend: true
                },
            },
            series: [
                {
                    type: 'pie',
                    data: series
                },
            ]
        }}
    />

    return <React.Fragment>
        <PieChartContainer
            description={<Description type={premiumsOrNegotiationsCount}/>}
            loading={isLoading || isFetching}
            className={props.className}
            title={title}
            buttons={[
                <label htmlFor={'brokerDataTotalsMonthSelect'} key={'brokerDataTotalsMonthSelect'}
                       style={{fontWeight: '400'}}>Trattative al:</label>,
                <SelectMonth month={date} setMonth={setDate} key={'month-select'}
                             selectProps={{name: "brokerDataTotalsMonthSelect", allowClear: false}}/>,
                <SelectPremiumsOrNegotiationsCount key={'premium-negotiation-select'} type={premiumsOrNegotiationsCount}
                                                   setType={setPremiumsOrNegotiationsCount}/>,
                <SelectNewBusinessRenewalAll key={'new-business-renewal-select'} setValue={setnewBusinessRenewallAll} value={newBusinessRenewallAll}/>,
                <DownloadButton key={'download-button'} chartRef={props.chartRef}/>]}
            pieChart={pieChart}
            valueDescription={premiumsOrNegotiationsCount === 1 ? 'Numero delle trattative' : 'Totale complessivo dei premi'}
            displayValue={total}
            colSpan={4}
            extraComponents={<ExtraComponent isModalVisible={isVisibleBrokerDetails}
                                             setModalVisibility={setIsVisibleBrokerDetails}/>}/>
        {isVisibleBrokerDetails && <BrokerDataTable className={'broker-data--table'} date={date}
                                                    isDataLoading={isLoading || isFetching}
                                                    calculationType={premiumsOrNegotiationsCount} negotiationsType={newBusinessRenewallAll}/>}

    </React.Fragment>
}