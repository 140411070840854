import { apiSlice } from "redux/api/baseApiSlice";
import { BelatedEmittedModel, CreateBelatedEmittedRequest, MountBelatedEmittedRequest, UnmountBelatedEmittedRequest } from "types/negotiations";


export const belatedEmittedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNegotiationBelatedEmitted: builder.query<{ count: number, next: number | null, previous: number | null, results: BelatedEmittedModel[], total_belated_emitted:number | null }, { negotiationId: string, page?: number, page_size?: number, ordering?: string }>({
            query: (args) => {
                const { negotiationId, page, page_size } = args;
                return {
                    url: `/negotiation/negotiations/${negotiationId}/belated_emitted/`,
                    //   params: { start, end },
                    // params: args
                };
            },
        }),
        createNegotiationBelatedEmitted: builder.mutation<BelatedEmittedModel, { negotiation_id: string, data: CreateBelatedEmittedRequest }>({
            query: ({ negotiation_id, data }) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/`,
                    method: 'POST',
                    //   params: { start, end },
                    body: data
                };
            },
        }),
        mountNegotiationBelatedEmitted: builder.mutation<BelatedEmittedModel, { id: string, negotiation_id: string, data: MountBelatedEmittedRequest }>({
            query: ({ id, negotiation_id, data }) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/${id}/mount/`,
                    method: 'POST',
                    body: data
                };
            },
        }),
        unmountNegotiationBelatedEmitted: builder.mutation<BelatedEmittedModel[], { id: string, negotiation_id: string, data: UnmountBelatedEmittedRequest }>({
            query: ({ id, negotiation_id, data }) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/${id}/unmount/`,
                    method: 'POST',
                    body: data
                };
            },
        }),
        unmountPartialNegotiationBelatedEmitted: builder.mutation<BelatedEmittedModel[], { id: string, negotiation_id: string, data: UnmountBelatedEmittedRequest }>({
            query: ({ id, negotiation_id, data }) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/${id}/unmount_partial/`,
                    method: 'POST',
                    body: data
                };
            },
        }),
        deleteNegotiationbelatedEmitted: builder.mutation<null, { id: string, negotiation_id: string }>({
            query: ({ id, negotiation_id }) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/${id}/`,
                    method: 'DELETE'
                };
            },
        }),
        updateNegotiationBelatedEmitted: builder.mutation<BelatedEmittedModel, { id: string, negotiation_id: string, data: Partial<BelatedEmittedModel> }>({
            query: ({ id, negotiation_id, data }) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/${id}/`,
                    method: 'PATCH',
                    body: { ...data }
                };
            },
        }),
    })
})

export const { useLazyGetNegotiationBelatedEmittedQuery, useCreateNegotiationBelatedEmittedMutation, useDeleteNegotiationbelatedEmittedMutation, useMountNegotiationBelatedEmittedMutation, useUnmountNegotiationBelatedEmittedMutation, useUnmountPartialNegotiationBelatedEmittedMutation, useUpdateNegotiationBelatedEmittedMutation } = belatedEmittedApiSlice
