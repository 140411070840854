import {Button, Col, Row, Select, Space, Typography} from 'antd';

import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import GeneralTable from "../../components/tables/negotiationsTables/generalTable/GeneralTable";
import {BudgetGroup, Views} from "../../types";
import ViewSelection from "../../components/tables/negotiationsTables/ViewSelection";
import AgencyTable from "../../components/tables/negotiationsTables/AgencyTable";
import RenewsTable from "../../components/tables/negotiationsTables/RenewsTable";
import NegotiationTotal from "../../components/charts/ChartCards/NegotiationTotal";
import GapToPlan from "../../components/charts/ChartCards/GapToPlan";
import RenewRetention from "../../components/charts/ChartCards/RenewRetention";
import MakeTransition from 'components/animator';
import { useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import LoadedPolicies from "../../components/charts/ChartCards/LoadedPolicies";
import PoliciesToLoad from "../../components/charts/ChartCards/PoliciesToLoad";
import PoliciesStatus from "../../components/charts/ChartCards/PoliciesStatus";
import HighchartsReact from "highcharts-react-official";
import LogRocket from 'logrocket';
import UwYearDatePicker from "../../components/select/UwYearDatePicker";
import BudgetGroupSelect from "../../components/select/BudgetGroupSelect";
import {
    canCreateNewNegotiation,
    canSeeOtherTablesViews,
    canSeePortfolioCharts,
    canSeeUWCharts,
} from "../../utils/permission";

import IsiaSelect from "../../components/select/IsiaSelect";
import ResetFiltersSortersButton from "../../components/buttons/ResetFiltersSortersButton";
import NegotiationTablesDownloadButton from "../../components/buttons/NegotiationTablesDownloadButton";
import StatusHelpModal from "../../components/modals/StatusHelpModal";
import PendingEmailTable from "../../components/tables/negotiationsTables/PendingEmailTable";

const {Title} = Typography
declare const window: any;


export default function Negotiations() {

    const gapToPlanChartRef = useRef<HighchartsReact.RefObject>(null);
    const negotiationTotalChartRef = useRef<HighchartsReact.RefObject>(null);
    const renewRetentionChartRef = useRef<HighchartsReact.RefObject>(null);
    const policiesToLoadChartRef = useRef<HighchartsReact.RefObject>(null);
    const loadedPoliciesChartRef = useRef<HighchartsReact.RefObject>(null);
    const policiesStatusChartRef = useRef<HighchartsReact.RefObject>(null);

    const [showViewSelection, setShowViewSelection] = useState<boolean>(false)
    const [currentView, setCurrentView] = useState<Views>(Views.general)
    const navigate = useNavigate()
    const user = useSelector(selectUser)

    useEffect(() => {
        if (user?.preferences.current_view) {
            setCurrentView(user.preferences.current_view)
        }
    }, [user?.preferences.current_view])

    if (process.env.NODE_ENV === 'production') {
        LogRocket.init('poqeeg/tooltrattative');
        LogRocket.identify(user.username, {
            userfullname: user.user_full_name,
            uuid: user.uuid,
            userTypesCode: user.usertypes[0].code,
            userTypesName: user.usertypes[0].name
        })

        if (window.location.origin === 'https://negotiations.riskapp.it') {
            window.Intercom('boot', {
                app_id: 's07tp4ym',
                name: user.user_full_name,
                email: user.email,
                user_id: user.username,
                is_from_negotiations: true
            });
        }
    }

    const budgetGroups: BudgetGroup[] = user.budget_groups

    return (
        <MakeTransition forwardedKey='negotiations-table'>
            <div style={{padding: '40px 4% 70px'}}>
                {user && user.usertypes.length && canSeeOtherTablesViews(user.usertypes.map(el => el.code)) &&
                    <ViewSelection
                        currentView={currentView}
                        setCurrentView={setCurrentView}
                        showViewSelection={showViewSelection}
                        setShowViewSelection={setShowViewSelection}
                    />}
                <Row justify={'space-between'} align={'middle'}
                      gutter={[0, 16]}
                >
                    <Col style={{display: 'flex'}}>
                        <Title level={3} style={{marginBottom: 0}}> Panoramica trattative</Title>
                        <StatusHelpModal/>
                    </Col>
                    <Col>
                        <Space wrap={true}>
                            {user && user.usertypes.length && (canSeePortfolioCharts(user.usertypes.map(el => el.code)) || canSeeUWCharts(user.usertypes.map(el => el.code))) && <>
                              <UwYearDatePicker/>
                              <BudgetGroupSelect budgetGroups={budgetGroups}/>
                              <IsiaSelect/>
                            </>
                            }
                            {user && user.usertypes.length && (canSeePortfolioCharts(user.usertypes.map(el => el.code)) || canSeeUWCharts(user.usertypes.map(el => el.code))) &&
                                <Button type={'primary'} onClick={() => navigate('../dashboard')}>Vai alla
                                  dashboard</Button>}
                        </Space>
                    </Col>
                </Row>

                {user && user.usertypes.length && (canSeePortfolioCharts(user.usertypes.map(el => el.code)) || canSeeUWCharts(user.usertypes.map(el => el.code))) && canSeePortfolioCharts(user.usertypes.map(el => el.code)) &&
                    <div className={'negotiation-overview--charts'}>
                      <LoadedPolicies chartRef={loadedPoliciesChartRef}/>
                      <PoliciesToLoad chartRef={policiesToLoadChartRef}/>
                      <PoliciesStatus chartRef={policiesStatusChartRef}/>
                    </div>}

                {user && user.usertypes.length && (canSeePortfolioCharts(user.usertypes.map(el => el.code)) || canSeeUWCharts(user.usertypes.map(el => el.code))) && !canSeePortfolioCharts(user.usertypes.map(el => el.code)) &&
                    <div className={'negotiation-overview--charts'}>
                      <NegotiationTotal/>
                      <GapToPlan chartRef={gapToPlanChartRef}/>
                      <RenewRetention chartRef={renewRetentionChartRef}/>
                    </div>}

                {user && user.usertypes.length && canSeeOtherTablesViews(user.usertypes.map(el => el.code)) && currentView !== Views.general && <>
                  <Row align={'middle'} justify={'space-between'} style={{marginTop: "1rem"}}>
                    <Col>
                      <Select
                          value={currentView === Views.agency ? {
                              label: 'Importati da Agenzia',
                              value: currentView
                          } : currentView === Views.robot_reader ? {
                              label: 'Robot-Reader',
                              value: currentView
                          } : {label: 'Lista trattative da rinnovare', value: currentView}}
                          labelInValue
                          open={false} onClick={() => setShowViewSelection(true)}/>
                      <ResetFiltersSortersButton view={currentView}/>
                    </Col>
                    <Col>
                      <Space wrap={true}>
                        <Button onClick={() => navigate('new')}>Crea nuova trattativa</Button>
                      </Space>
                    </Col>
                  </Row>
                    {user && user.usertypes.length && canSeeOtherTablesViews(user.usertypes.map(el => el.code)) && currentView === Views.agency &&
                        <AgencyTable/>}
                    {user && user.usertypes.length && canSeeOtherTablesViews(user.usertypes.map(el => el.code)) && currentView === Views.robot_reader &&
                        <PendingEmailTable/>}
                    {user && user.usertypes.length && canSeeOtherTablesViews(user.usertypes.map(el => el.code)) && currentView === Views.renews &&
                        <RenewsTable/>}
                </>
                }
                {currentView === Views.general && <Row justify={'start'} align={'middle'} style={{marginTop: "1rem"}}>
                    {user && user.usertypes.length && canSeeOtherTablesViews(user.usertypes.map(el => el.code)) &&
                        <Col order={1}>
                          <Select
                              value={{
                                  label: 'Generale',
                                  value: currentView
                              }}
                              labelInValue
                              open={false} onClick={() => setShowViewSelection(true)}/>
                        </Col>}
                  <GeneralTable/>
                  <Col order={3}>
                    <Space wrap={true}>
                      <NegotiationTablesDownloadButton currentView={currentView}/>
                        {user && user.usertypes.length && canCreateNewNegotiation(user.usertypes.map(el => el.code)) &&
                            <Button onClick={() => navigate('new')}>Crea nuova trattativa</Button>}
                    </Space>
                  </Col>
                </Row>}
            </div>
        </MakeTransition>
    );

}