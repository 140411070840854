import { apiSlice } from "redux/api/baseApiSlice";
import {BrokerModel, GetBrokersModel} from "types/broker";

export const brokersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotPaginatedBrokers: builder.query<BrokerModel[], { search?: string }>({
            query: (args) => {
                const { search } = args
                return {
                    url: '/negotiation/brokers/',
                    params: {
                        paginate: false,
                        ...args
                    }
                };
            },
        }),
        getPaginatedBrokers: builder.query<GetBrokersModel, { search?: string, page?: number, page_size?: number }>({
            query: (args) => {
                const { search, page, page_size } = args
                return {
                    url: '/negotiation/brokers/',
                    params: {
                        paginate: true,
                        ...args
                    }
                };
            },
        }),
        createBroker: builder.mutation<BrokerModel, { name: string }>({
            query: (args) => {
                const { name } = args;
                return {
                    url: `/negotiation/brokers/`,
                    method: 'POST',
                    body: { name }
                };
            },
        }),
        updateBroker: builder.mutation<BrokerModel, { name: string, uuid: string }>({
            query: (args) => {
                const { name, uuid } = args;
                return {
                    url: `/negotiation/brokers/${uuid}/`,
                    method: 'PATCH',
                    body: { name }
                };
            },
        }),
    })
})

export const { useLazyGetNotPaginatedBrokersQuery, useLazyGetPaginatedBrokersQuery, useCreateBrokerMutation, useUpdateBrokerMutation } = brokersApiSlice