import {UserTypes} from "../types";

export const canSeeReferralButton = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.LBM, UserTypes.CM, UserTypes.ADMIN].includes(el))

export const canSeeUWCharts = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.CMO, UserTypes.LBM, UserTypes.CO].includes(el))
export const canSeePortfolioCharts = (users: UserTypes[]) => users.some(el=> [UserTypes.PO].includes(el))

export const canSeeOtherTablesViews = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.CMO, UserTypes.LBM, UserTypes.CO].includes(el))

export const canCreateNewNegotiation = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM].includes(el))

export const canModifyForm = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.PO].includes(el))

export const canSeeCheckList = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM].includes(el))

export const canSeeAuthorities = (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.UW, UserTypes.UWJ, UserTypes.UWS ].includes(el))
export const canModifyGapToPlan = (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.CM,].includes(el))

export const canSeeDocuments = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.PO, UserTypes.RO].includes(el))

export const canModifyUnderwriter = (users: UserTypes[]) => users.some(el=> [UserTypes.UWJ, UserTypes.UWS, UserTypes.UW, UserTypes.LBM, UserTypes.CM, UserTypes.ADMIN].includes(el))

export const canSeeSettings = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM].includes(el))
