import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from 'react';

import { Col, Row, Card, Tag, Form, Input, Radio, Button, Divider, Space, message, InputNumber } from 'antd';

import { Breadcrumb, Layout, Menu, Typography } from 'antd';



import { useTranslation } from 'react-i18next';
import { CommonFormComponentProps } from "types/negotiations/components";
import { useLazyGetAtecoCodesQuery, useLazyGetContractorsQuery, useUpdateContractorMutation } from "redux/api/contractorsApiSlice";
import { DebounceSelect } from "components/debounceSelect";

import { FileOutlined } from '@ant-design/icons';
import { ContractorDataFormModel, CreateContractorModel } from "types/contractors";
import { debounce, isEqual } from "lodash";
import { AntCurrencyFormatInput } from "components/inputNumber";
import { useSelector } from "react-redux";
import { selectUser } from "redux/features/userSlice";
import { ContractorSelect } from "../selects";
import {States} from "../../../types";

const { Header, Content, Footer, Sider } = Layout;
const { Title, Paragraph } = Typography

export default function Contractor({ forwaredRef, negotiation, formInstance, debounceInputChange, onFormValuesChange, disabled }: CommonFormComponentProps) {

    const user = useSelector(selectUser)
    const isUserManager = !!user.usertypes.find(type => ['ADMIN', 'LBM', 'CM'].includes(type.code))

    const watchContractor = Form.useWatch(['contractor'], formInstance)
    const [fetchAtecos, { isLoading: isAtecosLoading }] = useLazyGetAtecoCodesQuery()
    const [updateContractorRequest, { isLoading: isUpdateContractorLoading }] = useUpdateContractorMutation()

    async function searchAtecos(text: string): Promise<{ label: string, value: string }[]> {
        try {
            const atecosSearchResult = await fetchAtecos({ search: text }).unwrap()
            return atecosSearchResult.results.map(ateco => (
                { label: ateco.full_description || ateco.uuid, value: ateco.uuid }
            ))

        }
        catch (e: any) {
            console.error('Ateco search', e)
            return []
        }
    }

    const updateContractorData = async (data: Partial<CreateContractorModel>) => {
        //saveData
        try {
            const watchContractor = formInstance.getFieldValue('contractor')
            if (watchContractor) {
                const updateContractorResult = await updateContractorRequest({ uuid: watchContractor, data }).unwrap()
                message.success('Dati aggiornati')
            }
            else
                message.error('Contraente non impostato')
        }
        catch (e: any) {
            console.error('debounce', e)
            message.error('Impossibile aggiornare i dati')
        }
    }
    const debounceUpdateContractor = useMemo(
        () => debounce((data: Partial<CreateContractorModel>) => updateContractorData(data), 1500)
        , []);


    const [contractorDataForm] = Form.useForm<ContractorDataFormModel>()

    function onContractorDataChange(changedValues: Partial<ContractorDataFormModel>, values: ContractorDataFormModel) {
        const x: any = { ...changedValues }
        if (changedValues?.activity) {
            x.activity = changedValues.activity.value
        }

        //due to a bug of ReactNumberFormat + antd, the initial setFields (only here for some reason)
        //triggers a valuesChange event and so an update (despite it shouldn't). this logic to prevent that
        if (!x.yearly_revenues || x.yearly_revenues !== negotiation.contractor?.yearly_revenues)
            debounceUpdateContractor(x)

    }

    useEffect(() => {

        contractorDataForm.setFieldsValue({
            ...negotiation.contractor,
            activity: negotiation.contractor?.activity && negotiation.contractor?.activity_full_description ?
                { value: negotiation.contractor?.activity, label: negotiation.contractor?.activity_full_description } : null,

        })

    }, [negotiation])

    return (
        <Card ref={forwaredRef} id="contractor" bordered={false} style={{
            // padding: '24px',
            borderRadius: '12px',
            width: '100%'
        }}>
            <Row>
                <Col>
                    <Title level={3}>
                        <FileOutlined />
                    </Title>
                </Col>
                <Col style={{ marginLeft: '12px' }}>
                    <Title level={3}>Dati contraente</Title>
                </Col>
            </Row>
            <Form
                disabled={disabled}
                form={formInstance}
                name='contractor'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
                style={{ marginTop: '24px' }}
            >
                <ContractorSelect
                    fieldName="contractor"
                    label={'Contraente'}
                    disabled={negotiation.state !== States.Rore}
                    onCreation={({ value, label }) => {
                        formInstance.setFieldsValue({ contractor: value })
                        debounceInputChange({ contractor: value })
                    }}
                    selectedValue={negotiation.contractor || undefined}
                />

            </Form>
            <Form
                form={contractorDataForm}
                disabled={disabled}
                name='contractorData'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onContractorDataChange}
            >
                <Row align='middle' justify='space-between'>
                    <Col xs={24} md={11}>
                        <Form.Item label="Fatturato annuo" name='yearly_revenues' >
                            <AntCurrencyFormatInput disabled={!watchContractor} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item label="Partita IVA" name={'vat_number'} >
                            <Input disabled={!watchContractor} placeholder="Partita IVA" maxLength={16} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item style={{ marginTop: '24px' }} label="Descrizione attività" name={'activity'} >
                    <DebounceSelect
                        // mode="multiple"
                        // value={value}
                        disabled={!watchContractor}
                        virtual={false}
                        showSearch
                        placeholder="Comincia a digitare per cercare un attività.. (nome o ateco) "
                        fetchOptions={searchAtecos}
                        style={{ width: '100%' }}
                    // dropdownRender={menu => (
                    //     <>
                    //         {menu}
                    //         <Divider style={{ margin: '8px 0' }} />
                    //         <Space align="center" style={{ padding: '0 8px 4px' }}>
                    //             <Input placeholder="Nome contraente" value={newContractorName} onChange={(e) => setNewContractorName(e.target.value)} />
                    //             <Typography.Link onClick={createContractor} style={{ whiteSpace: 'nowrap' }}>
                    //                 <PlusOutlined /> Crea nuovo contraente
                    //             </Typography.Link>
                    //         </Space>
                    //     </>
                    // )}
                    />
                </Form.Item>
            </Form>
        </Card >

    )
}