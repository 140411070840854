import {apiSlice} from "redux/api/baseApiSlice";
import {TableDataType, TableHeader} from "../../types/tableDataType";
import {percentage} from "../../utils/utils";

const baseUrl = '/negotiation/charts'

export const chartsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNegotiationsStatusChart: builder.query<{ state: number, amount: number }[], { uw_year: number, budget_group: string, month: number, year: number, isia?:string /* underwriter: string,*/ }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/negotiationsstatus`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, month: params.month + 1}
                }
            },
        }),
        getNegotiationsPremiumsChart: builder.query<{ state: number, amount: number }[], { uw_year: number, budget_group: string, month: number, year: number, isia?:string/* underwriter: string,*/ }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/negotiationspremiums`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, month: params.month + 1}                }
            },
        }),
        getSubmissionReceptionChart: builder.query<{ month: string, premium: number, negotiations: number }[], { uw_year: number, budget_group: string, isia?:string}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/submissionreception`,
                    params: params
                }
            },
        }),
        getQuotedPremiumsOnReceivedChart: builder.query<{ premium: number, percentage_premium: number, negotiations: number, premium_goal: number }, { uw_year: number, budget_group: string, isia?:string}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/quotedpremiumsonreceived`,
                    params: params
                }
            },
        }),
        getBoundPremiumsOnTotalChart: builder.query<{ premium: number, percentage_premium: number, negotiations: number, premium_goal: number }, { uw_year: number, budget_group: string, isia?:string}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/boundpremiumsontotal`,
                    params: params
                }
            },
        }),
        getBoundRatiosChart: builder.query<{percentage_over_quoted: number, percentage_over_total: number, bound: number, quoted: number, total: number}, {uw_year: number, budget_group: string, isia?:string, calculation_type: number}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/boundratios`,
                    params: params
                }
            },
        }),
        getNegotiationsResultsChart: builder.query<{ state: number, premium: number, negotiations: number }[], { uw_year: number, budget_group: string, isia?:string}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/negotiationsresults`,
                    params: params
                }
            },
        }),
        getMonthlyHitRatioChart: builder.query<{ states: { state: number, premiums: number, negotiations: number }[], hit_ratio: number, total: number, isa_premium: number }, { uw_year: number, budget_group: string, month: number, year: number, isia?:string, calculation_type: string}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/monthlyhitratio`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, month: params.month + 1}
                }
            },
        }),
        getAnnualHitRatioChart: builder.query<{ states: { state: number, premium: number, negotiations: number }[], hit_ratio: number, total: number, isa_premium: number }, { uw_year: number, budget_group: string, isia?:string, calculation_type: string}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/annualhitratio`,
                    params: params
                }
            },
        }),
        getNetPremiumOurShareChart: builder.query<{ lobs: { lob: string, percentage: number }[], net_premium_our_share: number, account: number }, { uw_year: number, budget_group: string, isia?:string, calculation_type?: 1 | 2}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/netpremiumourshare`,
                    params: params
                }
            },
        }),
        getNetPremiumOurShareDetailsChart: builder.query<{ values: TableDataType[], header: TableHeader[] },{uw_year: number, budget_group: string, isia?: string}> ({
            query: (params) => {
                return {
                    url: `${baseUrl}/netpremiumoursharetable`,
                    params: params
                }
            }
        }),
        getNetPremiumOurShareTypeBusinessChart: builder.query<{new_business: number, renewal: number, total: number, regulation_premium: number}, { uw_year: number, budget_group: string, isia?:string, calculation_type?: 1 | 2}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/netpremiumoursharetypebusiness`,
                    params: params
                }
            }
        }),
        getBrokerDataTotalsChart: builder.query<{ states: { state: number, amount: number }[], total: number }, { uw_year: number, month: number, year: number, only_isia?: boolean, budget_group: string, isia?:string, calculation_type?: 1 | 2, negotiations_type? : 1| 2 | 3}>({
            query: (params) => {
                return {
                    url: `${baseUrl}/brokerdatatotals`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, month: params.month + 1}
                }
            },
        }),
        getBrokerDataDetailsChart: builder.query<{ values: TableDataType[], header: TableHeader[] }, { uw_year: number, month: number, year: number, only_isia?: boolean, budget_group: string, isia?:string, calculation_type?: 1 | 2, negotiations_type? : 1| 2 | 3 }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/brokerdatatdetails`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, month: params.month + 1}
                }
            },
        }),
        getBrokerSpecificDataDetailsChart: builder.query<{ values: TableDataType[], header: TableHeader[] }, { uw_year: number, month: number, year: number, broker: string, only_isia?: boolean, budget_group: string, isia?:string, calculation_type?: 1 | 2, negotiations_type? : 1| 2 | 3 }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/brokerspecificdatatdetails`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, month: params.month + 1}
                }
            },
        }),

        getAnnualGoalChart: builder.query<{ state: string, percentage: number, premium: number }[], { uw_year: number, scope: string, budget_group: string, isia?:string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/annualgoal`,
                    params: params
                }
            },
        }),
        getTrendAgencyChart: builder.query<{ values: TableDataType[], header: TableHeader[] }, { uw_year: number, budget_group: string, is_negotiations: boolean, isia?:string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/trendagency`,
                    params: params
                }
            },
        }),
        getUnderProcessingAgencyNegotiationsStatusChart: builder.query<{ values: TableDataType[], header: TableHeader[] }, { uw_year: number, budget_group: string, is_negotiations: boolean, isia?:string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/underprocessingagencynegotiationsstatus`,
                    params: params
                }
            },
        }),
        getGapToPipelineChart: builder.query<{ budget_group: string, percentage: number }[], { uw_year: number, budget_group: string, isia?:string /* underwriter: string,*/ }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/gaptopipeline`,
                    params: params
                }
            },
        }),
        getGapToPlanChart: builder.query<{ percentage: number, amount: number, goal: number, regulation: number}, { uw_year: number, budget_group: string, isia?:string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/gaptoplan`,
                    params: params
                }
            },
        }),
        getRenewRetentionChart: builder.query<{ percentage: number, amount: number, renewables: number, average_renewables: number }, { uw_year: number, budget_group: string, isia?:string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/retention`,
                    params: params
                }
            },
        }),
        getNegotiationTotalChart: builder.query<{ total_number: number, states_shares: {state: string, percentage: number}[] }, { uw_year: number, budget_group: string, isia?:string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/negotiationshares`,
                    params: params
                }
            },
        }),
        getNetPremiumOurShareSumChart: builder.query<{ state: number, premiums: number[] }[], { uw_year: number, budget_group: string, isia?:string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/netpremiumoursharesum`,
                    params: params
                }
            },
        }),
        getAccountNegotiationsCountChart: builder.query<{ state: number, premiums: number[] }[], { uw_year: number, budget_group: string, isia?:string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/accountnegotiationscount`,
                    params: params
                }
            },
        }),
        getPortfolioStatusUpload: builder.query<{ name: string, percentage: number, amount: number }[], { uw_year: number, budget_group: string, isia?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliostatusupload`,
                    params: params
                }
            },
        }),
        getPortfolioToBeUploaded: builder.query<{ to_be_uploaded: number, draft: number }, { uw_year: number, budget_group: string, isia?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliotobeuploaded`,
                    params: params
                }
            },
        }),
        getPortfolioUploaded: builder.query<{ total: number, uploaded: number }, { uw_year: number, budget_group: string, isia?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliouploaded`,
                    params: params
                }
            },
        }),
        getPortfolioBrokers: builder.query<{ name: string, negotiations: number }[], { uw_year: number, budget_group: string, isia?: string, waiting_reason?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliobrokers`,
                    params: params
                }
            },
        }),
        getPortfolioLobs: builder.query<{ name: string, negotiations: number }[], { uw_year: number, budget_group: string, isia?: string, upload_reason?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliolobs`,
                    params: params
                }
            },
        }),

    })
})

export const {
    useLazyGetNegotiationsStatusChartQuery,
    useLazyGetNegotiationsPremiumsChartQuery,
    useLazyGetGapToPipelineChartQuery,
    useLazyGetSubmissionReceptionChartQuery,
    useLazyGetQuotedPremiumsOnReceivedChartQuery,
    useLazyGetBoundPremiumsOnTotalChartQuery,
    useLazyGetBoundRatiosChartQuery,
    useLazyGetNegotiationsResultsChartQuery,
    useLazyGetMonthlyHitRatioChartQuery,
    useLazyGetAnnualHitRatioChartQuery,
    useLazyGetNetPremiumOurShareChartQuery,
    useLazyGetNetPremiumOurShareTypeBusinessChartQuery,
    useLazyGetBrokerDataTotalsChartQuery,
    useLazyGetAnnualGoalChartQuery,
    useLazyGetNetPremiumOurShareSumChartQuery,
    useLazyGetNetPremiumOurShareDetailsChartQuery,
    useLazyGetAccountNegotiationsCountChartQuery,
    useLazyGetTrendAgencyChartQuery,
    useLazyGetUnderProcessingAgencyNegotiationsStatusChartQuery,
    useLazyGetBrokerDataDetailsChartQuery,
    useLazyGetGapToPlanChartQuery,
    useLazyGetRenewRetentionChartQuery,
    useLazyGetNegotiationTotalChartQuery,
    useLazyGetPortfolioToBeUploadedQuery,
    useLazyGetPortfolioUploadedQuery,
    useLazyGetPortfolioStatusUploadQuery,
    useLazyGetPortfolioBrokersQuery,
    useLazyGetPortfolioLobsQuery,
    useLazyGetBrokerSpecificDataDetailsChartQuery
} = chartsApiSlice