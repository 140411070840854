import ConfirmCancelModal from "../modals/ConfirmCancelModal";
import {Button, Checkbox, message} from 'antd';
import React, {useState} from "react";
import {DownloadOutlined} from "@ant-design/icons";
import {CheckboxValueType} from "antd/lib/checkbox/Group";
import {DownloadNegotiationsParam, useGetDownloadNegotiationsMutation} from "../../redux/api/negotiationsApiSlice";
import {Views} from "../../types";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {cloneDeep} from "lodash";

const options = [
    {label: <><span style={{fontWeight: 'bold'}}>Ordinamenti attivi</span><br/>Mantiene gli ordinamenti per colonna impostati in tabella</>, value: 'sorted'},
    {label: <><span style={{fontWeight: 'bold'}}>Filtri attivi</span><br/>Mantiene i filtri attivi per colonna impostati in tabella</>, value: 'filtered'},
    {label: <><span style={{fontWeight: 'bold'}}>Tutti i dati</span><br/>Vengono estratti tutti i campi delle trattative (anche quelli non visibili come colonna)</>, value: 'allFields'}]

export default function NegotiationTablesDownloadButton({currentView}: { currentView: Views }) {

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(['sorted', 'filtered'])
    const [downloadXlsx, {isLoading}] = useGetDownloadNegotiationsMutation()
    const user = useSelector(selectUser)

    const handleDownloadXlsx = async () => {
        let contractorFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'contractor')
        let brokerFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'broker')
        let reinsurerBrokerFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'reinsurer_broker')
        let delegatedInsurerFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'delegated_insurer')

        let filters = user.preferences.tables[Views[currentView]].filters

        if (contractorFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'contractor'), {key: 'contractor', range: contractorFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }
        if (brokerFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'broker'), {key: 'broker', range: brokerFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }
        if (reinsurerBrokerFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'reinsurer_broker'), {key: 'reinsurer_broker', range: reinsurerBrokerFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }
        if (delegatedInsurerFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'delegated_insurer'), {key: 'delegated_insurer', range: delegatedInsurerFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }



        const params: DownloadNegotiationsParam = {
            filters: checkedList.find(el => el === 'filtered') ? filters as { key: string, range: number[] | string[]}[] : [],
            sorters: checkedList.find(el => el === 'sorted') ? user.preferences.tables[Views[currentView]].sorters : [],
            all_fields: !!checkedList.find(el => el === 'allFields')
        }
        downloadXlsx(params)
            .then((payload) => {
                message.success('Download completato correttamente')
                setIsModalVisible(false)
            })
            .catch((error) => {
                message.error('Errore nel download')
                setIsModalVisible(false)
            })
    }
    return <>
        <Button
            size='large'
            icon={<DownloadOutlined/>}
            style={{
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                boxShadow: "none"
            }}
            onClick={() => setIsModalVisible(true)}
        />
        <ConfirmCancelModal
            title={'Download delle trattative'}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={handleDownloadXlsx}
            okButtonProps={{
                loading: isLoading,
            }}
        >
            <p>Selezionare le opzioni di interesse per la generazione del file delle trattative in formato .xlsx. Quindi
                premere il pulsante "Conferma" per effettuare il download del file.</p>
            <Checkbox.Group
                style={{marginTop: '1rem'}}
                options={options}
                value={checkedList}
                onChange={values => setCheckedList(values)}
            />
        </ConfirmCancelModal></>
}