import {useLocation, useNavigate} from "react-router-dom";
import { useCallback, useEffect, useState} from 'react';

import {Col, Row, Button, Tooltip} from 'antd';

import { Layout, Menu, Typography, Divider} from 'antd';


import {useTranslation} from 'react-i18next';
import {LeftOutlined, RightOutlined, CloseOutlined} from '@ant-design/icons';

import type {MenuProps} from 'antd';

import useScrollSpy from 'react-use-scrollspy'
import {NegotiationDetailSubForm} from "types/negotiations/components";

import {useSelector} from "react-redux";
import {selectUser} from "redux/features/userSlice";
import {canSeeDocuments} from "../../../utils/permission";

const {Title} = Typography


export function NegotiationDetailMenu({menuItems}: { menuItems: NegotiationDetailSubForm[] }) {

    // const scrollPosition = useScrollPosition()
    const user = useSelector(selectUser)
    const isPortfolio = user.usertypes.find(type => type.code === 'PO')

    const [selectedSection, setSelectedSection] = useState<string>(isPortfolio ? 'policiesAndIssues' : 'submission');
    const [isAsideVisible, setIsAsideVisible] = useState(true)
    const navigate = useNavigate()

    const location = useLocation()
    const state = location.state as {previous_path: string}

    const onMenuItemClick: MenuProps['onClick'] = useCallback(
        (e: any) => {
            // setSelectedSection(e.key)
            const menuItem = menuItems.find(item => item.key === e.key)
            if (menuItem?.ref.current)
                menuItem.ref.current.scrollIntoView()
        },
        [menuItems],
    )


    const activeSection = useScrollSpy({
        sectionElementRefs: menuItems.map(menuItem => menuItem.ref),
        offsetPx: -400,
    });

    useEffect(() => {
        setSelectedSection(menuItems[activeSection].key || '')
    }, [activeSection])

    return (
        <>
            <aside
                style={{
                    maxWidth: '20%',
                    minWidth: '20%',
                    width: '20%',
                    backgroundColor: "white",
                    overflow: 'auto',
                    height: '100vh',
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
                    padding: '24px 8px',
                    display: isAsideVisible ? '' : 'none'
                }}
            >
                <Row justify={'space-between'} align={"middle"} style={{display: isAsideVisible ? '' : 'none'}}>
                    <Col>
                        <div className='btn-text' style={{gap: '4px', justifyContent: 'start'}}
                             onClick={() =>{
                                 state?.previous_path === '/authorities' ? navigate('/authorities') : navigate('/negotiations')
                             }}>
                            <LeftOutlined style={{color: '#1890FF'}}/>
                            <p style={{color: '#1890FF'}}>{state?.previous_path === '/authorities' ? "Torna alle authorities" : "Torna alle trattative"}</p>
                        </div>
                    </Col>
                    <Col>
                        <Tooltip title={'Comprimi'} placement={'bottom'}>
                            <Button
                            onClick={() => setIsAsideVisible(prevState => !prevState)}
                            style={{backgroundColor: 'transparent', width: '100%'}}
                            type={'text'}
                            icon={<LeftOutlined/>}/>
                        </Tooltip>
                    </Col>
                </Row>
                {/* collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}> */}
                <Row gutter={[24, 24]} style={{width: '100%'}}>
                    <Col span={24}>
                        {/* className="negotiationId-sidebar-backbutton" */}
                        {/* <Button style={{ padding: 0 }} icon={<LeftOutlined />} type='link' onClick={() => navigate('negotiations')}></Button> */}
                        <Title level={5} style={{marginBottom: 0}}>Dettagli Trattativa</Title>

                    </Col>
                    {/* <Col span={24}>
                    <Input.Search
                        // addonBefore="https://"
                        placeholder="input search text"
                        allowClear
                    // onSearch={onSearch}
                    // style={{ width: 304 }}
                    />
                </Col> */}
                </Row>
                <Menu

                    onClick={onMenuItemClick}
                    style={{width: '100%', marginTop: '24px', marginLeft: '-8px', marginRight: '-8px'}}
                    // defaultOpenKeys={['submission']}
                    selectedKeys={[selectedSection]}
                    mode="inline"
                    items={menuItems.map(item => ({key: item.key, icon: item.icon, label: item.label}))}
                />
                {/*{ user && user.usertypes.length && canSeeDocuments(user.usertypes.map(el=>el.code)) && <Row style={{marginTop: '44px'}} gutter={[8, 8]}>
                <Col span={24}>
                    <Button
                        block
                        type="primary"
                        icon={<FileOutlined/>}
                        onClick={() => {
                            navigate(`/documents/${location.pathname.split('/').pop()}`)
                        }}>
                        Documenti
                    </Button>
                </Col>
            </Row>}*/}
            </aside>
            <Button
                icon={isAsideVisible ? <LeftOutlined/> : <RightOutlined/>}
                onClick={() => setIsAsideVisible(prevState => !prevState)}
                style={{
                    position: 'sticky',
                    left: '0.7rem',
                    padding: '0.2rem',
                    top: '50%',
                    display: isAsideVisible ? 'none' : '',
                    zIndex: '1'
                }}
            />
        </>
    )
}

