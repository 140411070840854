import React from 'react';
import ReactDOM from "react-dom/client";

import reportWebVitals from './reportWebVitals';
import Router from 'router'
// import { AuthenticationContextProvider } from 'context/authContext';
// import { NotificationContextProvider } from 'context/notificationContext';
import { BrowserRouter } from 'react-router-dom'
import './styles/index.less';

import './i18n'

import { Provider } from 'react-redux';
import { store } from 'redux/store'
import AuthMiddleware from 'authMiddleware'
import { ConfigProvider } from 'antd';
import itIT from 'antd/es/locale/it_IT';



function Index() {

    return (
        <ConfigProvider locale={itIT}>
            <Provider store={store}>
                <BrowserRouter>
                    <AuthMiddleware>
                        <Router />
                    </AuthMiddleware>
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    )
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <Index />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
