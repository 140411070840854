import NegotiationsToAuthorize from "../../components/authorities/NegotiationsToAuthorize";
import GapToPlanNegotiations from "../../components/authorities/GapToPlanNegotiations";
import AssignNegotiationToUnderwriter from "../../components/authorities/AssignNegotiationToUnderwriter";
import MakeTransition from "components/animator";
import {useRef} from "react";
import HighchartsReact from "highcharts-react-official";

export default function Authorities() {
    const gapToPlanNegotiationsChartRef = useRef<HighchartsReact.RefObject>(null);
    return (
        <MakeTransition forwardedKey="authorities">
            <div style={{padding: '40px 4% 70px'}}>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                    gap: '1rem',
                }}>
                    <div className={'authorities--charts'}>
                        <NegotiationsToAuthorize className={'negotiations-to-authorize--chart'}/>
                        <GapToPlanNegotiations chartRef={gapToPlanNegotiationsChartRef}
                                               className={'gap-to-plan-negotiations--chart'}/>
                    </div>
                    <AssignNegotiationToUnderwriter/>
                </div>
            </div>
        </MakeTransition>
    )
}
