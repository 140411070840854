import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from 'redux/store'
import {User} from "../../types/user";
import {BudgetGroup, UserSortersFiltersColumnsTablePreferences, Views} from "../../types";


const userSlice = createSlice({
    name: 'user',
    initialState: {} as User,
    reducers: {
        setUserData: (state, action: PayloadAction<User>) => {
            return action.payload
            // ❌ ERROR: does not actually mutate or return anything new!
            // state = {...action.payload}
        },
        setTablesPreferences: (state, action: PayloadAction<UserSortersFiltersColumnsTablePreferences>) => {
            const view = Object.keys(action.payload)[0]
            if (view === Views[Views.general] && action.payload[view].selected_columns) {
                if (state.preferences.tables[view].sorters) {
                    const sortersToRemove: string[] = []
                    state.preferences.tables[view].sorters!.forEach(el => {
                            if (!action.payload[view].selected_columns!.includes(el.key)) {
                                sortersToRemove.push(el.key)
                            }
                        }
                    )
                    if (sortersToRemove.length) {
                        state.preferences.tables[view].sorters = state.preferences.tables[view].sorters!.filter(el => !sortersToRemove.includes(el.key))
                    }

                }
                if (state.preferences.tables[view].filters) {
                    const filtersToRemove: string[] = []
                    state.preferences.tables[view].filters!.forEach(el => {
                            if (!action.payload[view].selected_columns!.includes(el.key)) {
                                filtersToRemove.push(el.key)
                            }
                        }
                    )
                    if (filtersToRemove.length) {
                        state.preferences.tables[view].filters = state.preferences.tables[view].filters!.filter(el => !filtersToRemove.includes(el.key))
                    }
                }
                state.preferences.tables[view].selected_columns = action.payload[view].selected_columns
                return state

            } else {
                if (action.payload[view] && action.payload[view].sorters) {
                    state.preferences.tables[view].sorters = action.payload[view].sorters ? action.payload[view].sorters : []
                }
                if (action.payload[view] && action.payload[view].filters) {
                    state.preferences.tables[view].filters = action.payload[view].filters
                }
                return state
            }

            // ❌ ERROR: does not actually mutate or return anything new!
            // state = {...action.payload}
        },
        setTableViewSelection: (state, action: PayloadAction<Views>) => {
            state.preferences.current_view = action.payload
            return state
            // ❌ ERROR: does not actually mutate or return anything new!
            // state = {...action.payload}
        },
        setChartUWYear: (state, action: PayloadAction<number>) => {
            if (state.preferences?.charts_params?.uw_year) {
                state.preferences.charts_params.uw_year = action.payload
            } else {
                state.preferences.charts_params = {uw_year: action.payload, budget_group: null, isia: null}
            }
            return state
        },
        setChartBudgetGroup: (state, action: PayloadAction<BudgetGroup>) => {
            if (state.preferences?.charts_params?.budget_group) {
                state.preferences.charts_params.budget_group = action.payload
            } else {
                state.preferences.charts_params = {uw_year: null, budget_group: action.payload, isia: null}
            }
            return state
        },
        setChartIsia: (state, action: PayloadAction<{ label: string, value: string }>) => {
            if (state.preferences?.charts_params?.isia) {
                state.preferences.charts_params.isia = action.payload
            } else {
                state.preferences.charts_params = {uw_year: null, budget_group: null, isia: action.payload}
            }
            return state
        }

    }
})

export const {
    setTablesPreferences,
    setUserData,
    setTableViewSelection,
    setChartBudgetGroup,
    setChartIsia,
    setChartUWYear
} = userSlice.actions
export default userSlice.reducer

export const selectUser = (state: RootState) => state.user