import ConfirmCancelModal from "../../modals/ConfirmCancelModal";
import React, {useEffect, useMemo, useState} from "react";
import {Views} from "types";
import {Radio, Typography} from "antd";
import {useDispatch} from "react-redux";
import {setTableViewSelection} from "../../../redux/features/userSlice";
import {useSetTablesViewSelectionMutation} from "../../../redux/api/userPreferencesApiSlice";

const {Title} = Typography
const ViewSelection = (props: { currentView: Views, setCurrentView: React.Dispatch<React.SetStateAction<Views>>, setShowViewSelection: React.Dispatch<React.SetStateAction<boolean>>, showViewSelection: boolean }) => {

    const [viewSelected, setViewSelected] = useState<Views>(props.currentView)
    const dispatch = useDispatch()
    const [updateViewPreferences] = useSetTablesViewSelectionMutation()


    useEffect(() => {
        setViewSelected(props.currentView)
    }, [props.currentView])

    const ViewsSelectionRadio = useMemo(() => {
        let radioOptions = []
        for (const key of Object.keys(Views).filter(k => !isNaN(Number(k)))) {
            let title: string = ''
            let text: string = ''
            let value: Views = Views.general
            switch (+key) {
                case (Views.general) :
                    title = "Visualizzazione base"
                    text = "Visualizza tutte le trattative in corso, scegli dai filtri i dati da visualizzare nella tabella"
                    break
                case (Views.agency) :
                    value = Views.agency
                    title = "Visualizza dati importati da Agenzia"
                    text = "Visualizza solo i dati inseriti da Agenzia"
                    break
                case (Views.renews) :
                    value = Views.renews
                    title = "Visualizza solo trattative da rinnovare"
                    text = "Imposta questa visualizzazione per confermare o bloccare i rinnovi direttamente dalla tabella"
                    break
                case (Views.robot_reader) :
                    value = Views.robot_reader
                    title = "Dati importati da robot reader"
                    text = "Scegli questa impostazione per visualizzare le trattative inserite dal Robot Reader, in modo da approvarne i dati"
                    break
            }
            radioOptions.push(<Radio
                style={{marginTop: '0.75rem'}}
                key={+key}
                value={value}
            >
                <Title level={5} style={{margin: 0}}>{title}</Title>
                <p>{text}</p>
            </Radio>)
        }
        return <Radio.Group
            className={'views-radio'}
            value={viewSelected}
            onChange={event => setViewSelected(event.target.value)}
            style={{margin: 0, padding: 0}}
        >
            {radioOptions}
        </Radio.Group>
    }, [viewSelected])

    return <>
        <ConfirmCancelModal
            width={'40rem'}
            title={'Imposta viste'}
            open={props.showViewSelection}
            onCancel={() => {
                props.setShowViewSelection(false)
                setViewSelected(props.currentView)
            }}
            onOk={() => {
                props.setShowViewSelection(false)
                props.setCurrentView(viewSelected)
                dispatch(setTableViewSelection(viewSelected))
                updateViewPreferences(viewSelected)
            }}
        >{ViewsSelectionRadio}
        </ConfirmCancelModal>
    </>
}

export default ViewSelection