import { apiSlice } from "redux/api/baseApiSlice";
import {Budget, BudgetGroup} from "../../types/budgets";
import {ReinsurerModel} from "../../types/reinsurer";

export const budgetsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBudgetGroups: builder.query<BudgetGroup[], void>({
            query: () => '/negotiation/budget_groups/',
        }),
        getBudgets: builder.query<Budget[], {uw_year: number}>({
            query: (uw_year) => {
                return {
                    url: '/negotiation/budget/',
                    params: uw_year
                }
            },
        }),

        createBudget: builder.mutation<Budget, { budget_group: string, uw_year: number, total_goal: number, direct_isa_goal: number, isia_goal: number}>({
            query: (args) => {
                return {
                    url: '/negotiation/budget/',
                    method: 'POST',
                    body: args
                }
            }
        }),
        updateBudget: builder.mutation<Budget, { uuid: string, total_goal?: number, direct_isa_goal?: number, isia_goal?: number}>({
            query: (args) => {
                const { uuid, total_goal, direct_isa_goal, isia_goal } = args
                return {
                    url: `/negotiation/budget/${uuid}/`,
                    method: 'PATCH',
                    body: { total_goal, direct_isa_goal, isia_goal }
                }
            }
        })
    })
})

export const { useLazyGetBudgetGroupsQuery, useLazyGetBudgetsQuery, useCreateBudgetMutation, useUpdateBudgetMutation } = budgetsApiSlice