import {NegotiationModel} from "./negotiations";

export enum Views {
    general,
    agency,
    renews,
    robot_reader,
}

export enum UserTypes {
    UWJ = 'UWJ',
    UW = 'UW',
    UWS = 'UWS',
    LBM = 'LBM',
    CM = 'CM',
    PO = "PO",
    RO = 'RO',
    CO = 'CO',
    CMO = 'CMO',
    AA = 'AA',
    AM = 'AM',
    ADMIN = 'ADMIN'
}

export enum States {
    Rore,
    Reported,
    Assegnata,
    Working,
    Quoted,
    Declined,
    Bound,
    NotTakenUp,
    Draft,
    Issued,
    InRinnovo,
    DaRinnovare,
    Referred,
    ReferralApproved,
    ReferralDeclined,
    Stornata,
    NonRinnovata
}

// note: state && policy_effective_date are hidden data in the table but useful for display expired negotiations
export type GeneralNegotiation = Pick<NegotiationModel, 'uuid' | 'state' | 'contractor' | 'underwriter' | 'broker' | 'lob'
    | 'reinsurer_broker' | 'is_isia' | 'isia_type' | 'reception_date' | 'uw_year' | 'is_new_business' | 'quoted_premium'
    | 'policy_effective_date' | 'policy_expiring_date' | 'reinsured_share_perc' | 'cancellation_terms_days'
    | 'insured_sum' | 'regulation_premium' | 'attachment_point' | 'is_framework_agreement' | 'reinsurers_list' | 'state' | 'policy_effective_date' | 'policy_number' | 'has_regulation_premium'>

export type RenewsNegotiation = Pick<NegotiationModel, 'uuid' | 'contractor' | 'broker' | 'days_to_dead_line' | 'days_to_expiration' | 'policy_expiring_date' | 'cancellation_terms_days' | 'is_auto_renewal' | 'regulation_premium' | 'state' | 'policy_effective_date'>
export type RobotReaderNegotiation = Pick<NegotiationModel, 'uuid' | 'contractor' | 'broker' | 'reception_date' | 'policy_effective_date' | 'policy_expiring_date' | 'regulation_premium' | 'state' | 'policy_effective_date'>

export interface SortersAndFiltersType {
    column: string,
    sorter?: 'ascend' | 'descend' | undefined,
    numberFilter?: [number, number],
    dateFilter?: [string, string],
    textFilter?: string[]
}

export interface UserTablePreferencesType {
    view: Views,
    sortersAndFilters?: SortersAndFiltersType[],
    selectedColumns?: string[]
}

export interface TableSorter {
    key: string,
    type: 'ascend' | 'descend' | undefined
}

export interface TableFilter {
    key: string,
    range: number[] | string[] | { label: string, value: string }[]
}

export interface TablePagination {
    page?: number,
    pageSize?: number,
}

export interface UserSortersFiltersColumnsTablePreferences {
    [key: string]: {
        sorters?: TableSorter[]
        filters?: TableFilter[],
        selected_columns?: string[]
    }
}

export interface BudgetGroup {
    label: string,
    value: string,
}

export interface ChartsParams {
    uw_year: number | null,
    budget_group: { label: string, value: string } | null,
    isia: { label: string, value: string } | null
}
