import ChartContainer from "./ChartContainer";
import React, {useEffect} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import {euro, numeric} from "../../../utils/utils";
import CommonChart from "../HighCharts/CommonChart";
import dayjs from "dayjs";
import {useLazyGetSubmissionReceptionChartQuery} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function SubmissionReceptionChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {

    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetSubmissionReceptionChartQuery()
    const description = 'Il grafico mostra il numero di trattative ricevute per ogni mese.'


    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])


    const title = 'Ricezione submission'
    return <ChartContainer
        style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}
        description={description}
        loading={isLoading || isFetching}
        className={props.className}
        title={title}
        buttons={<DownloadButton chartRef={props.chartRef}/>}>
        <CommonChart
            chartRef={props.chartRef}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        }
                    },
                },
                xAxis: {
                    categories: data?.map(el => dayjs(el.month).format('MM / YY')),
                    visible: true,
                    lineWidth: 0
                },
                yAxis: {
                    title: {
                        text: 'Numero trattative'
                    },
                    visible: false
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    headerFormat: '',
                    pointFormatter: function () {
                        const value = numeric(this.y ? this.y : 0)
                        // add negotiation field
                        // @ts-ignore
                        return `<span style='font-weight: bolder'>${this.category}</span><br/>Premi: <span style='font-weight: bolder'>${euro(this.premium)}</span> <br/> Trattative: <span style='font-weight: bolder'>${value}</span>`
                    }
                },
                series: [{
                    name: 'Ricezione submission',
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            const value = this.point.y ? numeric(this.point.y) : ""
                            return value
                        }
                    },
                    data: data ? data.map(el => ({
                        value: el.negotiations,
                        y: el.negotiations,
                        premium: el.premium
                    })) : []
                }]
            }}
        />
    </ChartContainer>
}

