import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button, Result} from "antd";
import {useDispatch} from "react-redux";
import {logOut} from "../redux/features/authSlice";
import {wait} from "@testing-library/user-event/dist/utils";

export default function TransitionFromRiskApp() {

    let {token} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            localStorage.setItem('accessToken', token)
            navigate('/')
        }
    }, [navigate, token])


    return <>
        {!token && <Result status={"error"} title={'Credenziali non corrette'}
                           subTitle={'Token di autenticazione non presente'}/>}
    </>
}


export function TransitionToRiskApp() {

    const dispatch = useDispatch()
    const [timeRemaining, setTimeRemaining] = useState<number>(10)

    useEffect(() => {
        wait(1000).then(() => setTimeRemaining(timeRemaining - 1))
    }, [timeRemaining])

    useEffect(() => {
        if (timeRemaining === 0)
            dispatch(logOut())
    }, [timeRemaining])

    return <Result status={"warning"}
                   title={'Sessione scaduta'}
                   subTitle={<>Effettua l'accesso per continuare. <br/>Verrai automaticamente reindirizzato alla pagina di login tra {timeRemaining} secondi.</>}
                   extra={[
                       <Button type={"primary"}
                               onClick={() => dispatch(logOut())}> Vai
                           al login</Button>
                   ]}/>
}