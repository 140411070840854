import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Button, Row, Col, Modal} from 'antd';
import {Typography} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {CreateContractorModel} from "types/contractors";
import ContractorsForm from "./ContractorsForm";

const {Title} = Typography

export default function ContractorsItem({
                                            id,
                                            name,
                                            activityFullDescription,
                                            activity,
                                            yearlyRevenues,
                                            vatNumber,
                                            updateContractors,
                                            isOneItemModifing,
                                            setIsOneItemModifing
                                        }: { id: string, name: string | null, activityFullDescription: string | null, activity: string | null, yearlyRevenues: number | null, vatNumber: string | null, updateContractors: (data: CreateContractorModel) => void, isOneItemModifing: { status: boolean; key: string; }, setIsOneItemModifing: Dispatch<SetStateAction<{ status: boolean; key: string; }>> }) {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    useEffect(() => {
        if ((isOneItemModifing.status && isOneItemModifing.key !== id) || isOneItemModifing.key === "")
            setIsModalOpen(false)
    }, [isOneItemModifing])

    const handleSaveClick = (values: CreateContractorModel) => {
        updateContractors(values)
        setIsModalOpen(false)
    }

    const handleModifyClick = () => {
        setIsOneItemModifing({status: true, key: id})
        setIsModalOpen(true)
    }

    return (
        <>
            <Row justify={'space-between'} style={{width: '100%'}}>
                <Col style={{paddingTop: '0.3rem', marginLeft: '0.3rem'}}><Title level={5}>{name}</Title></Col>
                <Col><Button type={'link'} size={'middle'} onClick={handleModifyClick}
                             icon={<EditOutlined/>}></Button></Col>
            </Row>
            <Modal open={isModalOpen} destroyOnClose footer={false} title={"Aggiornamento contraente"}
                   onCancel={() => setIsModalOpen(false)}>
                <ContractorsForm isNew={false} name={name}
                                 activityFullDescription={activityFullDescription}
                                 activity={activity}
                                 yearlyRevenues={yearlyRevenues}
                                 vatNumber={vatNumber} onsubmit={handleSaveClick}
                                 onClose={() => setIsModalOpen(false)}
                /></Modal>
        </>
    )
}