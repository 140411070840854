import {Budget, BudgetGroup} from "../../types/budgets";
import {Button, Col, Divider, Form, message, Row} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import {useUpdateBudgetMutation} from "../../redux/api/budgetsApiSlice";
import {debounce} from "lodash";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {UserTypes} from "../../types";
import { canModifyGapToPlan } from "../../utils/permission";

export default function GapToPlanForm({budgetGroup, budget}: { budgetGroup: BudgetGroup, budget: Budget }) {

    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [updateBudget, {isLoading, isError}] = useUpdateBudgetMutation()
    const [data, setData] = useState<{ total: number, isa: number, isia: number }>({
        total: budget.total_goal,
        isa: budget.direct_isa_goal,
        isia: budget.isia_goal
    })

    useEffect(()=> {
        if (isLoading) {
            message.success('Gap to plan aggiornata')
        }
    }, [isLoading])

    useEffect(()=> {
        if (isError) {
            message.error('Gap to plan non aggiornata')
        }
    }, [isError])

    const updateDebounce = useCallback(debounce(({
                                                     isa,
                                                     isia,
                                                     total
                                                 }: { isa: number, isia: number, total: number }) => {
            updateBudget({
                uuid: budget.uuid,
                total_goal: total,
                direct_isa_goal: isa,
                isia_goal: isia
            })

        }, 1000)

        , [])

    const user = useSelector(selectUser)

    //todo form useless... only for styling

    return <>
        <Divider> {budgetGroup.name} </Divider>
        <Row align={'middle'} justify={'space-between'}>
            <Col>
                <Form
                    name={budgetGroup.uuid}
                    colon={false}
                    labelAlign={'left'}
                    labelCol={{span: 12}}
                    wrapperCol={{span: 12}}
                    className={'gap-to-plan-form'}
                >
                    <Form.Item label={'Obiettivo direct ISA'}>
                        <NumberFormat
                            className={`ant-input ant-item-form ${isDisabled ? 'ant-input-disabled' : ''}`}
                            value={data.isa}
                            displayType={"input"}
                            decimalSeparator={","}
                            thousandSeparator={"."}
                            placeholder={"0.00 €"}
                            suffix={" €"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            readOnly={isDisabled}
                            disabled={isDisabled}
                            onValueChange={(values) => {
                                const isa = values.floatValue ? values.floatValue : 0
                                setData(prevState => {
                                    updateDebounce({
                                        isia: prevState.isia,
                                        isa: isa,
                                        total: prevState.isia + isa
                                    })
                                    return {
                                        isia: prevState.isia,
                                        isa: isa,
                                        total: prevState.isia + isa
                                    }
                                })
                            }}
                        /></Form.Item>
                    <Form.Item label={'Obiettivo ISIA'}>
                        <NumberFormat
                            className={`ant-input ant-item-form ${isDisabled ? 'ant-input-disabled' : ''}`}
                            value={data.isia}
                            displayType={"input"}
                            decimalSeparator={","}
                            thousandSeparator={"."}
                            placeholder={"0.00 €"}
                            suffix={" €"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            readOnly={isDisabled}
                            disabled={isDisabled}
                            onValueChange={(values) => {
                                const isia = values.floatValue ? values.floatValue : 0
                                setData(prevState => {
                                    updateDebounce({
                                        isa: prevState.isa,
                                        isia: isia,
                                        total: prevState.isa + isia
                                    })
                                    return {
                                        isa: prevState.isa,
                                        isia: isia,
                                        total: prevState.isa + isia
                                    }
                                })
                            }}
                        /></Form.Item>
                    <Form.Item label={'Obiettivo totale'}>
                        <NumberFormat
                            className={`ant-input ant-item-form ${isDisabled ? 'ant-input-disabled' : ''}`}
                            value={data.total}
                            displayType={"input"}
                            decimalSeparator={","}
                            thousandSeparator={"."}
                            placeholder={"0.00 €"}
                            suffix={" €"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            readOnly={true}
                            disabled={true}
                        />
                    </Form.Item>
                </Form>
            </Col>
            {user && user.usertypes.length && canModifyGapToPlan(user.usertypes.map(el=> el.code)) && <Col>
              <Button
                  type={'primary'}
                  onClick={() => setIsDisabled(prevState => !prevState)}> {isDisabled ? 'Modifica' : 'Visualizza'}
              </Button>
            </Col>}
        </Row>
    </>
}