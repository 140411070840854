import ChartContainer from "./ChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import {useSelector} from "react-redux";
import {SeriesOptionsType} from "highcharts";
import Histogram from "../HighCharts/Histogram";
import {useLazyGetGapToPipelineChartQuery} from "../../../redux/api/chartsApiSlice";
import {percentage} from "../../../utils/utils";
import {selectUser} from "../../../redux/features/userSlice";

export default function GapToPipelineChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {
    const title = 'Gap to pipeline'
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetGapToPipelineChartQuery()
    const [series, setSeries] = useState<SeriesOptionsType[]>([])
    const description = 'Descrizione da integrare'

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (data)
            setSeries(data.map(el => ({
                type: 'column',
                name: el.budget_group,
                data: [el.percentage * 100],
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        const value = this.y ? (this.y / 100) : 0
                        return value ? percentage(value) : ""
                    }
                }
            })))
    }, [data])

    return <ChartContainer
        description={description}
        loading={isLoading || isFetching}
        className={props.className}
        title={title}
        buttons={[
            <DownloadButton key={'download-button'} chartRef={props.chartRef}/>]}>
        <Histogram
            chartRef={props.chartRef}
            options={{
                tooltip: {
                    formatter: function () {
                        const symbol = this.color ? `<span style='color: ${this.color}'>●</span>` : ''
                        return `${symbol} <span>${this.series.name}</span>: <span style='font-weight: bolder'>${percentage(this.y ? this.y / 100 : 0)}</span>`
                    }
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: title
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    format: '{y:.2f}%'
                                },
                            }
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        }
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    labels: {
                        format: '{value}%'
                    }
                },
                series: series
            }}
        />
    </ChartContainer>
}