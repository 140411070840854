import React, {useEffect, useState} from "react";
import { Table} from "antd";
import {useLazyGetNetPremiumOurShareDetailsChartQuery} from "../../../redux/api/chartsApiSlice";
import {ColumnsType} from "antd/lib/table";
import { euro} from "../../../utils/utils";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function NetPremiumOurShareForLobTable({className,}: { className: string }) {

    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetNetPremiumOurShareDetailsChartQuery()
    const [columns, setColumns] = useState<ColumnsType<any>>([])

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value,
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (data?.header) {
            const updateCols = data.header.map((col, index) => {
                if (col.dataIndex === 'column0') return {
                    ...col,
                    sorter: {
                        compare: (a: any, b: any) => {
                            return a[col.dataIndex].localeCompare(b[col.dataIndex])
                        },

                    },
                    render: (value: number) => <div style={{textAlign: "start"}}>{value}</div>,
                    align: 'center' as const

                }
                else return {
                    ...col,
                    sorter: {
                        compare: (a: any, b: any) => {
                            return +a[col.dataIndex] - +b[col.dataIndex]
                        },
                    },
                    render: (value: number) => {
                        if (index === 2)
                            return <div style={{textAlign: "end"}}>{euro(value)}</div>
                        else
                            return <div style={{textAlign: "end"}}>{value}</div>
                    },
                    align: 'center' as const,
                }
            })
            setColumns(updateCols)
        }
    }, [data?.header])

    return <Table
        loading={isLoading || isFetching}
        className={className}
        columns={columns}
        dataSource={data?.values}
        pagination={false}
        style={{width: '100%'}}/>
}