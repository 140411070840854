import {apiSlice} from "redux/api/baseApiSlice";
import {
    CreateNegotiationTransitionRequest,
    CreateNewNegotiationRequest, DuplicateNegotiation,
    NegotiationModel, NegotiationTransitionHistory,
    NegotiationTransitionModel, NegotiationUserModel,
    UpdateNegotiationRequest
} from "types/negotiations";
import {TableFilter, TablePagination, TableSorter} from "../../types";
import {GetNegotiationReferred} from "../../types/authorities";

export type QueryNegotiationsParam = {
    pagination?: TablePagination
    filters?: TableFilter[]
    sorters?: TableSorter[]
}

export type DownloadNegotiationsParam = {
    filters?: {
        key: string,
        range: number[] | string[]
    }[]
    sorters?: TableSorter[]
    all_fields?: boolean
}


export const negotiationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => {
        return ({
            createNegotiation: builder.mutation<NegotiationModel, CreateNewNegotiationRequest>({
                query: (data) => ({
                    url: `/negotiation/negotiations/`,
                    method: 'POST',
                    body: {...data}

                }),
            }),
            checkDuplicatesNegotiation: builder.mutation<DuplicateNegotiation[],{contractor: string, lob: string, uw_year: number}>({
                query: (data) => ({
                    url:`/negotiation/negotiations/check_duplicates/`,
                    method: 'GET',
                    params: data
                })
            }),
            updateNegotiation: builder.mutation<NegotiationModel, { id: string, data: Partial<UpdateNegotiationRequest> }>({
                query: ({id, data}) => ({
                    url: `/negotiation/negotiations/${id}/`,
                    method: 'PATCH',
                    body: {...data}

                }),
            }),
            getNegotiationDetail: builder.query<NegotiationModel, string>({
                query: (id) => `/negotiation/negotiations/${id}/`,
                // keepUnusedDataFor: 5,
                //cache for 5s
            }),
            getNegotiations: builder.query<{
                count: number,
                premiums: number | null,
                results: NegotiationModel[]
            },
                QueryNegotiationsParam | void>({
                query: (params: QueryNegotiationsParam | void) => {
                    let url = '/negotiation/negotiations/'
                    if (params) {
                        let options: string[] = []
                        if (params.pagination) {
                            if (params.pagination && params.pagination.pageSize) {
                                options.push(`page_size=${params.pagination.pageSize}`)
                            }
                            if (params.pagination && params.pagination.page) {
                                options.push(`page=${params.pagination.page}`)
                            }
                        }
                        if (params.filters) {
                            for (let i = 0; i < params.filters.length; i++) {
                                if (params.filters[i].range.length) {
                                    switch (params.filters[i].key) {
                                        case ('reception_date'):
                                        case ('policy_effective_date'):
                                        case ('policy_expiring_date'):
                                            options.push(`${params.filters[i].key}_from=${params.filters[i].range[0]} 00:00:00`)
                                            options.push(`${params.filters[i].key}_to=${params.filters[i].range[1]} 23:59:59`)
                                            break
                                        case ('uw_year'):
                                            options.push(`${params.filters[i].key}_from=${params.filters[i].range[0]}`)
                                            options.push(`${params.filters[i].key}_to=${params.filters[i].range[1]}`)
                                            break
                                        case ('isa_quoted_premium_share'):
                                        case ('insured_sum'):
                                        case ('attachment_point'):
                                        case ('days_to_dead_line'):
                                        case ('days_to_expiration'):
                                            options.push(`${params.filters[i].key}_min=${params.filters[i].range[0]}`)
                                            options.push(`${params.filters[i].key}_max=${params.filters[i].range[1]}`)
                                            break
                                        case ('reinsured_share_perc'):
                                            const min = Number(params.filters[i].range[0])
                                            const max = Number(params.filters[i].range[1])
                                            options.push(`${params.filters[i].key}_min=${min * 0.01}`)
                                            options.push(`${params.filters[i].key}_max=${max * 0.01}`)
                                            break
                                        default:
                                            options.push(`${params.filters[i].key}=${params.filters[i].range}`)
                                    }
                                }
                            }
                        }


                        if (params.sorters && params.sorters.length > 0) {
                            let sorterParam = ''
                            for (let i = 0; i < params.sorters.length; i++) {
                                let key: string
                                switch (params.sorters[i].key) {
                                    case ('days_to_dead_line'):
                                        key = 'days_to_deadline'
                                        break
                                    case ('days_to_expiration'):
                                        key = 'days_to_expire'
                                        break
                                    default:
                                        key = params.sorters[i].key
                                        break
                                }
                                if (params.sorters[i].type === 'ascend') {
                                    if (i === 1) {
                                        sorterParam = key
                                    } else {
                                        sorterParam = key.concat(',', sorterParam)
                                    }
                                }
                                if (params.sorters[i].type === 'descend') {
                                    if (i === 1) {
                                        sorterParam = `-${key}`
                                    } else {
                                        sorterParam = `-${key}`.concat(',', sorterParam)
                                    }
                                }
                            }
                            sorterParam = 'ordering=' + sorterParam
                            options.push(sorterParam)
                        }

                        if (options.length > 0) {
                            url += '?'
                            for (let i = 0; i < options.length; i++) {
                                url += options[i]
                                if (i < options.length - 1) {
                                    url += '&'
                                }
                            }
                        }
                    }
                    return url
                },
                transformResponse: (response: { results: NegotiationModel[], premiums: number | null, count: number }, meta, args) => {
                    return {
                        count: response.count,
                        premiums: response.premiums,
                        results: response.results
                    }
                },
                // keepUnusedDataFor: 5,
                //cache for 5s
            }),
            createNegotiationStatusTransition: builder.mutation<NegotiationTransitionModel, { id: string, data: CreateNegotiationTransitionRequest }>({
                query: ({id, data}) => ({
                    url: `/negotiation/negotiations/${id}/transitions/`,
                    method: 'POST',
                    body: {...data}

                }),
            }),
            getNegotiationStatusTransitions: builder.query<NegotiationTransitionHistory[], string>({
                query: (id) => `/negotiation/negotiations/${id}/transitions/`,
                // keepUnusedDataFor: 5,
                //cache for 5s
            }),

            getNegotiationReferringUsers: builder.query<NegotiationUserModel[], string>({
                query: (id) => `/negotiation/negotiations/${id}/referring_users/`,
                // keepUnusedDataFor: 5,
                //cache for 5s
            }),
            getNegotiationReferred: builder.query<GetNegotiationReferred, {page?: number, page_size?: number}>({
                query: (args) => ({
                    url: `negotiation/negotiations/referred/`,
                    params: args
                })
                // keepUnusedDataFor: 5,
                //cache for 5s
            }),

            getDownloadNegotiations: builder.mutation <{ error: boolean, message: string }, QueryNegotiationsParam | void>({
                query: (params: DownloadNegotiationsParam | void) => {
                    let url = '/negotiation/negotiations/download'
                    if (params) {
                        let options: string[] = []

                        if (typeof params.all_fields === 'boolean') {
                            options.push(`all_fields=${params.all_fields}`)
                        }

                        if (params.filters) {
                            for (let i = 0; i < params.filters.length; i++) {
                                if (params.filters[i].range.length) {
                                    switch (params.filters[i].key) {
                                        case ('reception_date'):
                                        case ('policy_effective_date'):
                                        case ('policy_expiring_date'):
                                            options.push(`${params.filters[i].key}_from=${params.filters[i].range[0]} 00:00:00`)
                                            options.push(`${params.filters[i].key}_to=${params.filters[i].range[1]} 23:59:59`)
                                            break
                                        case ('uw_year'):
                                            options.push(`${params.filters[i].key}_from=${params.filters[i].range[0]}`)
                                            options.push(`${params.filters[i].key}_to=${params.filters[i].range[1]}`)
                                            break
                                        case ('isa_quoted_premium_share'):
                                        case ('insured_sum'):
                                        case ('attachment_point'):
                                        case ('days_to_dead_line'):
                                        case ('days_to_expiration'):
                                            options.push(`${params.filters[i].key}_min=${params.filters[i].range[0]}`)
                                            options.push(`${params.filters[i].key}_max=${params.filters[i].range[1]}`)
                                            break
                                        case ('reinsured_share_perc'):
                                            const min = Number(params.filters[i].range[0])
                                            const max = Number(params.filters[i].range[1])
                                            options.push(`${params.filters[i].key}_min=${min * 0.01}`)
                                            options.push(`${params.filters[i].key}_max=${max * 0.01}`)
                                            break
                                        default:
                                            options.push(`${params.filters[i].key}=${params.filters[i].range}`)
                                    }
                                }
                            }
                        }

                        if (params.sorters && params.sorters.length > 0) {
                            let sorterParam = ''
                            for (let i = 0; i < params.sorters.length; i++) {
                                let key: string
                                switch (params.sorters[i].key) {
                                    case ('days_to_dead_line'):
                                        key = 'days_to_deadline'
                                        break
                                    case ('days_to_expiration'):
                                        key = 'days_to_expire'
                                        break
                                    default:
                                        key = params.sorters[i].key
                                        break
                                }
                                if (params.sorters[i].type === 'ascend') {
                                    if (i === 1) {
                                        sorterParam = key
                                    } else {
                                        sorterParam = key.concat(',', sorterParam)
                                    }
                                }
                                if (params.sorters[i].type === 'descend') {
                                    if (i === 1) {
                                        sorterParam = `-${key}`
                                    } else {
                                        sorterParam = `-${key}`.concat(',', sorterParam)
                                    }
                                }
                            }
                            sorterParam = 'ordering=' + sorterParam
                            options.push(sorterParam)
                        }

                        if (options.length > 0) {
                            url += '?'
                            for (let i = 0; i < options.length; i++) {
                                url += options[i]
                                if (i < options.length - 1) {
                                    url += '&'
                                }
                            }
                        }
                    }

                    return {
                        url: url,
                        //method: 'GET',
                        //headers: {'Content-Type': 'application/csv'},
                        responseHandler: async (response) => {
                            if (response.status === 200) {
                                await response.blob()
                                    .then((payload) => {
                                        const url = URL.createObjectURL(payload)
                                        let filename = '';
                                        const disposition = response.headers.get('content-disposition')
                                        if (disposition) {
                                            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                            const matches = filenameRegex.exec(disposition);
                                            if (matches != null && matches[1]) {
                                                filename = matches[1].replace(/['"]/g, '');
                                            }
                                        }
                                        const a = document.createElement('a');
                                        a.href = url;
                                        //a.download = `Trattative_${dayjs().format('DD/MM/YYYY')}.csv`
                                        a.download = filename
                                        document.body.appendChild(a);
                                        a.click();
                                        URL.revokeObjectURL(url)
                                    })
                                    .catch((error) => {
                                        console.error('Blob Error', error)
                                    })
                            } else {
                                console.error('Download csv error - status: ', response.status)
                            }
                        },
                        cache: "no-cache",
                    }
                },
            }),
            renewNegotiation: builder.mutation<{ new_negotiation_uuid: string | null }, { uuid: string, data: { renew: boolean, modify: boolean | null } }>({
                query: ({uuid, data}) => ({
                    url: `/negotiation/negotiations/${uuid}/renew/`,
                    method: 'POST',
                    body: {...data}
                }),
            }),
            archiveNegotiation: builder.mutation<void, { uuid: string }>({
                query: ({uuid}) => ({
                    url: `/negotiation/negotiations/${uuid}/archive/`,
                    method: 'POST',
                }),
            }),
        });
    }
})

export const {
    useCreateNegotiationMutation,
    useCheckDuplicatesNegotiationMutation,
    useUpdateNegotiationMutation,
    useLazyGetNegotiationDetailQuery,
    useLazyGetNegotiationsQuery,
    useLazyGetNegotiationStatusTransitionsQuery,
    useCreateNegotiationStatusTransitionMutation,
    useGetDownloadNegotiationsMutation,
    useRenewNegotiationMutation,
    useArchiveNegotiationMutation,
    useGetNegotiationReferringUsersQuery,
    useLazyGetNegotiationReferredQuery
} = negotiationsApiSlice