import {useParams} from "react-router-dom";
import {useEffect, useState} from 'react';

import {
    Col,
    Row,
    Card,
    Tag,
    Form,
    Input,
    Radio,
    Button,
    Breadcrumb,
    Layout,
    Menu,
    Typography,
    Collapse,
    InputNumber,
    FormListFieldData, Space
} from 'antd';

import {useTranslation} from 'react-i18next';
import {CommonFormComponentProps} from "types/negotiations/components";


import {DeleteOutlined, PlusOutlined, SolutionOutlined, RightOutlined, DownOutlined} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import {AntCurrencyFormatInput} from "components/inputNumber";
import {isFulfilled} from "@reduxjs/toolkit";
import {FormInstance} from "antd/es/form/Form";
import {NegotiationFormModel, PolicyModel} from "types/negotiations";
import {isEqual} from "lodash";
import {rnfToNumber} from "utils/utils";
import TextArea from "antd/es/input/TextArea";

const {Header, Content, Footer, Sider} = Layout;
const {Title, Paragraph} = Typography
const {Panel} = Collapse;


export default function PoliciesAndIssues({
                                              forwaredRef,
                                              formInstance,
                                              debounceInputChange,
                                              negotiation,
                                              onFormValuesChange,
                                              disabled,
                                              setCalculateField,
                                              getCalculatedField
                                          }: CommonFormComponentProps) {

    const {installmentTypes} = useSelector(selectOptions)
    const watchIsFrameworkAgreement = Form.useWatch('is_framework_agreement', formInstance);
    const watchInstallmentType = Form.useWatch('installment_type', formInstance)
    const watchHasRegulationPremium = Form.useWatch('has_regulation_premium', formInstance)
    const watchOtherPolicies = Form.useWatch('other_policies', formInstance)


    return (
        <Card ref={forwaredRef} id="policiesAndIssues" bordered={false} style={{
            // padding: '24px',
            borderRadius: '12px',
            width: '100%'
        }}>
            <Form
                form={formInstance}
                disabled={disabled}
                name='policiesAndIssues'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
            >
                <Row>
                    <Col>
                        <Title level={3}>
                            <SolutionOutlined/>
                        </Title>
                    </Col>
                    <Col style={{marginLeft: '12px'}}>
                        <Title level={3}>Polizze ed emissioni</Title>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={12}>
                        <Form.Item style={{marginTop: '24px'}} name='is_framework_agreement' label="Polizza" required>
                            <Radio.Group>
                                <Radio value={false}>Singola </Radio>
                                <Radio value={true}>Convenzione</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item style={{marginTop: '24px'}} name='has_regulation_premium' label="Regolazione premio"
                                   required>
                            <Radio.Group>
                                <Radio value={true}>Sì </Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>


                {watchIsFrameworkAgreement &&
                    <Form.Item label="Convenzione" name='framework_agreement' required>
                        <Input placeholder="Convenzione"/>
                    </Form.Item>
                }
                {!watchIsFrameworkAgreement ?
                    <Row align='middle' justify='space-between'>
                        <Col style={{width: '100%'}}>
                            <Form.Item label="Frazionamento premio" name='installment_type' required>
                                <Radio.Group>
                                    {installmentTypes.map(type => <Radio key={type.value}
                                                                         value={type.value}>{type.text} </Radio>)}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {watchInstallmentType === 'O' && (
                            <Col style={{width: '100%'}}>
                                <Form.Item label="Numero frazionamenti premio" name='installments_number' required>
                                    <InputNumber style={{width: '100%'}}/>

                                </Form.Item>
                            </Col>
                        )}

                        <Col xs={24} md={11}>
                            <Form.Item label="Premio imponibile totale" name='policy_premium' required>
                                <AntCurrencyFormatInput disabled={getCalculatedField('policy_premium')}
                                                        calculate={() => setCalculateField('policy_premium')}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={11}>
                            <Form.Item label="Importo assicurato" name='policy_insured_sum' required>
                                <AntCurrencyFormatInput/>
                            </Form.Item>
                        </Col>
                    </Row>
                    : <FrameworkAgreementPoliciesList installmentTypes={installmentTypes} formInstance={formInstance}/>
                }
                {watchHasRegulationPremium &&
                    <Form.Item label="Premio di regolazione (nostra quota)" name='regulation_premium' required>
                        <AntCurrencyFormatInput/>
                    </Form.Item>}
                <Form.Item label="Note" name='policy_notes' required>
                    <TextArea autoSize={{minRows: 3}} placeholder="Note"/>
                </Form.Item>
            </Form>
        </Card>

    )
}

type FrameworkAgreementPoliciesListProps = { installmentTypes: { value: string, text: string }[], formInstance: FormInstance<NegotiationFormModel> }

export function FrameworkAgreementPoliciesList({installmentTypes, formInstance}: FrameworkAgreementPoliciesListProps) {

    const [activeKeys, setActiveKeys] = useState<string | string[]>([])

    return (
        <Form.List name='other_policies'>
            {(fields, {add, remove}) => {
                return <>
                    <Row align='middle' justify='space-between'>
                        <Col>
                            <Title level={5}>Lista polizze in convenzione</Title>
                        </Col>
                        <Col>
                            <Button type='primary' icon={<PlusOutlined/>} onClick={() => add({
                                installment_type: null,
                                installments_numer: null,
                                policy_premium: null,
                                policy_insured_sum: null
                            })}>Aggiungi polizza</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{marginTop: '24px', marginBottom: '24px'}}>
                            <Collapse style={{width: '100%'}} onChange={(keys) => setActiveKeys(keys)}
                                      activeKey={activeKeys} defaultActiveKey={['1']}>
                                {fields.map((field, idx) => {
                                    return (
                                        <Panel
                                            showArrow={false}
                                            key={idx}

                                            header={<FrameworkAgreementPolicyHeader field={field}
                                                                                    deletePolicy={() => remove(idx)}
                                                                                    isActive={activeKeys === idx.toString() || !!(Array.isArray(activeKeys) && activeKeys.find(key => key === idx.toString()))}/>}
                                            // style={{ width: '100%', marginTop: '36px', marginBottom: '36px' }} >
                                            style={{width: '100%'}}>
                                            <FrameworkAgreementPolicyBody field={field}
                                                                          installmentTypes={installmentTypes}
                                                                          formInstance={formInstance}/>

                                        </Panel>

                                    )
                                })}
                            </Collapse>

                        </Col>
                    </Row>
                </>
            }}
        </Form.List>
    )
}

function FrameworkAgreementPolicyHeader({
                                            field,
                                            deletePolicy,
                                            isActive
                                        }: { field: FormListFieldData, deletePolicy: () => void, isActive: boolean }) {


    return (
        <Row style={{padding: '8px 12px'}} align='middle' justify='space-between'>
            <Col>
                <Row align='middle' justify='start'>
                    <Col>
                        {isActive ? <DownOutlined/> : <RightOutlined/>}
                    </Col>
                    <Col style={{marginLeft: '12px'}}>
                        <Title level={5}>Polizza {field.name + 1}</Title>
                    </Col>
                </Row>
            </Col>
            <Col>
                <Button icon={<DeleteOutlined/>} onClick={deletePolicy}/>
            </Col>
        </Row>

    )
}

export function FrameworkAgreementPolicyBody({
                                                 field,
                                                 installmentTypes,
                                                 formInstance
                                             }: { field: FormListFieldData } & FrameworkAgreementPoliciesListProps) {

    const watchInstallmentType = Form.useWatch(['other_policies', field.name, 'installment_type'], formInstance)
    return (
        <Row align='middle' justify='space-between' style={{padding: '8px 12px'}}>
            <Col style={{width: '100%'}}>
                <Form.Item {...field} label="Frazionamento premio" name={[field.name, 'installment_type']} required>
                    <Radio.Group>
                        {installmentTypes.map(type => <Radio key={type.value} value={type.value}>{type.text} </Radio>)}
                    </Radio.Group>
                </Form.Item>
            </Col>
            {watchInstallmentType === 'O' && (
                <Col style={{width: '100%'}}>
                    <Form.Item {...field} label="Numero frazionamenti premio" name={[field.name, 'installments_number']}
                               required>
                        <InputNumber style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
            )}

            <Col style={{width: '48%'}}>
                <Form.Item {...field} label="Premio imponibile totale" name={[field.name, 'policy_premium']} required>
                    <AntCurrencyFormatInput/>
                </Form.Item>
            </Col>
            <Col style={{width: '48%'}}>
                <Form.Item {...field} label="Importo assicurato" name={[field.name, 'policy_insured_sum']} required>
                    <AntCurrencyFormatInput/>
                </Form.Item>
            </Col>
        </Row>
    )
}