import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Input, message, Pagination, Row, Space, Table, TablePaginationConfig, Tag, Tooltip} from "antd";
import {SearchOutlined} from '@ant-design/icons';
import {ColumnsType} from "antd/es/table";
import {PendingEmailsModel} from "../../../types/pendingEmails";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setTablesPreferences} from "redux/features/userSlice";
import {useSetUserTablesPreferencesMutation} from "redux/api/userPreferencesApiSlice";
import {useLazyGetPendingEmailsQuery} from "../../../redux/api/pendingEmailsApiSlice";
import {useLazyGetNegotiationUsersQuery} from "../../../redux/api/negotiationUsersApiSlice";
import {useNavigate} from "react-router-dom";
import {TableFilter, TableSorter, Views} from "../../../types";
import {SorterResult} from "antd/lib/table/interface";
import _, {multiply, set} from "lodash";
import {setNegotiationUsers} from "../../../redux/features/negotiationUsersSlice";
import {FilterConfirmProps} from "antd/es/table/interface";
import {ColumnType} from "antd/lib/table";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter, getColumnDateProps, getStandardFilter} from "./columnsUtils";
import FilterContainer from "./FilterContainer";

export default function PendingEmailTable() {

    const [fetchEmails, {
        data: pendingEmails,
        isLoading: isPendingEmailsLoading,
        isFetching: isPendingEmailsFetching,
        isError: isPendingEmailsError
    }] = useLazyGetPendingEmailsQuery()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [triggerNegotiationUsers, {
        data: negotiationUsersData,
        isLoading: isNegotiationUsersLoading,
        isUninitialized: isNegotiationUsersUnitialized
    }] = useLazyGetNegotiationUsersQuery()
    const user = useSelector(selectUser)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [updatePreferences] = useSetUserTablesPreferencesMutation()
    const filterPreferences = user && user.preferences && user.preferences.tables && user.preferences.tables[Views[user.preferences.current_view]] && user.preferences.tables[Views[user.preferences.current_view]].filters ? user.preferences.tables[Views[user.preferences.current_view]].filters as TableFilter[] : [] as TableFilter[]
    const sorterPreferences = user && user.preferences && user.preferences.tables && user.preferences.tables[Views[user.preferences.current_view]] && user.preferences.tables[Views[user.preferences.current_view]].sorters ? user.preferences.tables[Views[user.preferences.current_view]].sorters as TableSorter[] : [] as TableSorter[]

    const [searchText, setSearchText] = useState('');
    const searchInput = useRef(null);
    const [pageSize, setPageSize] = useState<number>(10)

    useEffect(() => {
        try {
            triggerNegotiationUsers({page: 0, usertypes: "UW,UWJ,UWS,LBM,CM"}).unwrap()
            if (negotiationUsersData)
                dispatch(setNegotiationUsers(negotiationUsersData))
        } catch (e: any) {
            message.error("Errore nel caricamento dei sottoscrittori")
            console.error("Errore", e)
        }
    }, [triggerNegotiationUsers])

    useEffect(() => {
        let option: any = {page: currentPage, page_size: pageSize}
        if (filterPreferences) {
            for (let i in filterPreferences) {
                switch (filterPreferences[i].key as string) {
                    case ('email_from'):
                        const senders = (): string => {
                            let _senders: string = ""
                            filterPreferences[i].range.forEach((el, index) => {
                                index === 0 ? _senders = el as string : _senders = _senders + "," + el
                            })
                            return _senders
                        }
                        option = {...option, email_from_user_profile: senders()}
                        break
                    case ('email_subject'):
                        option = {...option, search: filterPreferences[i].range[0]}
                        break
                    case ('email_forwarding_date'):
                        option = {
                            ...option,
                            email_forwarding_date_from: filterPreferences[i].range[0],
                            email_forwarding_date_to: filterPreferences[i].range[1] + " 23:59:59"
                        }
                        break
                    case ('job_status'):
                        const status = (): string => {
                            let _status: string = ""
                            filterPreferences[i].range.forEach((el, index)=> {
                                index === 0 ? _status = el as string : _status = _status + "," + el
                            })
                            return _status
                        }
                        option = {...option, job_status: status()}
                        break
                }
            }
        }
        if (sorterPreferences) {
            for (let i in sorterPreferences) {
                if (sorterPreferences[i].type === "ascend")
                    option = {...option, ordering: sorterPreferences[i].key}
                else
                    option = {...option, ordering: "-" + sorterPreferences[i].key}
            }
        }
        try {
            fetchEmails(option).unwrap()
        } catch (e: any) {
            message.error('Impossibile scaricare i dati')
            console.error('fetchPendingEmail', e)
        }
    }, [currentPage, filterPreferences, sorterPreferences, pageSize, fetchEmails])

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const getColumnSearchProps = (): ColumnType<PendingEmailsModel> => ({
        filterDropdown: (props) => (
            <FilterContainer {...props}>
                <Input
                    ref={searchInput}
                    placeholder={`Cerca per parola`}
                    value={props.selectedKeys[0]}
                    onChange={(e) => {
                        props.setSelectedKeys(e.target.value ? [e.target.value] : [])
                        endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
                    }}
                    onPressEnter={() => handleSearch(props.selectedKeys as string[], props.confirm)}
                    style={{
                        width: '20rem',
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
            </FilterContainer>
        ),
    })

    const columns: ColumnsType<PendingEmailsModel> = [
        {
            title: 'Mittente',
            dataIndex: 'email_from',
            key: 'email_from',
            sorter: {multiple: 1},
            ...getStandardFilter(negotiationUsersData?.map(el => ({text: el.user_full_name, value: el.uuid})) || []),
        },
        {
            title: 'Oggetto della mail',
            dataIndex: 'email_subject',
            key: 'email_subject',
            ellipsis: {
                showTitle: false,
            },
            sorter: {multiple: 1},
            ...getColumnSearchProps(),
            filterSearch: true,
            render: address => (
                <Tooltip placement="topLeft" title={address}>
                    <p style={{textOverflow: 'ellipsis', maxWidth: '20rem', overflow: 'hidden'}}> {address} </p>
                </Tooltip>
            ),
        },
        {
            title: 'Data ricezione',
            dataIndex: 'email_forwarding_date',
            key: 'email_forwarding_date',
            sorter: {multiple: 1},
            ...getColumnDateProps('email_forwarding_date'),
            render: (value: Date) => {
                return dayjs(value).format("DD-MM-YYYY")
            }
        },
        {
            title: 'Stato',
            dataIndex: ['job_status'],
            key: 'job_status',
            ...getStandardFilter(
                [{text: 'In lavorazione', value: 'P'},
                    {text: 'Completato', value: 'D'},
                    {text: 'Errore', value: 'E'}
                ]),
            filterMultiple: false,
            sorter: {multiple: 1},
            render: (text, record) => {
                switch (record.job_status) {
                    case('P'):
                        return <Tag color={"yellow"}>In lavorazione</Tag>
                    case('D'):
                        return <Tag color={"green"}>Completato</Tag>
                    case('E'):
                        return <Tag color={"red"}>Errore</Tag>
                }
            }
        },
        {
            title: 'Azioni',
            dataIndex: 'negotiation',
            key: 'negotiation',
            width: '12rem',
            render: (id) => {
                return <>{id ?
                    <Button type={"default"} size={"small"} onClick={() => navigate(`/negotiations/${id}`)}>Vai alla
                        trattativa</Button> : ""}</>
            }
        }
    ]

    return <div style={{marginTop: "1rem", cursor: 'default'}}>
        <Table<PendingEmailsModel> loading={isPendingEmailsLoading || isPendingEmailsFetching}
                                   pagination={false}
                                   scroll={{x: true}}
                                   locale={{
                                       filterConfirm: 'Applica filtro',
                                       filterReset: 'Reset filtro',
                                   }}
                                   onChange={(pag, fil, sor: SorterResult<any> | SorterResult<any>[], extra) => {
                                       switch (extra.action) {
                                           case "filter":
                                               let f: TableFilter[] = []
                                               for (let key of Object.keys(fil))
                                                   fil[key] ? f.push({
                                                       key: key,
                                                       range: fil[key] as string[] | number[]
                                                   }) : f.push({key: key, range: []})
                                               const filters = f.filter(el => el.range.length > 0)
                                               if (!_.isEqual(filters, filterPreferences)) {
                                                   dispatch(setTablesPreferences({[Views[user.preferences.current_view]]: {filters}}))
                                                   setCurrentPage(1)
                                                   updatePreferences({[Views[user.preferences.current_view]]: {filters: f.filter(el => el.range.length > 0)}})
                                               }
                                               break
                                           case "sort":
                                               let s: TableSorter[] = []
                                               if (Array.isArray(sor)) {
                                                   s = sor.map(el => ({
                                                       key: el.columnKey as string,
                                                       type: el.order as 'ascend' | 'descend'
                                                   }))
                                               } else {
                                                   if (sor.order === undefined)
                                                       s = []
                                                   else {
                                                       s = [{
                                                           key: sor.columnKey as string,
                                                           type: sor.order as 'ascend' | 'descend'
                                                       }]
                                                   }

                                               }
                                               dispatch(setTablesPreferences({[Views[user.preferences.current_view]]: {sorters: s}}))
                                               updatePreferences({[Views[user.preferences.current_view]]: {sorters: s}})
                                               break
                                       }
                                   }}
                                   columns={columns}
                                   dataSource={pendingEmails?.results.map(el => ({key: el.uuid, ...el}))}/>
        {pendingEmails?.count ?
            <Row justify={'end'} style={{marginTop: '1rem'}}><Pagination
                total={pendingEmails.count}
                showTotal={(total, range) => `${range[0]}-${range[1]} di ${total} email`}
                current={currentPage}
                showSizeChanger={true}
                onShowSizeChange={(current, size) => setPageSize(size)}
                pageSize={pageSize}
                onChange={page => setCurrentPage(page)}
            /></Row> : ""}
    </div>
}
