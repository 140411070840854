import CustomTable from "./CustomTable";
import {getStandardFilter, renewsColumns} from "./columnsUtils";
import {RenewsNegotiation, States, Views} from "types";
import React, {useCallback, useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {useSelector} from "react-redux";
import {
    QueryNegotiationsParam,
    useLazyGetNegotiationsQuery,
    useRenewNegotiationMutation
} from "redux/api/negotiationsApiSlice";
import {selectOptions} from "redux/features/optionsSlice";
import {Button, Menu, message, Space} from "antd";
import {useNavigate} from "react-router-dom";
import DropdownButton from "antd/es/dropdown/dropdown-button";
import MenuItem from "antd/lib/menu/MenuItem";
import {selectUser} from "../../../redux/features/userSlice";
import {LobModel} from "../../../types/options";
import Tag from "antd/es/tag";
import {getContrastYIQ} from "../../../utils/utils";

const RenewsTable = () => {
    const [columns, setColumns] = useState<ColumnsType<RenewsNegotiation>>(renewsColumns)

    const options = useSelector(selectOptions)
    const user = useSelector(selectUser)
    const [triggerGetNegotiations, {data: negotiations, isLoading, isFetching}] = useLazyGetNegotiationsQuery()
    const [renew, {data}] = useRenewNegotiationMutation();
    const navigate = useNavigate()

    const handleGetData = useCallback((params: QueryNegotiationsParam | void) => {
        const stateFilter = {key: 'state', range: [States.InRinnovo]}
        if (params) {
            triggerGetNegotiations({
                ...params,
                filters: params.filters ? [...params.filters, stateFilter] : [stateFilter]
            })
        } else {
            triggerGetNegotiations({filters: [stateFilter]})
        }
    }, [triggerGetNegotiations])

    const handleConfirmClick = useCallback(async (negotiationUUID: string, toRenew: boolean, toModify: boolean | null) => {
        try {
            const response: { new_negotiation_uuid: string | null } = await renew({
                uuid: negotiationUUID,
                data: {renew: toRenew, modify: toModify}
            }).unwrap()
            message.success(toRenew ? 'Trattativa rinnovata con successo' : 'Trattativa bloccata correttamente', 2)
            if (toRenew && toModify && response.new_negotiation_uuid)
                navigate(response.new_negotiation_uuid)
            else handleGetData({filters: user.preferences.tables.renews.filters, sorters: user.preferences.tables.renews.sorters})
        } catch (e) {
            message.error('Rinnovo trattativa fallito', 2)
        }
    }, [handleGetData, navigate, renew, user.preferences.tables.renews.filters, user.preferences.tables.renews.sorters])

    //setting table: setting filters and negotiation action column
    useEffect(() => {
        setColumns(prevState => prevState.map(el => {
            switch (el.key) {
                case 'cancellation_terms_days':
                    return {...el, ...getStandardFilter(options.cancellationDays)}
                case 'lob':
                    return {
                        ...el,
                        render: (lob: LobModel) => {
                            const foundLob = options.lobs.find(el => el.uuid === lob.uuid)
                            if (foundLob) {
                                return <Tag
                                    style={{
                                        borderRadius: '100px',
                                        width: '5rem',
                                        height: '1.25rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 0.5rem',
                                        color: getContrastYIQ(foundLob.color)
                                    }}
                                    color={foundLob.color}
                                >
                                    {foundLob.name}
                                </Tag>
                            }
                        },
                        ...getStandardFilter(options.lobs.map(el => ({text: el.name, value: el.uuid})))
                    }
                case 'renewsActions':
                    return {
                        ...el, render: (_, record) => {
                            return (record.is_auto_renewal === true || record.is_auto_renewal === null ?
                                    <Space onClick={(event) => event.stopPropagation()} size="middle">
                                        <DropdownButton style={{width: '11em'}}
                                                        overlay={
                                                            <Menu>
                                                                <MenuItem key={'1'} onClick={() => {
                                                                    handleConfirmClick(record.uuid, true, true)
                                                                }}>Con modifiche</MenuItem>
                                                                <MenuItem key={'2'} onClick={() => {
                                                                    handleConfirmClick(record.uuid, true, false)
                                                                }}>Senza modifiche</MenuItem>
                                                            </Menu>} placement={"bottomRight"}>
                                            Effettua Rinnovo
                                        </DropdownButton>
                                        <Button type={'primary'}
                                                onClick={() => {
                                                    handleConfirmClick(record.uuid, false, null)
                                                }}
                                                danger={true}>Blocca rinnovo</Button>
                                    </Space>
                                    : <Space size="middle">
                                        <Button style={{width: '11em'}}
                                                type={'primary'}
                                                onClick={() => {
                                                    handleConfirmClick(record.uuid, true, true)
                                                }}>Conferma
                                            rinnovo</Button>
                                        <Button type={'primary'}
                                                onClick={() => {
                                                    handleConfirmClick(record.uuid, false, null)
                                                }}
                                                danger={true}>Blocca rinnovo</Button>
                                    </Space>
                            )
                        }
                    }
                default:
                    return el
            }
        }))
    }, [handleConfirmClick, options.cancellationDays, options.lobs])


    return <CustomTable view={Views.renews} cols={columns} setCols={setColumns} data={negotiations}
                        getData={handleGetData} isLoading={isLoading || isFetching}/>
}

export default RenewsTable