import {Col, Divider, Row, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {euro} from "utils/utils";
import HighchartsReact from "highcharts-react-official";
import ChartContainer from "./ChartContainer";
import BarPercentageChart from "../HighCharts/BarPercentageChart";
import {
    useLazyGetGapToPlanChartQuery,
    useLazyGetSubmissionReceptionChartQuery
} from "../../../redux/api/chartsApiSlice";
import {selectUser} from "../../../redux/features/userSlice";

export function Description() {
    return <>
        Il grafico mostra la percentuale dei premi in Bound rispetto il budget prefissato.
        <dl>
            <dt>Premi per raggiungimento plan:</dt>
            <dd>rappresenta il budget totale della LOB analizzata.</dd>
            <dt>Premi new business:</dt>
            <dd>rappresenta il budget new business della LOB analizzata.</dd>
            <dt>Premi raggiunti:</dt>
            <dd>rappresenta il totale dei premi BOUND fino ad oggi.</dd>
        </dl>
    </>
}

export default function GapToPlan(props: { chartRef: React.RefObject<HighchartsReact.RefObject> }) {
    const title = "Premi rispetto gap to plan"
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetGapToPlanChartQuery()
    const [percentageToDisplay, setPercentageToDisplay] = useState<string>('')
    const [goal, setGoal] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const [regulation, setRegulation] = useState<string>('')

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (data) {
            setPercentageToDisplay(data.percentage ? (data?.percentage * 100).toFixed(2) : '0')
            setAmount(data.amount ? (data?.amount).toFixed(2) : '0')
            setGoal(data.goal ? (data?.goal).toFixed(2) : '0')
            setRegulation(data.regulation ? (data?.regulation).toFixed(2) : '0')
        }
    }, [data])

    return <ChartContainer
        title={title}
        loading={isLoading || isFetching}
        description={<Description />}>
        <BarPercentageChart
            chartRef={props.chartRef}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title,
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        },
                        chart: {
                            height: 150,
                            spacing: [10, 10, 15, 10],
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    x: 0,
                                    y: -25
                                },
                            },
                        },

                    },
                },
                series: [
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: [Number(percentageToDisplay) > 100 ? 0 : 100 - Number(percentageToDisplay)],
                        color: '#F5F5F5',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: Array.from({length: 1}, () => Number(percentageToDisplay)),
                        color: '#13C2C2',
                        dataLabels: {
                            enabled: true,
                            format: `{y}%`
                        },
                    },

                ]
            }}/>
        <Divider style={{marginTop: 0, marginBottom: '12px'}}/>
        <Row justify={'space-between'}>
            <Col>Premi per raggiungimento plan:</Col>
            <Col style={{fontWeight: 'bolder'}}>{goal ? euro(Number(goal)) : euro(0)}</Col>
        </Row>
        <Row justify={'space-between'}>
            <Col>Premi raggiunti:</Col>
            <Col style={{fontWeight: 'bolder'}}>{amount ? euro(Number(amount)) : euro(0)}</Col>
        </Row>
        <Row justify={'space-between'}>
            <Col>Premi di regolazione:</Col>
            <Col style={{fontWeight: 'bolder'}}>{regulation ? euro(Number(regulation)) : euro(0)}</Col>
        </Row>


    </ChartContainer>
}