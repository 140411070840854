import {Select} from "antd";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {BudgetGroup} from "../../types";
import {selectUser, setChartBudgetGroup} from "../../redux/features/userSlice";
import {useSetChartsParamsMutation} from "../../redux/api/userPreferencesApiSlice";

export default function BudgetGroupSelect(props: { budgetGroups: BudgetGroup[] }) {
    const dispatch = useDispatch()
    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const [updateChartsParams] = useSetChartsParamsMutation()

    return (props.budgetGroups.length > 0 ? <Select options={props.budgetGroups}
                                                    labelInValue
                                                    virtual={false}
                                                    value={budgetGroup}
                                                    onSelect={(option: any) => {
                                                        dispatch(setChartBudgetGroup({
                                                            label: option.label,
                                                            value: option.value
                                                        }))
                                                        updateChartsParams({
                                                            budget_group: {
                                                                label: option.label,
                                                                value: option.value
                                                            }
                                                        })
                                                    }
                                                    }
                                                    style={{width: 120, textAlign: 'center'}}/> : null)

}