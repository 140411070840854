import {configureStore} from "@reduxjs/toolkit";
import {apiSlice} from "./api/baseApiSlice";
import authReducer from 'redux/features/authSlice'
import optionsReducer from 'redux/features/optionsSlice'
import negotiationUsersReducer from 'redux/features/negotiationUsersSlice'
import userReducer from 'redux/features/userSlice'
import overlayReducer from 'redux/features/overlaySlice'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        userProfiles: negotiationUsersReducer,
        options: optionsReducer,
        user: userReducer,
        overlay: overlayReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        // serializableCheck: {ignoredPaths: ['api.queries.getBelatedReportsDownload',]}
        //serializableCheck: false
    })
        .concat(apiSlice.middleware),
    devTools: true
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {api: CombinedState, auth: AuthSliceState}
export type AppDispatch = typeof store.dispatch