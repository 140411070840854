import {apiSlice} from "redux/api/baseApiSlice";
import {
    BelatedReports, BelatedReportsOptions, BelatedReportsResponse
} from "types/negotiations";
import dayjs from "dayjs";

export const belatedHistoryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBelatedReports: builder.query<BelatedReportsResponse, { page: number, page_size: number, base_report_type?: string, reference_date_from?: string, reference_date_to?: string, date_creation_from?: string, date_creation_to?: string, ordering?: string}>({
            query: (args) => {
                //const { page, page_size } = args;
                return {url: `/negotiation/belated_emitted_reports/`, params: args}
            }
        }),
        getBelatedReportsOptions: builder.query<BelatedReportsOptions[], void>({
            query: () => `/negotiation/belated_emitted_report_options`
        }),
        getBelatedReportsDownload: builder.query<Blob, { uuid: string, type: string }>({
            query: ({uuid, type}) => {
                return {
                    url: `/negotiation/belated_emitted_reports/${uuid}/download/?type=${type}`,
                    responseHandler: async (response) => {
                        if (response.status === 200)
                            await response.blob()
                                .then((payload) => {
                                    const url = URL.createObjectURL(payload)
                                    let filename = '';
                                    const disposition = response.headers.get('content-disposition')
                                    if (disposition) {
                                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                        const matches = filenameRegex.exec(disposition);
                                        if (matches != null && matches[1]) {
                                            filename = matches[1].replace(/['"]/g, '');
                                        }
                                    }
                                    const a = document.createElement('a');
                                    a.href = url;
                                    a.download = filename
                                    document.body.appendChild(a);
                                    a.click();
                                    URL.revokeObjectURL(url)
                                })
                                .catch((error) => console.error('Blob Error', error))
                        else
                            console.error('Download csv error - status: ', response.status)
                    },

                    cache: "no-cache",
                }
            },

        }),
        createBelatedReports: builder.mutation<BelatedReports[], {"reference_date"?: string}>({
            query: (data) => {
                return {
                    url: `/negotiation/belated_emitted_reports/`,
                    method: 'POST',
                    body: {...data}
                };
            },
        }),
    })
})

export const {
    useLazyGetBelatedReportsQuery,
    useLazyGetBelatedReportsOptionsQuery,
    useLazyGetBelatedReportsDownloadQuery,
    useCreateBelatedReportsMutation
} = belatedHistoryApiSlice
