import {useEffect, useMemo, useState} from 'react';

import {
    Col,
    Row,
    Card,
    Form,
    Input,
    Radio,
    Select,
    Tooltip,
    message
} from 'antd';

import {Breadcrumb, Layout, Menu, Typography} from 'antd';

import DatePicker from 'components/DatePicker'

import {useTranslation} from 'react-i18next';
import {CommonFormComponentProps} from "types/negotiations/components";
import {
    CalculatedField,
    FieldsCalculated,
    NegotiationFormModel,
    NegotiationLobUsers,
    NegotiationModel,
    NegotiationUserModel
} from "types/negotiations";
import {useDispatch, useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";

import {EditOutlined, MailOutlined, PlusOutlined} from '@ant-design/icons';
import {selectNegotiationUsers} from "redux/features/negotiationUsersSlice";
import {useLazyGetLobUsersQuery, useLazyGetNegotiationAttorneysQuery} from "redux/api/negotiationUsersApiSlice";
import {RiskAppSpinnerInPage} from "components/spinners";
import {useCreateBrokerMutation} from "redux/api/brokersApiSlice";
import {AntCurrencyFormatInput} from "components/inputNumber";
import {selectUser} from "redux/features/userSlice";
import {BrokerSelect} from "../selects";
import CalculateButton from "../../buttons/CalculateButton";

const {Header, Content, Footer, Sider} = Layout;
const {Title, Paragraph} = Typography
const {Option} = Select

export default function SubmissionForm({
                                           forwaredRef,
                                           formInstance,
                                           debounceInputChange,
                                           negotiation,
                                           onFormValuesChange,
                                           getCalculatedField,
                                           setCalculateField,
                                           disabled
                                       }: CommonFormComponentProps) {

    const [getAttorneys, {isLoading: isAttorneysLoading}] = useLazyGetNegotiationAttorneysQuery()
    const [uwAttorneys, setUwAttorneys] = useState<NegotiationUserModel[]>([])

    const watchIsia = Form.useWatch('is_isia', formInstance);
    const options = useSelector(selectOptions)
    const userProfiles = useSelector(selectNegotiationUsers)
    const user = useSelector(selectUser)

    const watchLob = Form.useWatch('lob', formInstance)
    const watchUw = Form.useWatch('underwriter', formInstance)
    const watchEffectiveDate = Form.useWatch('policy_effective_date', formInstance)

    const isUserManager = !!user.usertypes.find(type => ['ADMIN', 'LBM', 'CM'].includes(type.code))

    const uwAttorneysChoices = useMemo(() => {

        const choices = uwAttorneys.map(uw => <Option key={uw.uuid} value={uw.uuid}>{uw.user_full_name}</Option>)
        const isUwAlreadyPresentInTheChoices = negotiation.attorney_from === null || uwAttorneys.some(el => el.uuid === negotiation.attorney_from?.uuid)
        // if the uw associated to the negotiation is no more active, add the option to the select
        if (!isUwAlreadyPresentInTheChoices) {
            choices.push(<Select.Option key={negotiation.attorney_from?.uuid}
                                        value={negotiation.attorney_from?.uuid}>{negotiation.attorney_from?.user_full_name}</Select.Option>)
        }
        return <>{choices}</>
    }, [negotiation.attorney_from, uwAttorneys])

    useEffect(() => {
        async function fetchAttorneys({negotiation_id}: { negotiation_id: string }) {
            try {
                const attorneys = await getAttorneys({negotiation_id}).unwrap()
                setUwAttorneys(attorneys)
            } catch (e: any) {
                message.error('Impossibile recuperare le procure')
                console.error('Fetch attroneys', e)
                setUwAttorneys([])
            }
        }

        if (negotiation.uuid)
            fetchAttorneys({negotiation_id: negotiation.uuid})
        else
            setUwAttorneys([])

        // if underwriter change => update attorneys
    }, [getAttorneys, negotiation.uuid, negotiation.underwriter])

    return (
        <Card ref={forwaredRef} id="submission" bordered={false} style={{
            // padding: '24px',
            borderRadius: '12px',
            width: '100%'
        }}>
            <Form
                form={formInstance}
                disabled={disabled}
                name='submission'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
            >
                <Row style={{marginBottom: '24px'}}>
                    <Col>
                        <Title level={3}>
                            <MailOutlined/>
                        </Title>
                    </Col>
                    <Col style={{marginLeft: '12px'}}>
                        <Title level={3}>Submission</Title>
                    </Col>
                </Row>
                <Form.Item name={'is_calculated'} hidden/>

                <Form.Item
                    label="LOB"
                    name='lob'
                    required
                    hidden={true}
                >
                    <Select
                        virtual={false}
                        showSearch
                        disabled={!isUserManager}
                        placeholder="Seleziona una LOB"
                        optionFilterProp="children"
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {options.lobs.map(lob => <Option key={lob.uuid} value={lob.uuid}>{lob.name}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='Underwriter'
                           name='underwriter'
                           required
                           hidden={true}
                >
                    <Select
                        disabled={!watchLob || !isUserManager}
                        virtual={false}
                        showSearch
                        placeholder="Seleziona un underwriter"
                        optionFilterProp="children"
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {negotiation.lob.underwriters.map(uw => <Option key={uw.uuid}
                                                                        value={uw.uuid}>{uw.user_full_name}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Procura utilizzata" name='attorney_from' required>
                    <Select
                        virtual={false}
                        disabled={!watchUw}
                        showSearch
                        placeholder={watchUw ? "Seleziona una procura" : "Seleziona prima un underwriter"}
                        optionFilterProp="children"

                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {uwAttorneysChoices}
                    </Select>
                </Form.Item>
                <Form.Item label="ISIA" name={['is_isia']} required>
                    {/* <Radio.Group onChange={onChange} value={value}> */}
                    <Radio.Group>
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                {watchIsia &&
                    <>
                      <Form.Item label="Segnalazione ISIA" name={['isia_type']} required>
                        <Select
                            virtual={false}
                            showSearch
                            placeholder="Seleziona un tipo di segnalazione"
                            optionFilterProp="children"
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                            }
                        >
                            {options.isiaTypes.map(type => <Option key={type.value}
                                                                   value={type.value}>{type.text}</Option>)}
                        </Select>
                      </Form.Item>

                      <Form.Item label="Sale ISIA" name={['isia_sale']} required>
                        <Select
                            virtual={false}
                            showSearch
                            placeholder="Seleziona un utente"
                            optionFilterProp="children"
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                            }
                        >
                            {userProfiles.filter(user => user.usertypes.find(type => type.code === 'AA')).map(user =>
                                <Option key={user.uuid} value={user.uuid}>{user.user_full_name}</Option>)}
                        </Select>
                      </Form.Item>
                    </>
                }

                <BrokerSelect
                    fieldName="broker"
                    label='Broker'
                    onCreation={(newBrokerId: string) => {
                        formInstance.setFieldsValue({broker: newBrokerId})
                        debounceInputChange({broker: newBrokerId})
                    }}
                    { ... !!negotiation.broker && {selectedBroker: negotiation.broker}}
                />

                <Row align='bottom' justify='space-between' >

                    <Col xs={24} md={watchIsia? 7 : 11}>
                        <Form.Item name='fee_broker_perc' label="Provvigioni broker" required>
                            <AntCurrencyFormatInput suffix={' %'} decimalScale={6} />
                        </Form.Item>
                    </Col>
                    {
                        watchIsia && (
                            <Col xs={24} md={7}>
                                <Form.Item name='fee_agency_perc' label="Provvigioni agenzia" required>
                                    <AntCurrencyFormatInput suffix={' %'}/>
                                </Form.Item>
                            </Col>
                        )
                    }
                    <Col xs={24} md={watchIsia? 7 : 11}>
                        <Tooltip placement='top'
                                 title={watchIsia ? ' Provvigioni broker + Provvigioni agenzia' : 'Provvigioni broker'}>
                            <Form.Item name='fee_total_perc' label="Provvigioni complessive" required>
                                <AntCurrencyFormatInput
                                    suffix={' %'}
                                    disabled={getCalculatedField('fee_total_perc')}
                                    calculate={() => setCalculateField('fee_total_perc')}
                                    decimalScale={6}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>


                <Form.Item label="Account Executive Broker" name={['broker_account_executive']} required>
                    <Input placeholder="Account executive broker"/>
                </Form.Item>
                <Form.Item label="Branch" name='broker_branch' required>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder="Seleziona un branch"
                        optionFilterProp="children"
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {options.provinces.map(province => <Option key={province.value}
                                                                   value={province.value}>{province.text}</Option>)}
                    </Select>
                </Form.Item>

                <Form.Item label="LPS" name='is_lps' required>
                    <Radio.Group>
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row align='bottom' justify='space-between'>
                    <Col style={{width: '48%'}}>
                        <Form.Item label="Data ricezione Submission" name='reception_date' required>
                            <DatePicker
                                disabled
                                clearIcon={false}
                                defaultPickerValue={undefined}
                                defaultValue={undefined}
                                style={{width: '100%'}}
                                placeholder='Seleziona una data'
                                format={'DD/MM/YYYY'}/>
                        </Form.Item>
                    </Col>
                    <Col style={{width: '48%'}}>
                        <Form.Item label="Data decorrenza" name='policy_effective_date' required>
                            <DatePicker
                                clearIcon={false}
                                defaultPickerValue={undefined}
                                defaultValue={undefined}
                                style={{width: '100%'}}
                                placeholder='Seleziona una data'
                                format={'DD/MM/YYYY'}/>
                        </Form.Item>
                    </Col>
                    <Col style={{width: '48%'}}>
                        <Form.Item label="Data scadenza" name='policy_expiring_date' required>
                            <DatePicker
                                defaultPickerValue={undefined}
                                defaultValue={undefined}
                                style={{width: '100%'}}
                                placeholder='Seleziona una data'
                                format={'DD/MM/YYYY'}
                                disabled={!watchEffectiveDate}
                                disabledDate={(current) => !!(!watchEffectiveDate || current <= watchEffectiveDate)}
                            />
                        </Form.Item>
                    </Col>
                    <Col style={{width: '48%'}}>
                        <Form.Item label="UW Year" name='uw_year' required>
                            <DatePicker style={{width: '100%'}} placeholder="Seleziona un anno" picker='year'/>
                        </Form.Item>
                    </Col>
                    <Col style={{width: '48%'}}>
                        <Form.Item label="Premio imponibile proposto 100%" name='quoted_premium' required>
                            <AntCurrencyFormatInput/>
                        </Form.Item>
                    </Col>
                    <Col style={{width: '48%'}}>
                        <Form.Item label="Quota ISA" name='isa_share_perc' required>
                            <AntCurrencyFormatInput suffix=" %"/>
                        </Form.Item>
                    </Col>
                    <Col style={{width: '100%'}}>
                        <Tooltip placement='top' title='Premio imponibile proposto * %Quota'>
                            <Form.Item label="Premio imponibile nostra quota" name='isa_quoted_premium_share'
                                       required>
                                <AntCurrencyFormatInput
                                    disabled={getCalculatedField('isa_quoted_premium_share')}
                                    calculate={() => setCalculateField('isa_quoted_premium_share')}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    {/* <Col style={{ width: '48%' }}>
                    <Form.Item label="Premio target" name='target_premium' required >
                        <InputNumber style={{ width: '100%' }} addonAfter="€" placeholder="input placeholder" onChange={(value) => debounceInputChange({ 'target_premium': parseFloat(value.toString()) })} />
                    </Form.Item>
                </Col> */}
                </Row>
            </Form>

        </Card>

    )
}