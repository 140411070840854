import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';

import {
    Typography,
    Divider,
    Modal,
    Col,
    Row,
    Card,
    Form,
    Button,
    Select,
    message,
    Space,
    FormInstance,
    Tag
} from 'antd';

import {useTranslation} from 'react-i18next';
import {
    RightOutlined,
    HistoryOutlined,
    UserAddOutlined,
    EditOutlined,
    FormOutlined,
    CheckCircleOutlined,
    UnorderedListOutlined, DeleteOutlined
} from '@ant-design/icons';
import {
    AssignNegotiationModal,
    EditNegotiationStatusModal, EditReferredModal,
    HistoryModal,
    MoveNegotiationToStatus,
    RestoreNegotiationStatus
} from './modals/state';
import {ChecklistFormDialog} from './modals/checklist';
import {
    NegotiationFormModel,
    NegotiationModel, NegotiationTransitionHistory,
    UpdateNegotiationRequest
} from 'types/negotiations';
import {OptionsModel} from 'types/options';


import {useSelector} from 'react-redux';
import {selectOptions} from 'redux/features/optionsSlice';
import {StatusIndicator} from './utils';
import {
    useArchiveNegotiationMutation,
    useCreateNegotiationStatusTransitionMutation,
    useLazyGetNegotiationStatusTransitionsQuery
} from 'redux/api/negotiationsApiSlice';
import {SimplifiedNegotiationStatusView} from './portfolio';
import dayjs from 'dayjs';
import ConfirmCancelModal from "../../../modals/ConfirmCancelModal";
import {useNavigate} from "react-router-dom";
import {selectUser} from 'redux/features/userSlice';
import {
    canSeeCheckList,
    canSeeReferralButton,
    canModifyUnderwriter,
    canCreateNewNegotiation
} from "../../../../utils/permission";
import _ from "lodash";
import StatusHelpModal from "../../../modals/StatusHelpModal";
import {States, UserTypes} from "../../../../types";
import EditUnderwriterModal from "../../../modals/EditUnderwriterModal";
import EditLobModal from "../../../modals/EditLobModal";

const {Title, Paragraph} = Typography

export function NegotiationDetailStatusManager({
                                                   negotiation,
                                                   setNegotiation,
                                                   options,
                                                   updateNegotiation,
                                                   isUpdateNegotiationLoading,
                                                   negotiationStatusHistory,
                                                   setNegotiationStatusHistory,
                                                   negotiationId,
                                                   isSimplifiedView,
                                                   form
                                               }: NegotiationDetailStatusPropsType & { negotiationStatusHistory: NegotiationTransitionHistory[], setNegotiationStatusHistory: Dispatch<SetStateAction<NegotiationTransitionHistory[]>>, negotiationId: string, isSimplifiedView: boolean, form: FormInstance<NegotiationFormModel> }) {

    const {states} = useSelector(selectOptions)

    const [isRestoreStateModalOpen, setIsRestoreStateModalOpen] = useState<boolean>(false);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false);
    const [isMoveNegotiationToStatusModalOpen, setIsMoveNegotiationToStatusModalOpen] = useState<boolean>(false);
    const [isEditNegotiationStatusModalOpen, setIsEditNegotiationStatusModalOpen] = useState<boolean>(false);
    const [isReferred, setIsReferred] = useState<boolean>(false);
    const [isAssignNegotiationModalOpen, setIsAssignNegotiationModalOpen] = useState<boolean>(false);
    const [isChecklistOpen, setIsChecklistOpen] = useState<boolean>(false)
    const [formFieldErrors, setFormFieldErrors] = useState<{ field: string, error: string }[]>()
    const [formFieldWarnings, setFormFieldWarnings] = useState<{ field: string, warning: string }[]>()

    const [isErrorStateTransitionModalOpen, setIsErrorStateTransitionModalOpen] = useState<boolean>(false)

    const [createStateTransition, {isLoading: isCreateStateTransitionLoading}] = useCreateNegotiationStatusTransitionMutation()

    const [triggerGetNegotiationStatusHistory, {isLoading: isNegotiationStatusHistoryLoading}] = useLazyGetNegotiationStatusTransitionsQuery()

    const fieldNotAvailableMessage = 'Non presente'

    const [uuidToGoTo, setUuidToGoTo] = useState<string>('')

    const {available_state_transitions} = negotiation

    const resetErrors = useCallback(() => {
        const prevErrors = form.getFieldsError().filter((el: any) => el.errors.length)
        prevErrors?.forEach((el: any) => {
            form.setFields([
                {
                    name: el.name,
                    errors: []
                },
            ])
        })
    }, [])

    useEffect(() => { // set the focus on fields that have to be fixed to complete a state transition
        resetErrors()
        formFieldErrors?.forEach(el => {
            form.setFields([
                {
                    name: el.field,
                    errors: [el.error]
                },
            ])
        })
    }, [formFieldErrors, resetErrors])

    const resetWarnings = useCallback(() => {
        const prevErrors = form.getFieldsError().filter((el: any) => el.warnings.length)
        prevErrors?.forEach((el: any) => {
            form.setFields([
                {
                    name: el.name,
                    warnings: []
                },
            ])
        })
    }, [])

    useEffect(() => {
        resetWarnings()
        formFieldWarnings?.forEach(el => {
            form.setFields([
                {
                    name: el.field,
                    warnings: [el.warning]
                },
            ])
        })
    }, [formFieldWarnings, resetWarnings])

    useEffect(() => {
        if (negotiation.state === States.Rore && negotiation.raw_data) {
            let roreSuggestions: { field: string, warning: string }[] | undefined = []
            for (const [key, value] of Object.entries(negotiation.raw_data)) {
                roreSuggestions.push(({field: key, warning: `Dati trovati da RORE: ${value.join(', ')}`}))
            }
            setFormFieldWarnings(roreSuggestions)
        }
    }, [negotiation.raw_data, negotiation.state])

    async function handleCreateStateTransition(data: { state_to: number, reason?: string, notes?: string }, newNegotiation?: NegotiationModel) {

        try {
            const createTransitionResult = await createStateTransition({id: negotiation.uuid, data: data}).unwrap()
            setUuidToGoTo(createTransitionResult.new_negotiation_uuid)
            setNegotiation({
                ...(newNegotiation || negotiation),
                state: createTransitionResult.state_to,
                available_state_transitions: createTransitionResult.available_states,
                revert_state_transition: createTransitionResult.revert_state_transition
            })
            resetErrors()
            resetWarnings()
            message.success('Stato aggiornato')
        } catch (e: any) {
            message.error('Impossibile aggiornare lo stato della trattativa')
            console.error('createStateTransition', e)
            if (e.data?.length) {
                setIsErrorStateTransitionModalOpen(true)
                setFormFieldErrors(e.data)
            }

        }
        try {
            const negotiationStatusHistory = await triggerGetNegotiationStatusHistory(negotiationId).unwrap()
            setNegotiationStatusHistory(negotiationStatusHistory)
        } catch (e: any) {
            console.error('negotiationStatusHistory', e)
        }
    }


    async function updateNegotiationUnderwriter(underwriterId: string): Promise<NegotiationModel | undefined> {

        try {
            const updateResult = await updateNegotiation({underwriter: underwriterId})
            return updateResult
        } catch (e: any) {
            message.error('Impossibile assegnare l\'underwriter')
            console.error('updateUnderwriter')
        }
    }


    const availableContainsReferred = available_state_transitions.find(state => state.state === States.Assegnata)

    const viewProps: NegotiationStatusViewBaseProps = {
        negotiation: negotiation,
        options: options,
        fieldNotAvailableMessage: fieldNotAvailableMessage,
        openRestoreState: () => setIsRestoreStateModalOpen(true),
        openHistory: () => setIsHistoryModalOpen(true),
        openMoveNegotiation: () => setIsMoveNegotiationToStatusModalOpen(true),
        openEditNegotiation: () => setIsEditNegotiationStatusModalOpen(true),
        openEditReferred: () => setIsReferred(true),
        createStateTransition: handleCreateStateTransition,
        openAssignNegotiation: () => setIsAssignNegotiationModalOpen(true),
        openChecklist: () => setIsChecklistOpen(true),
        updateNegotiationUw: updateNegotiationUnderwriter,
        uuidToGoTo: uuidToGoTo
    }
    return (
        <>
            <RestoreNegotiationStatus handleCreateStateTransition={handleCreateStateTransition}
                                      isCreateStateTransitionLoading={isCreateStateTransitionLoading}
                                      currentState={negotiation.state}
                                      revertStates={negotiation.revert_state_transition}
                                      isOpen={isRestoreStateModalOpen}
                                      onClose={() => setIsRestoreStateModalOpen(false)}/>
            <HistoryModal negotiationStatusHistory={negotiationStatusHistory} isOpen={isHistoryModalOpen}
                          onClose={() => setIsHistoryModalOpen(false)}/>
            {available_state_transitions.length === 1 &&
                <MoveNegotiationToStatus handleCreateStateTransition={handleCreateStateTransition}
                                         isCreateStateTransitionLoading={isCreateStateTransitionLoading}
                                         state={negotiation.available_state_transitions[0].state}
                                         isOpen={isMoveNegotiationToStatusModalOpen}
                                         onClose={() => setIsMoveNegotiationToStatusModalOpen(false)}/>}

            {/*show all states apart from referred (which has a dedicated button)*/}
            {available_state_transitions.length > 1 &&
                <EditNegotiationStatusModal handleCreateStateTransition={handleCreateStateTransition}
                                            isCreateStateTransitionLoading={isCreateStateTransitionLoading}
                                            availableStates={negotiation.available_state_transitions?.filter(state => state.state !== States.Referred)}
                                            isOpen={isEditNegotiationStatusModalOpen}
                                            onClose={() => setIsEditNegotiationStatusModalOpen(false)}
                                            openChecklist={() => setIsChecklistOpen(true)}
                                            isAutoRenewal={negotiation.is_auto_renewal}
                                            currentState={negotiation.state}/>}

            {/*show referred*/}
            {negotiation.state === States.Working && !!negotiation.underwriter &&
                <EditReferredModal negotiationId={negotiationId} isOpen={isReferred}
                                   onClose={() => setIsReferred(false)}
                                   handleCreateStateTransition={handleCreateStateTransition}
                                   isCreateStateTransitionLoading={isCreateStateTransitionLoading}/>}

            {!isSimplifiedView && !!availableContainsReferred &&
                <AssignNegotiationModal updateNegotiationUnderwriter={updateNegotiationUnderwriter}
                                        handleCreateStateTransition={handleCreateStateTransition}
                                        negotiation={negotiation} isOpen={isAssignNegotiationModalOpen}
                                        onClose={() => setIsAssignNegotiationModalOpen(false)}/>}
            {!isSimplifiedView &&
                <ChecklistFormDialog isOpen={isChecklistOpen} onClose={() => setIsChecklistOpen(false)}
                                     negotiation={negotiation} updateNegotiation={updateNegotiation}/>}
            {isSimplifiedView ? <SimplifiedNegotiationStatusView {...viewProps} />
                : <CompleteNegotiationStatusView {...viewProps} updateNegotiation={updateNegotiation}/>}
            <Modal open={isErrorStateTransitionModalOpen}
                   title={<p style={{color: "red"}}>Impossibile aggiornare lo stato della trattativa</p>} footer={null}
                   onCancel={() => setIsErrorStateTransitionModalOpen(false)}><p style={{paddingBottom: "100px"}}>Per
                finalizzare il passaggio di stato risolvere i seguenti errori:</p>
                <ul> {formFieldErrors?.map((el, index) => <li key={index} style={{
                    marginTop: "0.75em",
                    marginLeft: "0.85em"
                }}>{el.error}</li>)} </ul>
                <p>I campi interessati dall'errore sono evidenziati in rosso nel form e l'errore verrà rimosso una volta
                    effettuato il passaggio di stato</p></Modal>
        </>
    )
}


const {Option} = Select


export type NegotiationDetailStatusPropsType = {

    negotiation: NegotiationModel,
    setNegotiation: (negotiation: NegotiationModel) => void
    options: OptionsModel,
    updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>
    isUpdateNegotiationLoading: boolean
}

export type NegotiationStatusViewBaseProps = {
    negotiation: NegotiationModel,
    options: OptionsModel,
    fieldNotAvailableMessage: string,
    openRestoreState: () => void,
    openHistory: () => void,
    openMoveNegotiation: () => void,
    openEditNegotiation: () => void,
    openEditReferred: () => void,
    createStateTransition: (data: { state_to: number, reason?: string, notes?: string }) => void
    openAssignNegotiation: () => void,
    openChecklist: () => void,
    updateNegotiationUw: (uwId: string) => Promise<NegotiationModel | undefined>
    uuidToGoTo: string
}

export function CompleteNegotiationStatusView({
                                                  negotiation,
                                                  options,
                                                  fieldNotAvailableMessage,
                                                  openRestoreState,
                                                  openHistory,
                                                  openAssignNegotiation,
                                                  openEditReferred,
                                                  openMoveNegotiation,
                                                  openEditNegotiation,
                                                  openChecklist,
                                                  updateNegotiationUw,
                                                  uuidToGoTo,
                                                  updateNegotiation
                                              }: NegotiationStatusViewBaseProps & { updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined> }
) {

    const [isEditUwOpen, setIsEditUwOpen] = useState<boolean>(false)
    const [isEditLobOpen, setIsEditLobOpen] = useState<boolean>(false)
    const {available_state_transitions} = negotiation
    const {states} = options
    const availableContainsReferred = available_state_transitions.find(state => state.state === States.Assegnata)
    const user = useSelector(selectUser)
    const [isDeleteNegotiationModalOpen, setIsDeleteNegotiationModalOpen] = useState<boolean>(false)
    const [archiveNegotiation, {isLoading, error, data}] = useArchiveNegotiationMutation();

    const [uuid, setUuid] = useState<string>('')

    useEffect(() => {
        if (uuidToGoTo)
            setUuid(uuidToGoTo)
    }, [uuidToGoTo])

    const navigate = useNavigate()


    return (
        <Card bordered={false} style={{
            position: 'sticky',
            // width: '100%',
            // right: '5%',
            top: '24px',
            // padding: '24px',
            borderRadius: '12px'
        }}>
            {user && user.usertypes.length && canModifyUnderwriter(user.usertypes.map(el => el.code)) &&
                <EditUnderwriterModal isOpen={isEditUwOpen} onClose={() => setIsEditUwOpen(false)}
                                      negotiation={negotiation} updateNegotiationUw={updateNegotiationUw}/>}
            {negotiation.state === States.Rore &&
                <EditLobModal isOpen={isEditLobOpen} onClose={() => setIsEditLobOpen(false)} negotiation={negotiation}
                              updateNegotiation={updateNegotiation}/>}
            <Row justify="space-between" gutter={[8, 12]}>
                <Col>
                    <Space>
                        <p>Stato Trattativa</p>
                        <Button icon={<HistoryOutlined/>} disabled={!negotiation.revert_state_transition.length}
                                onClick={openRestoreState} type={'text'}/>
                    </Space>
                </Col>
                <div className='btn-text' onClick={openHistory}>
                    <p style={{color: 'inherit !important'}}>Storico</p>
                    <RightOutlined style={{marginLeft: '4px'}}/>
                </div>
                <Col span={24} style={{display: "flex", textAlign: 'end'}}>
                    <StatusIndicator state={negotiation.state} options={options}/>
                    <StatusHelpModal/>
                </Col>
                <Col>
                    {uuid ? <a onClick={() => {
                        navigate(`/negotiations/${uuid}`);
                        setUuid('')
                    }}>vai alla nuova trattativa</a> : null}
                </Col>
                {!!availableContainsReferred ? (
                        <Col span={24}>
                            <Button block type='primary' icon={<UserAddOutlined/>} onClick={openAssignNegotiation}> Assegna
                                trattativa ad underwriter</Button>
                        </Col>
                    )
                    : available_state_transitions.length === 1 ? (
                            <Col span={24}>
                                <Button block type='primary' icon={<EditOutlined/>}
                                        onClick={openMoveNegotiation}> {`Passa trattativa a ${states.find(state => state.value === available_state_transitions[0].state)?.text}`} </Button>
                            </Col>
                        )
                        : !!available_state_transitions.length && (
                        <Col span={24}>
                            <Button block type='primary' icon={<FormOutlined/>}
                                    onClick={openEditNegotiation}> {'Modifica stato trattativa'} </Button>
                        </Col>
                    )}
                {negotiation.state === States.Working && user && user.usertypes.length && canSeeReferralButton(user.usertypes.map(el => el.code)) && !!negotiation.underwriter &&
                    <Col span={24}>
                      <Button
                          block
                          onClick={openEditReferred}
                          icon={<CheckCircleOutlined/>}>
                        Richiedi referral
                      </Button>
                    </Col>}
            </Row>
            <Divider/>
            <Space direction='vertical' size='small' align='start' style={{width: '100%'}}>
                <Row>
                    <Col>
                        {negotiation.is_imported && <Paragraph>Trattativa importata da Excel</Paragraph>}
                    </Col>
                </Row>
                <Space direction='horizontal' size='middle'>
                    <Paragraph>Contraente</Paragraph>
                    <Title underline={negotiation?.contractor ? false : true}
                           level={5}>{negotiation?.contractor?.name || fieldNotAvailableMessage}</Title>
                </Space>
                <Row align='middle' justify='space-between' style={{width: '100%'}}>
                    <Col>
                        <Space direction='horizontal' size='middle' align='center'>
                            <Paragraph>LOB</Paragraph>
                            <Title underline={negotiation?.lob ? false : true}
                                   level={5}>{negotiation?.lob.name || fieldNotAvailableMessage}</Title>
                        </Space>
                    </Col>
                    {negotiation.state === States.Rore && <Col>
                      <Button type='link' size='middle' icon={<EditOutlined/>}
                              onClick={() => setIsEditLobOpen(true)}/>
                    </Col>}
                </Row>
                {negotiation.state === States.Rore && !!negotiation.raw_data?.lob?.length &&
                    <p className={'ant-form-item-explain-warning'}>{`Dati trovati da RORE: ${negotiation.raw_data.lob.join(', ')}`}</p>}
                <Row align='middle' justify='space-between' style={{width: '100%'}}>
                    <Col>
                        <Space direction='horizontal' size='middle' align='center' style={{width: '100%'}}>
                            <Paragraph>Underwriter</Paragraph>
                            <Title underline={negotiation?.underwriter ? false : true}
                                   level={5}>{negotiation?.underwriter?.user_full_name || fieldNotAvailableMessage}</Title>
                        </Space>
                    </Col>
                    {user && user.usertypes.length && canModifyUnderwriter(user.usertypes.map(el => el.code)) && negotiation.state >= States.Assegnata &&
                        <Col>
                          <Button type='link' size='middle' icon={<EditOutlined/>}
                                  onClick={() => setIsEditUwOpen(true)}/>
                        </Col>}
                </Row>
                {negotiation.state === States.Rore && !!negotiation.raw_data?.underwriter?.length &&
                    <p className={'ant-form-item-explain-warning'}>{`Dati trovati da RORE: ${negotiation.raw_data.underwriter.join(', ')}`}</p>}
                <Space direction='horizontal' size='middle' align='center'>
                    <Paragraph>Data di ricezione</Paragraph>
                    <Title underline={negotiation?.reception_date ? false : true}
                           level={5}>{negotiation.reception_date ? dayjs(negotiation.reception_date).format('DD/MM/YYYY') : fieldNotAvailableMessage}</Title>
                </Space>
                <Space direction='horizontal' size='middle' align='center'>
                    <Paragraph>Data di decorrenza</Paragraph>
                    <Title underline={negotiation?.policy_effective_date ? false : true}
                           level={5}>{negotiation.policy_effective_date ? dayjs(negotiation.policy_effective_date).format('DD/MM/YYYY') : fieldNotAvailableMessage}</Title>
                </Space>
                <Space direction='horizontal' size='middle' align='center'>
                    <Paragraph>Data di scadenza</Paragraph>
                    <Title underline={negotiation?.policy_expiring_date ? false : true}
                           level={5}>{negotiation.policy_expiring_date ? dayjs(negotiation.policy_expiring_date).format('DD/MM/YYYY') : fieldNotAvailableMessage}</Title>
                </Space>
                <Space direction='horizontal' size='large' align='center'>
                    <Paragraph>UW Year</Paragraph>
                    <Title underline={negotiation?.uw_year ? false : true}
                           level={5}>{negotiation?.uw_year || fieldNotAvailableMessage}</Title>
                </Space>
                <Space direction='horizontal' size='middle' align='center'>
                    <Paragraph>Broker</Paragraph>
                    <Title underline={negotiation?.broker?.name ? false : true}
                           level={5}>{negotiation?.broker?.name || fieldNotAvailableMessage}</Title>
                </Space>
                <Space direction='horizontal' size='middle' align='center'>
                    <Paragraph>ISIA</Paragraph>
                    <Title underline={negotiation?.isia_sale ? false : true}
                           level={5}>{negotiation?.isia_sale?.user_full_name || fieldNotAvailableMessage}</Title>
                </Space>
            </Space>

            {user && user.usertypes.length && canSeeCheckList(user.usertypes.map(el => el.code)) &&
                <Button block style={{marginTop: '24px'}} type='primary' icon={<UnorderedListOutlined/>}
                        onClick={() => {
                            openChecklist()
                            //getChecklist()
                        }}>Apri checklist</Button>}
            {user && user.usertypes.length && canCreateNewNegotiation(user.usertypes.map(el => el.code)) &&
                <Button block style={{marginTop: '12px'}} danger icon={<DeleteOutlined/>}
                        onClick={() => setIsDeleteNegotiationModalOpen(!isDeleteNegotiationModalOpen)}>Elimina
                  trattativa</Button>}
            <ConfirmCancelModal
                title={<p style={{color: "red"}}>Eliminazione trattativa</p>}
                open={isDeleteNegotiationModalOpen}
                okText={'Elimina'}
                okButtonProps={{danger: true}}
                onCancel={() => setIsDeleteNegotiationModalOpen(!isDeleteNegotiationModalOpen)}
                onOk={() => {
                    archiveNegotiation({uuid: negotiation.uuid}).unwrap()
                        .then((payload) => {
                            message.success('Trattativa eliminata con successo.');
                            navigate('negotiations')
                        })
                        .catch(error => {
                            message.error('l\'eliminazione della trattativa non è andata a buon fine.')
                        })

                }}
            >L'eliminazione di una trattativa comporta la perdita di tutti i suoi dati. Vuoi davvero eliminare la
                trattativa corrente?</ConfirmCancelModal>
        </Card>
    )
}




