import ChartContainer from "./ChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import CommonChart from "../HighCharts/CommonChart";
import {euro, numeric} from "../../../utils/utils";
import HighchartsReact from "highcharts-react-official";
import {useSelector} from "react-redux";
import {useLazyGetNegotiationsResultsChartQuery} from "../../../redux/api/chartsApiSlice";
import {selectOptions} from "../../../redux/features/optionsSlice";
import {selectUser} from "../../../redux/features/userSlice";

const NegotiationsResultsChart = (props: { className: string, chartRef: React.RefObject<HighchartsReact.RefObject> }) => {

    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetNegotiationsResultsChartQuery()
    const states = useSelector(selectOptions).states
    const [categories, setCategories] = useState<string[]>([])
    const description = 'Il grafico mostra il numero di trattative e la sommatoria dei premi che si trovano in stato Bound, Declined, Not Taken Up.'

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (states && data) {
            const selectedStates = data.map(el => {
                const state = states.find(state => state.value === el.state)
                return state ? state.text : ""
            })
            setCategories(selectedStates)
        }
    }, [data, states])


    const title = 'Risultato trattative'
    return <ChartContainer
        description={description}
        loading={isLoading || isFetching}
        className={props.className}
        title={title}
        buttons={<DownloadButton chartRef={props.chartRef}/>}>
        <CommonChart
            chartRef={props.chartRef}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.y} trattative - {point.value:,.2f}€ premi'
                                }
                            }
                        }
                    },
                },
                xAxis: {
                    categories: categories,
                    lineWidth: 0
                },
                yAxis: {
                    title: {
                        text: 'Numero trattative'
                    },
                    visible: false
                },
                legend: {
                    enabled: false
                },
                chart: {
                    type: 'bar',
                    height: 150
                },
                series: [{
                    name: 'Ricezione submission',
                    type: 'bar',
                    color: '#F2EFFF',
                    data: data ? data.map(el => ({
                        value: el.premium,
                        y: el.premium,
                        negotiations: el.negotiations
                    })) : [],
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            // @ts-ignore
                            const value = this.point?.negotiations ? numeric(this.point.negotiations) : ""
                            return value
                        }
                    },
                    states: {
                        hover: {
                            color: '#5932EA',
                        },
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormatter: function () {
                            const value = euro(this.y ? this.y : 0)
                            // add negotiation field
                            // @ts-ignore
                            return `<span style='font-weight: bolder'>${this.category}</span><br/>Premi: <span style='font-weight: bolder'>${value}</span> <br/> Trattative: <span style='font-weight: bolder'>${this.negotiations}</span>`
                        }
                    }
                }],
            }}
        />
    </ChartContainer>
}

export default NegotiationsResultsChart