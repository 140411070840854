import ChartContainer from "./ChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import SelectMonth from "../../select/SelectMonth";
import HighchartsReact from "highcharts-react-official";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import {SeriesOptionsType} from "highcharts";
import Histogram from "../HighCharts/Histogram";
import dayjs from "dayjs";
import {capitalizeFirstLetter, euro, numeric, percentage} from "../../../utils/utils";
import {
    useLazyGetNegotiationsPremiumsChartQuery,
} from "../../../redux/api/chartsApiSlice";
import {selectUser} from "../../../redux/features/userSlice";

export default function NegotiationsPremiumsChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {

    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetNegotiationsPremiumsChartQuery()
    const [date, setDate] = useState(dayjs(new Date()))
    const options = useSelector(selectOptions)
    const [series, setSeries] = useState<SeriesOptionsType[]>([])
    const description = 'Il grafico mostra la sommatoria dei premi delle trattative che si trovano in ogni stato al mese di riferimento.'


    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                year: date.year(),
                month: date.month(),
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, date, getData, isia, uwYear])

    useEffect(() => {
        if (data)
            setSeries(data ? data.map(el => {
                const state = options.states.find(state => state.value === el.state)
                return {
                    type: 'column', name: state?.text, data: [el.amount], color: state?.color, dataLabels: {
                        enabled: true,
                        formatter: function () {
                            const value = this.y ? euro(this.y) : ""
                            return value
                        }
                    }
                }
            }) : [])
    }, [data, options])

    return <ChartContainer
        className={props.className}
        description={description}
        title={'Premi trattative'}
        loading={isLoading || isFetching}
        buttons={[
            <label htmlFor={'negotiationPremiumsMonthSelect'} key={'negotiationPremiumsMonthSelect'}
                   style={{fontWeight: '400'}}>Trattative al:</label>,
            <SelectMonth month={date} setMonth={setDate} key={'month-select'}
                         selectProps={{name: "negotiationPremiumsMonthSelect", allowClear: false}}/>,
            // <SelectUW setUw={setUw} key={'uw-select'} uw={uw}/>,
            <DownloadButton key={'download-button'} chartRef={props.chartRef}/>]}>
        <Histogram
            chartRef={props.chartRef}
            options={{
                series: series,
                yAxis: {
                    labels: {
                        format: '{text} €'
                    },
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    formatter: function () {
                        const value = euro(this.y ? this.y : 0)
                        const symbol = this.color ? `<span style='color: ${this.color}'>●</span>` : ''
                        return `${symbol} <span>${this.series.name}</span>: <span style='font-weight: bolder'>${value}</span>`
                    }
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: 'Premi trattative'
                        },
                        subtitle: {
                            // text: `Parametri: ${capitalizeFirstLetter(month.format('MMMM'))} ${props.year.year()} - ${uw.label}`
                            text: `Parametri: ${capitalizeFirstLetter(date.format('MMMM YYYY'))} - UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`

                        }
                    }
                }
            }}
        />
    </ChartContainer>
}