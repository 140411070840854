import {useNavigate, useParams} from "react-router-dom";
import {RefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {Col, Row, Card, Tag, Form, Input, Radio, Button, Typography, message} from 'antd';

import {
    LeftOutlined,
    PlusOutlined,
    MailOutlined,
    FileOutlined,
    CheckCircleOutlined,
    FolderOpenOutlined,
    SolutionOutlined
} from '@ant-design/icons';

import debounce from 'lodash.debounce'
import _, {cloneDeep} from 'lodash'

import {useTranslation} from 'react-i18next';

import {NegotiationDetailMenu} from "components/negotiationForm/sidebars/menu";
import {NegotiationDetailStatusManager} from "components/negotiationForm/sidebars/status";
import {PortfolioNegotiationTakenOverBy} from "components/negotiationForm/sidebars/status/portfolio";

import Submission from "components/negotiationForm/form/submission";
import Contractor from "components/negotiationForm/form/contractor";
import Reinsurance from "components/negotiationForm/form/reinsurance";
import Negotiation from "components/negotiationForm/form/negotiation";
import Portfolio from "components/negotiationForm/form/portfolio";
import PoliciesAndIssues from "components/negotiationForm/form/policiesAndIssues";
import PortfolioPoliciesAndIssues from "components/negotiationForm/form/portfolioView/policiesAndIssues";
import { NegotiationDetailSubForm} from "types/negotiations/components";
import {
    CalculatedField,
    FieldsCalculated,
    NegotiationFormModel, NegotiationTransitionHistory,
    UpdateNegotiationRequest
} from "types/negotiations/index";
import {States} from "../../types";
import {NegotiationModel} from "types/negotiations";
import {RiskAppSpinner} from "components/spinners";
import {useSelector} from "react-redux";
import {Alert} from "antd";

import dayjs from "dayjs";

import {AnimatePresence, motion} from 'framer-motion'
import MakeTransition from "components/animator";

import {selectUser} from "redux/features/userSlice";
import {selectOptions} from "redux/features/optionsSlice";
import {
    useLazyGetNegotiationDetailQuery,
    useLazyGetNegotiationStatusTransitionsQuery,
    useUpdateNegotiationMutation
} from "redux/api/negotiationsApiSlice";
import {transformNegotiationFormToUpdateRequest, transformNegotiationToFormFields} from "utils/form/dataParser";
import {FormChangeInfo} from "rc-field-form/es/FormContext";
import {
    calculateNegotiationFormSideEffect,
    calculatePoliciesAndIssuesSideEffects,
    calculatePortfolioFormSideEffect,
    calculateReinsuranceFormSideEffects,
    calculateSubmissionFormSideEffects
} from "utils/form/handleFormSideEffects";
import {canModifyForm} from "../../utils/permission";
import {isNegotiationExpired} from "../../utils/utils";

const {Title, Paragraph} = Typography


export default function NegotiationDetail() {
    const {id: negotiationId} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate()

    const options = useSelector(selectOptions)
    const user = useSelector(selectUser)

    const [isPortfolio, setIsPortfolio] = useState<boolean>(false)
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true)
    const [selectedNegotiation, setSelectedNegotiation] = useState<NegotiationModel | undefined>(undefined)
    const [triggerGetNegotiation, {
        isLoading: isGetNegotiationloading,
        isFetching,
        isUninitialized,
        isError: isGetNegotiationError
    }] = useLazyGetNegotiationDetailQuery()
    const [triggerUpdateNegotiation, {isLoading: isUpdateNegotiationLoading}] = useUpdateNegotiationMutation()

    const [selectedNegotiationStatusHistory, setSelectedNegotiationStatusHistory] = useState<NegotiationTransitionHistory[]>([])
    const [triggerGetNegotiationStatusHistory, {
        isLoading: isNegotiationStatusHistoryLoading,
        isError: hasNegotiationStatusHistoryFailed,
        isSuccess: hassNegotiationStatusHistorySucceeded
    }] = useLazyGetNegotiationStatusTransitionsQuery()

    const [form] = Form.useForm<NegotiationFormModel>();

    useEffect(() => {
        if (user && user.usertypes.length) {
            setIsPortfolio(!!user.usertypes.find(type => type.code === 'PO'))
            setIsFormDisabled(!canModifyForm(user.usertypes.map(el => el.code)))
        }
    }, [user])

    const fetchNegotiation = useCallback(async (id: string) => {
        try {
            const negotiation = await triggerGetNegotiation(id).unwrap()
            setSelectedNegotiation({
                ...negotiation,
                available_state_transitions: negotiation.available_state_transitions.flat()
            })
        } catch (e: any) {
            console.error(`negotiation ${id} fetch`, e)
        }
        try {
            const negotiationStatusHistory: NegotiationTransitionHistory[] = await triggerGetNegotiationStatusHistory(id).unwrap()
            setSelectedNegotiationStatusHistory(negotiationStatusHistory)
        } catch (e: any) {
            console.error('negotiationStatusHistory', e)
        }
    }, [form, triggerGetNegotiation, triggerGetNegotiationStatusHistory])


    //fetch the request (from query param) negotiation and display it
    useEffect(() => {
        if (negotiationId)
            fetchNegotiation(negotiationId)
    }, [fetchNegotiation, negotiationId])

    const saveData = useCallback(async (data: Partial<UpdateNegotiationRequest>): Promise<NegotiationModel | undefined> => {
        //saveData
        try {
            if (negotiationId) {
                const updateResult = await triggerUpdateNegotiation({id: negotiationId, data}).unwrap()
                setSelectedNegotiation({
                    ...updateResult,
                    available_state_transitions: updateResult.available_state_transitions.flat()
                })
                message.success('Dati aggiornati')
                return updateResult
            } else
                message.error('Trattativa non impostata')
            return undefined
        } catch (e: any) {
            //in case of update error, rollback to previous negotitation save
            if (selectedNegotiation) {
                const oldFormNegotiation = transformNegotiationToFormFields(selectedNegotiation)
                form.setFieldsValue(oldFormNegotiation)
            }
            console.error('debounce', e)
            message.error('Impossibile aggiornare i dati')
            return undefined
        }
    }, [form, negotiationId, selectedNegotiation, triggerUpdateNegotiation])


    const debounceInputChange = useMemo(
        () => debounce((data: Partial<UpdateNegotiationRequest>) => saveData(data), 1500)
        , [saveData]);

    //both menu and each form are dynamic and render via this state
    const [formItems, setFormItems] = useState<NegotiationDetailSubForm[]>([])

    //Scrollspy + scroll on menu item click
    const submissionRef = useRef<HTMLDivElement>(null)
    const contractorRef = useRef<HTMLDivElement>(null)
    const reinsuranceRef = useRef<HTMLDivElement>(null)
    const negotiationRef = useRef<HTMLDivElement>(null)
    const portfolioRef = useRef<HTMLDivElement>(null)
    const policiesAndIssuesRef = useRef<HTMLDivElement>(null)

    const getCalculatedField = useCallback((field: FieldsCalculated) => {
        if (form.getFieldValue('is_calculated')) {
            return (form.getFieldValue('is_calculated') as CalculatedField)[field]
        } else {
            return true
        }
    }, [form])


    //From.Provider => not really used
    const onFormChange = (formName: string, info: FormChangeInfo) => {
    }

    //triggered every time a controlled input changes, here is handled the relation between inputs
    const onValuesChange = useCallback((changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) => {
        if (selectedNegotiation &&  ! _.isMatch(transformNegotiationToFormFields(selectedNegotiation), changedValues)) {
            let updatedValues = cloneDeep(values)
            const lob = options.lobs.find(lob => lob.uuid === values.lob)

            updatedValues = calculateSubmissionFormSideEffects(changedValues, updatedValues)
            updatedValues = calculateReinsuranceFormSideEffects(changedValues, updatedValues)
            updatedValues = calculateNegotiationFormSideEffect(changedValues, updatedValues, lob, options.firstDelegated)
            updatedValues = calculatePortfolioFormSideEffect(selectedNegotiation, changedValues, updatedValues)
            updatedValues = calculatePoliciesAndIssuesSideEffects(changedValues, updatedValues)

            form.setFieldsValue(updatedValues)
            const hasErrors = form.getFieldsError().filter(({errors}) => errors.length);

            const parsedFormValues = transformNegotiationFormToUpdateRequest(updatedValues)

            // for avoiding debounce on enabling / disabling  calculation buttons
            if ('is_calculated' in changedValues) {
                if (changedValues.is_calculated) {
                    saveData(parsedFormValues)
                }
            } else {
                debounceInputChange(parsedFormValues)
            }
        }

    }, [debounceInputChange, form, options.firstDelegated, options.lobs, saveData, selectedNegotiation])

    const setCalculateField = useCallback((field: FieldsCalculated) => {
        if (form.getFieldValue('is_calculated')) {
            const updatedCalculateFields: CalculatedField = {...form.getFieldValue('is_calculated')}
            updatedCalculateFields[field] = !updatedCalculateFields[field]

            onValuesChange({is_calculated: updatedCalculateFields}, form.getFieldsValue())
        }
    }, [form, onValuesChange])


    useEffect(() => {
        if (selectedNegotiation) {

            const baseProps = {
                formInstance: form,
                debounceInputChange,
                negotiation: selectedNegotiation,
                disabled: isFormDisabled,
                getCalculatedField,
                setCalculateField,
                onFormValuesChange: onValuesChange
            }
            const baseItems = [
                {
                    uwOrder: 0,
                    portfolioOrder: 5,
                    label: 'Submission',
                    key: 'submission',
                    ref: submissionRef,
                    icon: <MailOutlined/>,
                    component: <Submission {...baseProps} forwaredRef={submissionRef}
                    />
                }, // remember to pass the key prop
                {
                    uwOrder: 1,
                    portfolioOrder: 3,
                    label: 'Dati contraente',
                    key: 'contractor',
                    ref: contractorRef,
                    icon: <FileOutlined/>,
                    component: <Contractor {...baseProps} forwaredRef={contractorRef}/>
                }, // which is required
                {
                    uwOrder: 2,
                    portfolioOrder: 4,
                    label: 'Riassicurazione',
                    key: 'reinsurance',
                    ref: reinsuranceRef,
                    icon: <CheckCircleOutlined/>,
                    component: <Reinsurance {...baseProps} forwaredRef={reinsuranceRef}/>
                },
                {
                    uwOrder: 3,
                    portfolioOrder: 6,
                    label: 'Dati trattativa',
                    key: 'negotiation',
                    ref: negotiationRef,
                    icon: <MailOutlined/>,
                    component: <Negotiation {...baseProps} forwaredRef={negotiationRef}/>
                },
                {
                    uwOrder: 4,
                    portfolioOrder: 2,
                    label: 'Portafoglio',
                    key: 'portfolio',
                    ref: portfolioRef,
                    icon: <FolderOpenOutlined/>,
                    component: <Portfolio {...baseProps} forwaredRef={portfolioRef}/>
                },
                {
                    uwOrder: 5,
                    portfolioOrder: 1,
                    label: 'Polizze ed emissioni',
                    key: 'policiesAndSubmissions',
                    ref: policiesAndIssuesRef,
                    icon: <SolutionOutlined/>,
                    component: isPortfolio && [States.Bound, States.Draft, States.Issued, States.InRinnovo, States.DaRinnovare, States.NonRinnovata].includes(selectedNegotiation.state) ?
                        <PortfolioPoliciesAndIssues {...baseProps} forwaredRef={policiesAndIssuesRef}/>
                        : <PoliciesAndIssues {...baseProps} forwaredRef={policiesAndIssuesRef}/>
                },
            ]
            baseItems.sort((a, b) => {
                if (isPortfolio)
                    return a.portfolioOrder - b.portfolioOrder
                else
                    return a.uwOrder - b.uwOrder

            })

            setFormItems(baseItems)
        }
    }, [debounceInputChange, form, getCalculatedField, isFormDisabled, isPortfolio, negotiationId, onValuesChange, selectedNegotiation, selectedNegotiationStatusHistory, setCalculateField])

    useEffect(() => {
        if (selectedNegotiation) {
            const formNegotiation = transformNegotiationToFormFields(selectedNegotiation)
            form.setFieldsValue(formNegotiation)
        }
    }, [form, selectedNegotiation])


    if (negotiationId && (isGetNegotiationloading || isUninitialized || !formItems.length)) {
        return <RiskAppSpinner/>
    }

    if ((!!negotiationId && isGetNegotiationError) || !negotiationId) {
        return (
            <MakeTransition forwardedKey={'form-error'}>
                <div style={{
                    'height': '80vh',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Card>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Title level={3}>Trattativa non trovata</Title>
                                <Paragraph>La trattativa potrebbe essere stata rimossa o potresti essere finito qui per
                                    errore.</Paragraph>
                            </Col>
                            <Col span={24}>
                                <Row justify='space-between'>
                                    <Col>
                                        <Button icon={<LeftOutlined/>} type='primary'
                                                onClick={() => navigate('negotiations')}>Torna indietro</Button>
                                    </Col>
                                    <Col>
                                        <Button icon={<PlusOutlined/>} type='primary'
                                                onClick={() => navigate('negotiations/new')}>Crea nuova
                                            trattativa</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>


                    </Card>
                </div>
            </MakeTransition>
        )
    }

    //if portfolio try to access a negotiation not in bound,draft or issued
    // if (selectedNegotiation && isPortfolio && !([6, 8, 9].includes(selectedNegotiation.state)))
    //     return (
    //         <MakeTransition forwardedKey={'form-error'}>
    //             <div style={{ 'height': '80vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //                 <Card>
    //                     <Row gutter={[16, 16]}>
    //                         <Col span={24}>
    //                             <Title level={3}>Non autorizzato</Title>
    //                             <Paragraph>Non è stato possibile mostrare la trattativa poichè il suo stato non ti consente di visualizzarla (a trattativa non è ancora draft/issued)</Paragraph>
    //                         </Col>
    //                         <Col span={24}>
    //                             <Row justify='space-between'>
    //                                 <Col>
    //                                     <Button icon={<LeftOutlined />} type='primary' onClick={() => navigate('negotiations')}>Torna indietro</Button>
    //                                 </Col>
    //                             </Row>
    //                         </Col>
    //                     </Row>


    //                 </Card>
    //             </div>
    //         </MakeTransition>
    //     )
    return (
        <div className="negotiation-form__container">
            <NegotiationDetailMenu menuItems={formItems}/>
            <main style={{marginTop: '24px', marginBottom: '24px'}}>
                {selectedNegotiation && <Row gutter={24} justify='space-evenly' style={{margin: 0}}>
                    {/* <Col style={{ width: isPortfolio ? '90%' : '50%', padding: 0 }}> */}
                  <Col style={{width: '60%', padding: 0}}>
                    <motion.div
                        initial={{opacity: 0, x: 0, y: 200}}
                        animate={{opacity: 1, x: 0, y: 0}}
                        exit={{opacity: 0, x: 0, y: -100}}
                        transition={{type: 'linear'}} // Set the transition to linear
                    >
                      <Form.Provider
                          onFormChange={onFormChange}
                      >
                        <Row gutter={[0, 24]}>
                            {/* {isPortfolio &&
                                        <Col span={24}>
                                            <Row align='stretch' justify='space-between'>
                                                <Col style={{ width: '49%' }}>
                                                    <PortfolioNegotiationTakenOverBy negotiation={selectedNegotiation} setNegotiation={(negotiation: NegotiationModel) => setSelectedNegotiation(negotiation)} options={options} updateNegotiation={debounceInputChange} isUpdateNegotiationLoading={isUpdateNegotiationLoading} />
                                                </Col>
                                                <Col style={{ width: '49%' }}>
                                                    <NegotiationDetailStatusManager isSimplifiedView={true} negotiation={selectedNegotiation} setNegotiation={(negotiation: NegotiationModel) => setSelectedNegotiation(negotiation)} options={options} updateNegotiation={saveData} isUpdateNegotiationLoading={isUpdateNegotiationLoading} negotiationStatusHistory={selectedNegotiationStatusHistory} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    } */}
                            {selectedNegotiationStatusHistory.length > 2 && (selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-1]?.state_to === States.Working || selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-1]?.state_to === States.ReferralApproved) && selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-1]?.state_from === States.Referred &&
                                //display this alert when the state is Working or Referral approved both from Referred state
                                <Alert
                                    message="Referral approved"
                                    description={selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-1]?.state_to === States.Working ? selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-2]?.notes : selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-1]?.notes}
                                    style={{width: '100%'}}
                                    showIcon={true}
                                />}
                            {selectedNegotiationStatusHistory.length && selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-1]?.state_to === States.Referred &&
                                //display this alert when the state is Referred (always from Working)
                                <Alert
                                    message="Referred"
                                    description={selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-1]?.notes}
                                    style={{width: '100%'}}
                                    showIcon={true}
                                />}
                            {selectedNegotiationStatusHistory.length && selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-1]?.state_to === States.ReferralDeclined &&
                                //display this alert when the state is Referred Declined
                                <Alert
                                    type={'error'}
                                    message="Referred declined"
                                    description={selectedNegotiationStatusHistory[selectedNegotiationStatusHistory.length-1]?.notes}
                                    style={{width: '100%'}}
                                    showIcon={true}
                                />}
                            {isNegotiationExpired(selectedNegotiation.state, dayjs(selectedNegotiation.policy_effective_date)) &&
                                <Alert
                                    message="Attenzione"
                                    description={'La data di decorrenza è passata'}
                                    type={'error'}
                                    style={{width: '100%'}}
                                    showIcon={true}
                                />}

                            {formItems.map((formItem, idx) => {
                                return (
                                    <Col span={24} key={idx}>
                                        {formItem.component}
                                    </Col>)
                            })}
                        </Row>
                      </Form.Provider>
                    </motion.div>
                  </Col>

                    {/* {!isPortfolio && */}
                  <Col style={{width: '35%', padding: 0}} className={'negotiation-status'}>
                      {/* I pass saveData and not debounceInputChange because i need that the uw call is performed instantely (uw assignment) */}
                    <NegotiationDetailStatusManager isSimplifiedView={false} negotiation={selectedNegotiation}
                                                    setNegotiation={(negotiation: NegotiationModel) => setSelectedNegotiation(negotiation)}
                                                    options={options} updateNegotiation={saveData}
                                                    isUpdateNegotiationLoading={isUpdateNegotiationLoading}
                                                    negotiationStatusHistory={selectedNegotiationStatusHistory}
                                                    setNegotiationStatusHistory={setSelectedNegotiationStatusHistory}
                                                    negotiationId={negotiationId} form={form}/>
                  </Col>
                    {/* } */}

                </Row>}
            </main>
        </div>
    )


}