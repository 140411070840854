import {Modal, Button, Row, Col, Space, Checkbox, Divider} from 'antd'
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {selectUser, setTablesPreferences} from "../../redux/features/userSlice";
import {ColumnsType} from "antd/es/table";
import {GeneralNegotiation} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {useSetUserTablesPreferencesMutation} from "../../redux/api/userPreferencesApiSlice";


export default function ColumnSelectionGeneralTableFilter({
                                                              currentColumns,
                                                              setShowColumnsSelections,
                                                              showColumnsSelection,
                                                              defaultColumns,
                                                              columnsSelected,
                                                              setColumnsSelected
                                                          }: {
    currentColumns: ColumnsType<GeneralNegotiation>,
    setShowColumnsSelections: Dispatch<SetStateAction<boolean>>,
    showColumnsSelection: boolean, defaultColumns: ColumnsType<GeneralNegotiation>,
    columnsSelected: string[],
    setColumnsSelected: React.Dispatch<React.SetStateAction<string[]>>
}) {


    const reinsurerCols = defaultColumns.filter(col => ['reinsurance_type', 'reinsurers_list', 'reinsurer_broker', 'reinsured_share_perc', 'attachment_point',].includes(col.key as string))
    const policyCols = defaultColumns.filter(col => ['is_framework', 'installment_type', 'insured_sum', 'cancellation_terms_days', 'policy_number', 'has_regulation_premium', 'is_auto_renewal'].includes(col.key as string))
    const negotiationDataCols = defaultColumns.filter(col => !reinsurerCols.map(c => c.key).includes(col.key) && !policyCols.map(c => c.key).includes(col.key))
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    const [updatePreferences] = useSetUserTablesPreferencesMutation()


    const handleCancel = useCallback(() => {
        setColumnsSelected(currentColumns.map(col => col.key as string))
        setShowColumnsSelections(false)
    }, [currentColumns, setColumnsSelected, setShowColumnsSelections])

    return <Modal
        width={'45rem'}
        title={'Colonne da visualizzare in tabella'}
        onCancel={handleCancel}
        footer={[
            <>
                <Row justify={'space-between'} key={'modal-actions'}>
                    <Col>
                        <Space>
                            <Button onClick={handleCancel}> Annulla</Button>
                            <Button danger disabled={columnsSelected.length === 0}
                                    onClick={() => setColumnsSelected([])}>Reset</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Button
                            disabled={columnsSelected.length === 0}
                            onClick={() => {
                                const notChangedColumns: string[] = columnsSelected.filter(id => currentColumns.find(col => col.key === id))
                                const newColumns: string[] = columnsSelected.filter(id => !notChangedColumns.includes(id))
                                const removedColumns: string[] = currentColumns.filter(col => !columnsSelected.includes(col.key as string)).map(col => col.key as string)
                                const updatedSorters = user.preferences.tables.general.sorters ? user.preferences.tables.general.sorters?.filter(el => !removedColumns.includes(el.key) && el.type !== undefined) : []
                                const updatedFilters = user.preferences.tables.general.filters ? user.preferences.tables.general.filters?.filter(el => !removedColumns.includes(el.key) && el.range.length > 0) : []

                                dispatch(setTablesPreferences({
                                    general: {
                                        selected_columns: columnsSelected
                                    }
                                }))

                                updatePreferences({
                                    general: {
                                        sorters: updatedSorters,
                                        filters: updatedFilters,
                                        selected_columns: [...notChangedColumns, ...newColumns]
                                    }
                                })

                                setShowColumnsSelections(false)
                            }}
                            type={'primary'}
                        >
                            Conferma
                        </Button>
                    </Col>
                </Row>
            </>
        ]}
        open={showColumnsSelection}
    >
        <Checkbox.Group
            value={columnsSelected}
            onChange={(values) => setColumnsSelected(values.map(v => v.toString()))}
        >
            <div style={{fontWeight: 'bold', fontSize: 'large', marginBottom: '1rem'}}>Dati trattativa</div>
            <Row justify={'space-between'} gutter={36}>
                {negotiationDataCols.map(col => <Col span={11} key={`checkbox-${col.key}`}><Checkbox
                    value={col.key}
                    style={{marginBottom: '1.3rem'}}
                >
                    {col.title as string}
                </Checkbox></Col>)}
            </Row>
            <Divider/>
            <div style={{fontWeight: 'bold', fontSize: 'large', marginBottom: '1rem'}}>Riass</div>
            <Row justify={'space-between'} gutter={36}>
                {reinsurerCols.map(col => <Col span={11} key={`checkbox-${col.key}`}><Checkbox
                    style={{marginBottom: '1.3rem'}}
                    value={col.key}>
                    {col.title as string}
                </Checkbox></Col>)}
            </Row>
            <Divider/>
            <div style={{fontWeight: 'bold', fontSize: 'large', marginBottom: '1rem'}}>Polizze</div>
            <Row justify={'space-between'} gutter={36}>
                {policyCols.map(col => <Col span={11} key={`checkbox-${col.key}`}><Checkbox
                    style={{marginBottom: '1.3rem'}}
                    value={col.key}>
                    {col.title as string}
                </Checkbox></Col>)}
            </Row>
        </Checkbox.Group>
        {columnsSelected.length === 0 && <p style={{color: 'red', textAlign: 'center'}}>Selezionare almeno una colonna</p>}
    </Modal>
}