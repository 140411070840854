import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from 'react';

import {
    Col,
    Row,
    Card,
    Tag,
    Form,
    Input,
    Radio,
    Tooltip,
    Button,
    Breadcrumb,
    Layout,
    Menu,
    Typography,
    Collapse,
    Space,
    InputNumber,
    Divider,
    Select,
    Modal,
    message
} from 'antd';

import {useTranslation} from 'react-i18next';
import {CommonFormComponentProps} from "types/negotiations/components";


import {
    DeleteOutlined,
    PlusOutlined,
    SolutionOutlined,
    CheckCircleFilled,
    DownOutlined,
    UpOutlined,
    ExclamationCircleFilled,
    CloseOutlined, EditOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import {
    BelatedEmittedModel,
    CreateBelatedEmittedForm,
    NegotiationModel, NegotiationTransitionHistory,
    NegotiationTransitionModel,
    UnmountBelatedEmittedForm
} from "types/negotiations";
import dayjs from "dayjs";
import {useForm} from "antd/es/form/Form";
import {
    useCreateNegotiationBelatedEmittedMutation,
    useLazyGetNegotiationBelatedEmittedQuery,
    useUnmountNegotiationBelatedEmittedMutation,
    useUnmountPartialNegotiationBelatedEmittedMutation, useUpdateNegotiationBelatedEmittedMutation
} from "redux/api/negotiationBelatedEmittedApiSlice";
import DatePicker from "components/DatePicker";
import {AntCurrencyFormatInput} from "components/inputNumber";
import {euro, percentage} from "../../../../utils/utils";
import {Alert} from "antd";

const {Header, Content, Footer, Sider} = Layout;
const {Title, Paragraph, Text} = Typography
const {Panel} = Collapse;
const {Option} = Select;


export default function PoliciesAndIssues({
                                              forwaredRef,
                                              formInstance,
                                              negotiation,
                                              debounceInputChange,
                                              onFormValuesChange,
                                              disabled
                                          }: CommonFormComponentProps) {

    const [isCreateFractionamentOpen, setIsCreateFractionamentOpen] = useState<boolean>(false);

    const [isUpdateFractionamentOpen, setIsUpdateFractionamentOpen] = useState<boolean>(false)
    const [belatedEmitted, setBelatedEmitted] = useState<BelatedEmittedModel>()

    const [belatedEmittedList, setBelatedEmittedList] = useState<BelatedEmittedModel[]>([])
    const [getBelatedEmitted, {isLoading: isBelatedEmittedLoading}] = useLazyGetNegotiationBelatedEmittedQuery()

    const {waiting_reasons, installmentTypes} = useSelector(selectOptions)
    const [totalBelatedEmitted, setTotalBelatedEmitted] = useState<number | null>(null)

    const watchHasRegulationPremium = Form.useWatch('has_regulation_premium', formInstance)

    const fetchBelatedEmitted = useCallback(async () => {
        try {
            const res = await getBelatedEmitted({negotiationId: negotiation.uuid}).unwrap()
            setBelatedEmittedList(res.results)
            setTotalBelatedEmitted(res.total_belated_emitted)
        } catch (e: any) {
            message.error('Emesso tardivo: Impossibile scaricare i frazionamenti')
        }
    }, [getBelatedEmitted, negotiation.uuid])

    useEffect(() => {
        /*async function fetchBelatedEmitted() {
            try {
                const res = await getBelatedEmitted({negotiationId: negotiation.uuid}).unwrap()
                setBelatedEmittedList(res.results)
            } catch (e: any) {
                message.error('Emesso tardivo: Impossibile scaricare i frazionamenti')
            }
        }*/

        fetchBelatedEmitted()
    }, [fetchBelatedEmitted])

    /*useEffect(() => {
        //console.log(belatedEmitted)
        // setIsUpdateFractionamentOpen(true)
    }, [belatedEmitted])*/

    return (
        <Card ref={forwaredRef} id="policiesAndIssues" bordered={false} style={{
            // padding: '24px',
            borderRadius: '12px',
            width: '100%'
        }}>
            <CreateFractionamentModal isOpen={isCreateFractionamentOpen}
                                      onClose={() => setIsCreateFractionamentOpen(false)} negotiation={negotiation}
                                      belatedEmittedList={belatedEmittedList}
                                      fetchBelatedEmitted={fetchBelatedEmitted}
            />
            {belatedEmitted !== undefined ?
                <UpdateFractionamentModal isOpen={isUpdateFractionamentOpen}
                                          onClose={() => {
                                              setIsUpdateFractionamentOpen(false);
                                              fetchBelatedEmitted()
                                              setBelatedEmitted(undefined)
                                          }}
                                          fetchBelatedEmitted={fetchBelatedEmitted}
                                          negotiation={negotiation}
                                          belatedEmitted={belatedEmitted}/> : null}
            <Row>
                <Col>
                    <Title level={3}>
                        <SolutionOutlined/>
                    </Title>
                </Col>
                <Col style={{marginLeft: '12px'}}>
                    <Title level={3}>Polizze ed emissioni</Title>
                </Col>
            </Row>
            <Form
                form={formInstance}
                disabled={disabled}
                name='portfolioPoliciesAndIssues'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
                style={{marginTop: '24px', marginBottom: '36px'}}
            >
                <Space direction='vertical' style={{width: '100%'}} size='large'>
                    <Row align='top' justify='space-between'>
                        <Col span={7}>
                            <Space direction='vertical'>
                                <Text>Polizza</Text>
                                <Text
                                    strong>{negotiation.is_framework_agreement === null ? 'Non definito' : negotiation.is_framework_agreement ? 'Convenzione' : 'Singola'}</Text>
                            </Space>
                        </Col>
                        <Col span={7}>
                            <Space direction='vertical'>
                                <Text>Frazionamento</Text>
                                <Text
                                    strong>{negotiation.installment_type ? (installmentTypes.find(type => type.value === negotiation.installment_type)?.text || `Valore non valido (${negotiation.installment_type})`) : 'Non Definito'}</Text>
                            </Space>
                        </Col>
                        <Col span={7}>
                            <Form.Item label="Motivazione attesa" name='portfolio_waiting_reason' required
                                       style={{marginBottom: 0}}>
                                <Select
                                    virtual={false}
                                    showSearch
                                    placeholder="Selezionare la causa"
                                    optionFilterProp="children"
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                                    }
                                >
                                    <Option value={null}>Nessuna attesa</Option>
                                    {waiting_reasons.map(reason => <Option key={reason.value}
                                                                           value={reason.value}>{reason.text}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row align='middle' justify='start'>
                        <Col><Text>Emissione</Text></Col>
                        <Col style={{ marginLeft: '12px' }}><Text strong >{negotiation.installment_type === null || 'Non definito'}</Text></Col>
                    </Row> */}
                    <Row justify='space-between' align='top'>
                        <Col span={7}>
                            <Space direction='vertical'>
                                <Text>Premio imponibile polizza (Quota ISA)</Text>
                                <strong><AntCurrencyFormatInput displayType='text'
                                                                value={negotiation.isa_quoted_premium_share}/></strong>
                            </Space>
                        </Col>
                        <Col span={7}>
                            <Space direction='vertical'>
                                <Text>Importo assicurato</Text>
                                <strong><AntCurrencyFormatInput displayType='text'
                                                                value={negotiation.insured_sum}/></strong>
                            </Space>
                        </Col>
                    </Row>
                    <Row justify='space-between' align='top'>
                        <Col span={7}>
                            <Space direction='vertical'>
                                <Text>Date decorrenza polizza</Text>
                                <Text
                                    strong>{negotiation.policy_effective_date ? dayjs(negotiation.policy_effective_date).format('DD/MM/YYYY') : 'Non definito'}</Text>
                            </Space>
                        </Col>
                        <Col span={7}>
                            <Space direction='vertical'>
                                <Text>Data scadenza polizza</Text>
                                <Text
                                    strong>{negotiation.policy_expiring_date ? dayjs(negotiation.policy_expiring_date).format('DD/MM/YYYY') : 'Non definito'}</Text>
                            </Space>
                        </Col>
                        <Col span={7}>
                            <Space direction='vertical'>
                                <Form.Item label='Numero polizza' name='policy_number' style={{marginBottom: 0}}>
                                    <Input
                                        style={{width: '100%'}}
                                        placeholder='Numero polizza'/>
                                </Form.Item>
                            </Space>
                        </Col>
                    </Row>
                    <Row align='top'>
                        <Col span={7}>
                            <Form.Item name='has_regulation_premium' label="Regolazione premio"
                                       required>
                                <Radio.Group>
                                    <Radio value={true}>Sì </Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {watchHasRegulationPremium &&
                            <Col span={9}>
                                <Form.Item label="Premio di regolazione (nostra quota)" name='regulation_premium'
                                           required>
                                    <AntCurrencyFormatInput/>
                                </Form.Item></Col>
                        }
                    </Row>
                </Space>
            </Form>
            <BelatedIssuedManager negotiation={negotiation}
                                  fetchBelatedEmitted={fetchBelatedEmitted}
                                  belatedEmittedList={belatedEmittedList}
                                  setBelatedEmittedList={setBelatedEmittedList}
                                  createFractionament={() => setIsCreateFractionamentOpen(true)}
                                  updateFractionament={(be: BelatedEmittedModel) => {
                                      setBelatedEmitted(be)
                                      setIsUpdateFractionamentOpen(true)
                                  }}
            />
            <Row justify={"space-around"} style={{marginTop: '24px'}}>
                <Col>
                    <Space direction={"horizontal"}>
                        {belatedEmittedList.length > 0 ?
                            <>Totale emesso tardivo:
                                <Text strong>
                                    {totalBelatedEmitted ? euro(totalBelatedEmitted) : euro(0)}
                                </Text></>
                            : 'Nessun titolo presente al momento'}
                    </Space>
                </Col>
            </Row>
        </Card>

    )
}

type CreateFractionamentProps = {
    negotiation: NegotiationModel,
    belatedEmittedList: BelatedEmittedModel[]
    isOpen: boolean
    onClose: () => void
    fetchBelatedEmitted: () => Promise<void>
}

export function CreateFractionamentModal({
                                             isOpen,
                                             onClose,
                                             negotiation,
                                             belatedEmittedList,
                                             fetchBelatedEmitted
                                         }: CreateFractionamentProps) {

    const [form] = Form.useForm<CreateBelatedEmittedForm>()
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

    const [createFractionament, {isLoading, isError}] = useCreateNegotiationBelatedEmittedMutation()

    const watchInstallmentPremium = Form.useWatch('installment_premium', form)
    const watchInstallmentEffectiveDate = Form.useWatch('installment_effective_date', form)


    useEffect(() => {
        let commission = 0
        if (watchInstallmentPremium && negotiation.fee_total_perc) {
            commission = +(watchInstallmentPremium * negotiation.fee_total_perc).toFixed(2)
        }
        form.setFieldsValue({installment_commission: commission})

    }, [form, negotiation.fee_total_perc, watchInstallmentPremium])

    const onValuesChange = () => {
        const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
        const fields = form.getFieldsValue()
        const allFieldsOk = !!(fields.installment_effective_date && fields.installment_premium && fields.installment_expiring_date && fields.installment_expiring_date >= fields.installment_effective_date)
        if (!fields.installment_effective_date || fields.installment_expiring_date < fields.installment_effective_date) {
            form.setFieldsValue({'installment_expiring_date': undefined})
        }
        setDisabledSubmit(hasErrors || !allFieldsOk);
    }

    async function onFinish(values: CreateBelatedEmittedForm) {
        const newInstallmentNumber = Math.max(...belatedEmittedList.map(fract => fract.installment_num), 0);
        try {
            const newFractionament = await createFractionament({
                negotiation_id: negotiation.uuid,
                data: {
                    ...values,
                    installment_num: newInstallmentNumber + 1,
                    installment_effective_date: new Date(values.installment_effective_date.format('YYYY-MM-DD')),
                    installment_expiring_date: new Date(values.installment_expiring_date.format('YYYY-MM-DD'))
                }
            }).unwrap()
            await fetchBelatedEmitted()
            message.success('Frazionamento creato')
            onClose()
        } catch (e: any) {
            message.error('Impossibile creare il frazionamento')
        }
    }

    return (


        <Modal
            title={<Row align='middle' justify='space-between'>
                <Col>
                    <Title level={5}>Crea Titolo</Title>
                </Col>
                <Col>
                    <Space direction='horizontal' align='center' size='middle'>
                        <Tag
                            color={negotiation.fee_total_perc !== null ? 'blue' : 'red'}>Provvigioni: {negotiation.fee_total_perc !== null ?
                            <AntCurrencyFormatInput displayType='text' value={percentage(negotiation.fee_total_perc)}
                                                    suffix=' %'/> : 'Non impostate'}</Tag>
                        <CloseOutlined style={{cursor: 'pointer'}} onClick={onClose}/>
                    </Space>
                </Col>
            </Row>}
            closable={false}
            centered
            open={isOpen}
            onOk={onClose}
            onCancel={onClose}
            footer={<>
                <Alert style={{textAlign: 'left'}} type={"warning"}
                       message={"Attenzione, la creazione del titolo aumenterà l'emesso tardivo se la data di decorrenza è già passata"}
                       banner></Alert>
                <Row align='middle' justify="space-between" style={{marginTop: '0.75rem'}}>
                    <Col>
                        <Button onClick={onClose}>
                            Annulla
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={disabledSubmit} type='primary'
                                onClick={() => onFinish(form.getFieldsValue())}>
                            Crea
                        </Button>
                    </Col>
                </Row>
            </>
            }
        >
            <Form form={form} name="createBelatedEmitted" onValuesChange={onValuesChange} onFinish={onFinish}
                  style={{width: '100%'}} requiredMark={false}>
                <Form.Item label="Decorrenza" name='installment_effective_date' required>
                    <DatePicker
                        defaultPickerValue={undefined}
                        defaultValue={undefined}
                        style={{width: '100%'}}
                        placeholder='Seleziona una data'
                        format={'DD/MM/YYYY'}/>
                </Form.Item>
                <Form.Item label="Scadenza" name='installment_expiring_date' required>
                    <DatePicker
                        defaultPickerValue={undefined}
                        defaultValue={undefined}
                        style={{width: '100%'}}
                        placeholder='Seleziona una data'
                        format={'DD/MM/YYYY'}
                        disabled={!watchInstallmentEffectiveDate}
                        disabledDate={(current) => !watchInstallmentEffectiveDate || watchInstallmentEffectiveDate >= current}
                    />
                </Form.Item>
                <Form.Item name='installment_premium' label='Importo premio' required>
                    <AntCurrencyFormatInput/>
                </Form.Item>
                <Tooltip placement='top' title='Provvigioni totali * importo premio'>
                    <Form.Item name='installment_commission' label='Importo provvigioni' required>
                        <AntCurrencyFormatInput disabled/>
                    </Form.Item>
                </Tooltip>
            </Form>
        </Modal>
    )


}


type UpdateFractionamentProps = {
    negotiation: NegotiationModel,
    belatedEmitted: BelatedEmittedModel
    //setBelatedEmittedList: (be: BelatedEmittedModel[]) => void
    //fetchBelatedEmittedList: () => BelatedEmittedModel[]
    isOpen: boolean
    onClose: () => void
    fetchBelatedEmitted: () => Promise<void>
}

export function UpdateFractionamentModal({
                                             isOpen,
                                             onClose,
                                             negotiation,
                                             belatedEmitted,
                                             fetchBelatedEmitted
                                         }: UpdateFractionamentProps) {

    const [form] = Form.useForm<CreateBelatedEmittedForm>()
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

    const [updateFractionament, {isLoading, isError}] = useUpdateNegotiationBelatedEmittedMutation()

    const watchInstallmentPremium = Form.useWatch('installment_premium', form)
    const watchInstallmentEffectiveDate = Form.useWatch('installment_effective_date', form)

    useEffect(() => {
        let commission = 0
        if (watchInstallmentPremium && negotiation.fee_total_perc) {
            commission = +(watchInstallmentPremium * negotiation.fee_total_perc).toFixed(2)
        }
        form.setFieldsValue({installment_commission: commission})
    }, [form, negotiation.fee_total_perc, watchInstallmentPremium])

    const onValuesChange = () => {
        const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
        const fields = form.getFieldsValue()
        const allFieldsOk = !!(fields.installment_effective_date && fields.installment_premium && fields.installment_expiring_date && fields.installment_expiring_date >= fields.installment_effective_date)
        if (!fields.installment_effective_date || fields.installment_expiring_date < fields.installment_effective_date) {
            form.setFieldsValue({'installment_expiring_date': undefined})
        }
        setDisabledSubmit(hasErrors || !allFieldsOk);
    }


    async function onFinish(values: CreateBelatedEmittedForm) {
        if (belatedEmitted) {
            const newInstallmentNumber = Math.max(belatedEmitted.installment_num, 0);
            try {
                await updateFractionament({
                    id: belatedEmitted.uuid,
                    negotiation_id: negotiation.uuid,
                    data: {
                        ...values,
                        installment_num: newInstallmentNumber,
                        installment_effective_date: new Date(values.installment_effective_date.format('YYYY-MM-DD')),
                        installment_expiring_date: new Date(values.installment_expiring_date.format('YYYY-MM-DD'))
                    }
                }).unwrap()
                message.success('Frazionamento aggiornato')
                onClose()
            } catch (e: any) {
                message.error('Impossibile aggiornare il frazionamento')
                console.error('Fractionament update failed', e)
            }
        }

    }

    return (


        <Modal
            title={<Row align='middle' justify='space-between'>
                <Col>
                    <Title level={5}>Aggiorna Titolo</Title>
                </Col>
                <Col>
                    <Space direction='horizontal' align='center' size='middle'>
                        <Tag
                            color={negotiation.fee_total_perc !== null ? 'blue' : 'red'}>Provvigioni: {negotiation.fee_total_perc !== null ?
                            <AntCurrencyFormatInput displayType='text' value={percentage(negotiation.fee_total_perc)}
                                                    suffix=' %'/> : 'Non impostate'}</Tag>
                        <CloseOutlined style={{cursor: 'pointer'}} onClick={onClose}/>
                    </Space>
                </Col>
            </Row>}
            closable={false}
            centered
            open={isOpen}
            onOk={onClose}
            onCancel={onClose}
            footer={<>
                <Alert style={{textAlign: 'left'}} type={"warning"}
                       message={"Attenzione, la creazione del titolo aumenterà l'emesso tardivo se la data di decorrenza è già passata"}
                       banner></Alert>
                <Row align='middle' justify="space-between" style={{marginTop: '0.75rem'}}>
                    <Col>
                        <Button onClick={onClose}>
                            Annulla
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={disabledSubmit} type='primary'
                                onClick={() => onFinish(form.getFieldsValue())}>
                            Aggiorna
                        </Button>
                    </Col>
                </Row>
            </>
            }
        >
            <Form form={form} name="updateBelatedEmitted" onValuesChange={onValuesChange} onFinish={onFinish}
                  style={{width: '100%'}} requiredMark={false}>
                <Form.Item label="Decorrenza" name='installment_effective_date'
                           initialValue={dayjs(belatedEmitted.installment_effective_date)}>
                    <DatePicker
                        defaultPickerValue={undefined}
                        defaultValue={dayjs(belatedEmitted.installment_effective_date)}
                        style={{width: '100%'}}
                        placeholder='Seleziona una data'
                        format={'DD/MM/YYYY'}/>
                </Form.Item>
                <Form.Item label="Scadenza" name='installment_expiring_date' required
                           initialValue={dayjs(belatedEmitted.installment_expiring_date)}
                >
                    <DatePicker
                        defaultPickerValue={undefined}
                        defaultValue={dayjs(belatedEmitted.installment_expiring_date)}
                        style={{width: '100%'}}
                        placeholder='Seleziona una data'
                        format={'DD/MM/YYYY'}
                        disabled={!watchInstallmentEffectiveDate}
                        disabledDate={(current) => !watchInstallmentEffectiveDate || watchInstallmentEffectiveDate >= current}
                    />
                </Form.Item>
                <Form.Item name='installment_premium' label='Importo premio'
                           initialValue={belatedEmitted.installment_premium}>
                    <AntCurrencyFormatInput defaultValue={belatedEmitted.installment_premium}/>
                </Form.Item>
                <Tooltip placement='top' title='Provvigioni totali * importo premio'>
                    <Form.Item name='installment_commission' label='Importo provvigioni'
                               initialValue={belatedEmitted.installment_commission}>
                        <AntCurrencyFormatInput disabled defaultValue={belatedEmitted.installment_commission}/>
                    </Form.Item>
                </Tooltip>
            </Form>
        </Modal>
    )
}


type BelatedIssuedManagerProps = {
    negotiation: NegotiationModel
    createFractionament: () => void
    belatedEmittedList: BelatedEmittedModel[]
    setBelatedEmittedList: (be: BelatedEmittedModel[]) => void
    updateFractionament: (be: BelatedEmittedModel) => void
    fetchBelatedEmitted: () => Promise<void>
}

export function BelatedIssuedManager({
                                         negotiation,
                                         createFractionament,
                                         belatedEmittedList,
                                         setBelatedEmittedList,
                                         updateFractionament,
                                         fetchBelatedEmitted
                                     }: BelatedIssuedManagerProps) {

    const [selectedFractionament, setSelectedFractionament] = useState<BelatedEmittedModel | undefined>(undefined)
    const [belatedEmittedByInstallmentNumber, setBelatedEmittedByInstallmentNumber] = useState<BelatedEmittedModel[][]>([])


    useEffect(() => {

        const uniqueInstallmentNumber: number[] = []
        belatedEmittedList.forEach(be => !(uniqueInstallmentNumber.includes(be.installment_num)) && uniqueInstallmentNumber.push(be.installment_num))
        setBelatedEmittedByInstallmentNumber(uniqueInstallmentNumber.map(inst_num => belatedEmittedList.filter(be => inst_num === be.installment_num)))


    }, [belatedEmittedList])


    return (
        <Space direction='vertical' style={{width: '100%'}} size='large'>
            {selectedFractionament &&
                <UnmountFractionamentModal isOpen={true}
                                           onClose={() => setSelectedFractionament(undefined)}
                                           belatedEmittedList={belatedEmittedList}
                                           setBelatedEmittedList={setBelatedEmittedList}
                                           fetchBelatedEmitted={fetchBelatedEmitted}
                                           belatedEmitted={selectedFractionament}
                                           negotiation={negotiation}/>}
            <Row align='middle' justify='space-between'>
                <Col>
                    <Title level={5}>Caricamento in emesso tardivo</Title>
                </Col>
                <Col>
                    <Row justify='end' align='middle'>
                        <Col><Button type='primary' onClick={() => createFractionament()}>Aggiungi Titolo</Button></Col>
                    </Row>
                </Col>
            </Row>

            {belatedEmittedByInstallmentNumber.map((beList, idx) => {
                return (
                    <BelatedIssuedFractionament key={idx}
                                                idx={idx}
                                                belatedEmittedList={beList}
                                                unmountFractionament={(f: BelatedEmittedModel) => setSelectedFractionament(f)}
                                                updateFractionament={(be: BelatedEmittedModel) => {
                                                    updateFractionament(be)
                                                }}/>
                )
            })}
        </Space>

    )
}


export function BelatedIssuedFractionament({
                                               belatedEmittedList,
                                               idx,
                                               unmountFractionament,
                                               updateFractionament,
                                           }: { belatedEmittedList: BelatedEmittedModel[], idx: number, unmountFractionament: (f: BelatedEmittedModel) => void, updateFractionament: (be: BelatedEmittedModel) => void }) {

    return (
        <Card style={{background: '#fafafa'}}
              bodyStyle={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}><Title level={5}>{idx + 1}° Titolo</Title></div>
            {belatedEmittedList.map((be, idx) => {
                return (<div key={'idx'}>
                        <Row justify={"center"}>
                            <Col xxl={18} xl={24}>
                                <Divider/>
                                <Row wrap={true} justify={"space-between"}>
                                    <Col span={6} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                                        <Space direction='vertical'>
                                            <Text>Decorrenza</Text>
                                            <Text strong>{dayjs(be.installment_effective_date).format('DD/MM/YYYY')}
                                            </Text>
                                        </Space>
                                    </Col>
                                    <Col span={6} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                                        <Space direction='vertical'>
                                            <Text>Scadenza</Text>
                                            <strong>{be.installment_expiring_date ? dayjs(be.installment_expiring_date).format('DD/MM/YYYY') :
                                                <span>Non presente</span>}</strong>
                                        </Space>
                                    </Col>
                                    <Col span={6} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                                        <Space direction='vertical'>
                                            <Text>Importo premio</Text>
                                            <strong> <AntCurrencyFormatInput displayType='text'
                                                                             value={be.installment_premium}/></strong>
                                        </Space>
                                    </Col>
                                    <Col span={6} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                                        <Space direction='vertical'>
                                            <Text>Provvigioni</Text>
                                            <strong><AntCurrencyFormatInput displayType='text'
                                                                            value={be.installment_commission}/></strong>
                                        </Space>
                                    </Col>
                                    {be.is_unmounted &&
                                        <Col span={7} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                                          <Space direction='vertical'>
                                            <Text>Data caricamento</Text>
                                            <strong>{be.unmounting_date && dayjs(be.unmounting_date).format('DD/MM/YYYY')}</strong>
                                          </Space>
                                        </Col>}
                                    {be.is_unmounted &&
                                        <Col span={8} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                                          <Space direction='vertical'>
                                            <Text>Importo premio effettivo</Text>
                                            <strong> <AntCurrencyFormatInput displayType='text'
                                                                             value={be.installment_premium_actual}/></strong>
                                          </Space>
                                        </Col>}
                                    {be.is_unmounted &&
                                        <Col span={7} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                                          <Space direction='vertical'>
                                            <Text>Numero certificato</Text>
                                            <Text
                                                strong>{be.certificate_number ? be.certificate_number :
                                                <span style={{textDecoration: 'underline'}}>Non presente</span>}</Text>
                                          </Space>
                                        </Col>}
                                </Row>
                            </Col>
                        </Row>
                        <Row justify={"center"}>
                            <Col flex={20}>
                                {be.is_unmounted ?
                                    <div style={{width: '100%'}}>
                                        <Space direction='vertical' align={"center"} style={{width: '100%'}}>
                                            <Space direction='vertical' align='center' style={{marginTop: '0.5rem'}}>
                                                <div style={{textAlign: 'center', color: 'green', fontWeight: '500'}}>
                                                    <CheckCircleFilled
                                                        style={{fontSize: '16px', color: 'green'}}/> Caricato su
                                                    portafoglio
                                                </div>
                                                <Text>{be.unmounting_date && dayjs(be.unmounting_date).format('DD/MM/YYYY')}</Text>
                                            </Space>
                                        </Space>
                                    </div>
                                    : !be.is_unmounted && be.is_mounted ?
                                        <div style={{width: '100%'}}>
                                            <Space direction='vertical' align={"center"} style={{width: '100%'}}>
                                                <Space direction='vertical' align='center'
                                                       style={{marginTop: '0.5rem'}}>
                                                    <div style={{
                                                        textAlign: 'center',
                                                        color: 'orange',
                                                        fontWeight: '500'
                                                    }}>
                                                        <ExclamationCircleFilled
                                                            style={{fontSize: '16px', color: 'orange'}}/> Caricato in
                                                        emesso tardivo
                                                    </div>
                                                    <AntCurrencyFormatInput displayType='text'
                                                                            value={be.installment_premium}/>
                                                </Space>
                                                <Space direction={"horizontal"} size={'middle'} style={{width: '100%'}}>
                                                    <Button onClick={() => {
                                                        updateFractionament(be)
                                                    }}>
                                                        <EditOutlined/>Aggiorna titolo
                                                    </Button>
                                                    <Button type='primary' onClick={() => unmountFractionament(be)}>
                                                        Carica su portafoglio
                                                    </Button>
                                                </Space>
                                            </Space>
                                        </div>
                                        : <div style={{width: '100%'}}>
                                            <Space direction='vertical' align={"center"} style={{width: '100%'}}>
                                                <div style={{
                                                    textAlign: 'center',
                                                    marginBottom: '0.5rem',
                                                    marginTop: '0.5rem'
                                                }}>
                                                    <InfoCircleOutlined
                                                        style={{fontSize: '16px', color: '#3FA9FF'}}/> Non genera emesso
                                                    tardivo
                                                </div>
                                                <Space direction={"horizontal"} size={'middle'} style={{width: '100%'}}>
                                                    <Button onClick={() => {
                                                        updateFractionament(be)
                                                    }}>
                                                        <EditOutlined/>Aggiorna titolo
                                                    </Button>
                                                    <Button type='primary' onClick={() => unmountFractionament(be)}>
                                                        Carica su portafoglio
                                                    </Button>
                                                </Space>
                                            </Space>
                                        </div>
                                }
                            </Col>
                        </Row>
                    </div>
                )
            })}
        </Card>

    )
}

type UnmountFractionamentProps = {
    negotiation: NegotiationModel,
    belatedEmitted: BelatedEmittedModel
    belatedEmittedList: BelatedEmittedModel[]
    setBelatedEmittedList: (be: BelatedEmittedModel[]) => void
    isOpen: boolean
    onClose: () => void,
    fetchBelatedEmitted: () => Promise<void>
}

export function UnmountFractionamentModal({
                                              isOpen,
                                              onClose,
                                              negotiation,
                                              belatedEmitted,
                                              belatedEmittedList,
                                              setBelatedEmittedList,
                                              fetchBelatedEmitted
                                          }: UnmountFractionamentProps) {

    const [form] = Form.useForm<UnmountBelatedEmittedForm>()
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

    const [unmountRequest, {isLoading: isUnmountLoading}] = useUnmountNegotiationBelatedEmittedMutation()
    const [partialUnmountRequest, {isLoading: isPartialUnmountLoading}] = useUnmountPartialNegotiationBelatedEmittedMutation()

    const onValuesChange = () => {
        const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
        const fields = form.getFieldsValue()
        const allFieldsOk = !!(fields.certificate_number && fields.installment_premium_actual && fields.unmounting_date && fields.installment_premium_residual !== undefined)
        setDisabledSubmit(hasErrors || !allFieldsOk);

    }

    async function unmountFractionament(partial?: boolean) {
        const {unmounting_date, ...values} = form.getFieldsValue()
        try {
            if (partial) {
                const unmountResult = await partialUnmountRequest({
                    id: belatedEmitted.uuid,
                    negotiation_id: negotiation.uuid,
                    data: {...values, unmounting_date: unmounting_date.toDate()}
                }).unwrap()
                //setBelatedEmittedList(unmountResult)
                await fetchBelatedEmitted()
                message.success('Frazionamento smontato parzialmente')
            } else {
                const unmountResult = await unmountRequest({
                    id: belatedEmitted.uuid,
                    negotiation_id: negotiation.uuid,
                    data: {...values, unmounting_date: unmounting_date.toDate()}
                }).unwrap()
                //setBelatedEmittedList(unmountResult)
                await fetchBelatedEmitted()
                message.success('Frazionamento smontato')
            }
            onClose()
        } catch (e: any) {
            console.error(e)
        }

    }

    const watchActualPremium = Form.useWatch('installment_premium_actual', form)
    const watchResidualPremium = Form.useWatch('installment_premium_residual', form)

    /*useEffect(() => {
        let residualValue = belatedEmitted.installment_premium
        if (watchActualPremium)
            residualValue -= watchActualPremium
        form.setFieldsValue({ installment_premium_residual: residualValue })
    }, [watchActualPremium])

    useEffect(() => {
        if (watchResidualPremium && negotiation.fee_total_perc !== null) {
            let residualValue = watchResidualPremium * negotiation.fee_total_perc
            form.setFieldsValue({ installment_commission_residual: residualValue })
        }
    }, [watchResidualPremium])*/

    useEffect(() => {
        let residualPremiumAmount = belatedEmitted.installment_premium
        let residualCommissionAmount = belatedEmitted.installment_premium
        const actualPremium = watchActualPremium || 0

        residualPremiumAmount = residualPremiumAmount - actualPremium >= 0 ? residualPremiumAmount - actualPremium : 0
        if (negotiation.fee_total_perc !== null)
            residualCommissionAmount = residualPremiumAmount * negotiation.fee_total_perc
        form.setFieldsValue({installment_premium_residual: residualPremiumAmount})
        form.setFieldsValue({installment_commission_residual: residualCommissionAmount})

    }, [belatedEmitted.installment_premium, form, negotiation.fee_total_perc, watchActualPremium])


    // async function onFinish(values: UnmountBelatedEmittedForm) {
    //     const newInstallmentNumber = Math.max(...belatedEmittedList.map(fract => fract.installment_num), 0);
    //     try {
    //         const newFractionament = await createFractionament({ negotiation_id, data: { ...values, installment_num: newInstallmentNumber + 1, installment_effective_date: values.installment_effective_date.toDate() } }).unwrap()
    //         setBelatedEmittedList([...belatedEmittedList, newFractionament])
    //         message.success('Frazionamento creato')
    //         onClose()
    //     }
    //     catch (e: any) {
    //         message.error('Impossibile creare il frazionamento')
    //     }
    // }

    return (


        <Modal
            title={<Row align='middle' justify='space-between'>
                <Col>
                    <Title level={5}>Smonta frazionamento</Title>
                </Col>
                <Col>
                    <Space direction='horizontal' align='center' size='middle'>
                        <Tag
                            color={negotiation.fee_total_perc !== null ? 'blue' : 'red'}>Provvigioni: {negotiation.fee_total_perc !== null ?
                            <AntCurrencyFormatInput displayType='text' value={percentage(negotiation.fee_total_perc)}
                                                    suffix=' %'/> : 'Non impostate'}</Tag>
                        <CloseOutlined style={{cursor: 'pointer'}} onClick={onClose}/>
                    </Space>
                </Col>
            </Row>}
            closable={false}
            centered
            open={isOpen}
            onOk={onClose}
            width={'40%'}
            onCancel={onClose}
            footer={
                <Row align='middle' justify="space-between">
                    <Col>
                        <Button onClick={onClose}>
                            Annulla
                        </Button>
                    </Col>
                    <Col>
                        <Space direction='horizontal'>
                            <Button
                                disabled={disabledSubmit || watchResidualPremium <= 0 || watchActualPremium >= belatedEmitted.installment_premium}
                                type='primary'
                                onClick={() => unmountFractionament(true)}>
                                Smonta parziale
                            </Button>
                            <Button disabled={disabledSubmit} type='primary' onClick={() => unmountFractionament()}>
                                Smonta tutto
                            </Button>
                        </Space>
                    </Col>
                </Row>
            }
        >
            {/* <Space size='small' direction="vertical" style={{ width: '100%' }}> */}
            <div style={{display: 'flex', justifyContent: 'center'}}><Title level={5}>Frazionamento selezionato</Title>
            </div>
            <Row style={{background: '#fafafa', borderRadius: '12px', border: '1px solid #f0f0f0', padding: '8px 12px'}}
                 justify='space-between' align='middle'>
                <Col span={5}>
                    <Space direction='vertical'>
                        <Text>Decorrenza</Text>
                        <Text strong>{dayjs(belatedEmitted.installment_effective_date).format('DD/MM/YYYY')}</Text>
                    </Space>
                </Col>
                <Col span={5}>
                    <Space direction='vertical'>
                        <Text>Scadenza</Text>
                        <Text strong>{dayjs(belatedEmitted.installment_expiring_date).format('DD/MM/YYYY')}</Text>
                    </Space>
                </Col>
                <Col span={5}>
                    <Space direction='vertical'>
                        <Text>Importo premio</Text>
                        <strong> <AntCurrencyFormatInput displayType='text' value={belatedEmitted.installment_premium}/></strong>
                    </Space>
                </Col>
                <Col span={5}>
                    <Space direction='vertical'>
                        <Text>Provvigioni</Text>
                        <strong><AntCurrencyFormatInput displayType='text'
                                                        value={belatedEmitted.installment_commission}/></strong>
                    </Space>
                </Col>
            </Row>
            <Divider/>
            <Form form={form} name="unmountBelatedEmitted" layout={'vertical'} onValuesChange={onValuesChange}
                  style={{width: '100%'}} requiredMark={false}>
                <Row align='middle' justify='space-between'>
                    <Col span={7}>
                        <Form.Item name='certificate_number' label='Numero certificato / Polizza' required>
                            <Input placeholder="Numero certificato/Polizza"/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name='installment_premium_actual' label='Importo premio effettivo' required>
                            <AntCurrencyFormatInput/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label="Data caricamento su portafoglio" name='unmounting_date' required>
                            <DatePicker
                                defaultPickerValue={undefined}
                                defaultValue={undefined}
                                style={{width: '100%'}}
                                placeholder='Seleziona una data'
                                format={'DD/MM/YYYY'}
                                disabledDate={(current) => current > dayjs()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align='middle' justify='space-between'>
                    <Col span={7}>
                        <Tooltip placement='top' title='Importo premio - importo effettivo'>
                            <Form.Item name='installment_premium_residual' label='Importo premio residuo' required>
                                <AntCurrencyFormatInput disabled allowNegative={true}/>
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={7}>
                        <Tooltip placement='top' title='Provvigioni * importo residuo'>
                            <Form.Item name='installment_commission_residual' label='Importo provvigioni residuo'
                                       required>
                                <AntCurrencyFormatInput disabled/>
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
            {/* </Space> */}
        </Modal>
    )


}