import NegotiationTotal from "../charts/ChartCards/NegotiationTotal";
import GapToPlan from "../charts/ChartCards/GapToPlan";
import RenewRetention from "../charts/ChartCards/RenewRetention";
import React, {useRef} from "react";
import DashboardHeader from "./DashboardHeader";
import {Col, Row} from "antd";
import Title from "antd/lib/typography/Title";
import HighchartsReact from "highcharts-react-official";
import NegotiationsStatusChart from "../charts/ChartCards/NegotiationsStatusChart";
import NegotiationsPremiumsChart from "../charts/ChartCards/NegotiationsPremiumsChart";
import UnderProcessingNegotiationsForLobChart from "../charts/ChartCards/UnderProcessingNegotiationsForLobChart";
import UnderProcessingPremiumsForLobChart from "../charts/ChartCards/UnderProcessingPremiumsForLobChart";
import SubmissionReceptionChart from "../charts/ChartCards/SubmissionReceptionChart";
import QuotedPremiumsOnReceivedChart from "../charts/ChartCards/QuotedPremiumsOnReceivedChart";
import BoundPremiumsOnTotalChart from "../charts/ChartCards/BoundPremiumsOnTotalChart";
import NegotiationsResultsChart from "../charts/ChartCards/NegotiationsResultsChart";
import MonthlyHitRatioChart from "../charts/ChartCards/MonthlyHitRatioChart";
import AnnualHitRatioChart from "../charts/ChartCards/AnnualHitRatioChart";
import BrokerDataTotalsChart from "../charts/ChartCards/BrokerDataTotalsChart";
import NetPremiumOurShareChart from "../charts/ChartCards/NetPremiumOurShareChart";
import AnnualGoalChart from "../charts/ChartCards/AnnualGoalChart";
import TrendAgencyChart from "../charts/ChartCards/TrendAgencyChart";
import UnderProcessingAgencyNegotiationsStatusChart
    from "../charts/ChartCards/UnderProcessingAgencyNegotiationsStatusChart";
import GapToPipelineChart from "../charts/ChartCards/GapToPipelineChart";
import NetPremiumOurShareSumChart from "../charts/ChartCards/NetPremiumOurShareSumChart";
import AccountNegotiationsCountChart from "../charts/ChartCards/AccountNegotiationsCountChart";
import {User} from "../../types/user";
import {BudgetGroup} from "../../types";
import HitRatioContainer from "../charts/ChartCards/HitRatioContainer";
import NetPremiumOurShareTypeBusinessChart from "../charts/ChartCards/NetPremiumOurShareTypeBusinessChart";

export default function UnderWriterDashboard(props: { user: User, budgetGroups: BudgetGroup[] }) {

    const gapToPlanChartRef = useRef<HighchartsReact.RefObject>(null);
    const renewRetentionChartRef = useRef<HighchartsReact.RefObject>(null);


    const negotiationsStatusChartRef = useRef<HighchartsReact.RefObject>(null);
    const negotiationsPremiumsChartRef = useRef<HighchartsReact.RefObject>(null);
    // const underProcessingNegotiationsForLobChartRef = useRef<HighchartsReact.RefObject>(null);
    // const underProcessingPremiumsForLobChartRef = useRef<HighchartsReact.RefObject>(null);
    const submissionReceptionChartRef = useRef<HighchartsReact.RefObject>(null);
    const quotedPremiumsOnReceivedChartRef = useRef<HighchartsReact.RefObject>(null);
    const boundPremiumsOnTotalChartRef = useRef<HighchartsReact.RefObject>(null);
    const negotiationsResultsChartRef = useRef<HighchartsReact.RefObject>(null);
    const monthlyHitRatioChartRef = useRef<HighchartsReact.RefObject>(null);
    const annualHitRatioChartRef = useRef<HighchartsReact.RefObject>(null);
    const netPremiumOurShareChartRef = useRef<HighchartsReact.RefObject>(null);
    const netPremiumOurShareTypeBusinessRef = useRef<HighchartsReact.RefObject>(null);
    const brokerDataTotalsChartRef = useRef<HighchartsReact.RefObject>(null);
    const annualGoalChartRef = useRef<HighchartsReact.RefObject>(null);
    const gapToPipelineChartRef = useRef<HighchartsReact.RefObject>(null);
    const netPremiumOurShareSumChartRef = useRef<HighchartsReact.RefObject>(null);
    const accountNegotiationsCountChartRef = useRef<HighchartsReact.RefObject>(null);

    return <React.Fragment>
        <DashboardHeader budgetGroups={props.budgetGroups} className={'dashboard-data-header'}/>
        <div className={'dashboard-uw-container'}>
            <div className={'negotiation-overview--charts'}>
                <NegotiationTotal/>
                <GapToPlan chartRef={gapToPlanChartRef}/>
                <RenewRetention chartRef={renewRetentionChartRef}/></div>
            <NegotiationsStatusChart className={'negotiations-status--chart'} chartRef={negotiationsStatusChartRef}/>
            <NegotiationsPremiumsChart className={'negotiations-premiums--chart'}
                                       chartRef={negotiationsPremiumsChartRef}/>
            {/*<UnderProcessingNegotiationsForLobChart year={year} className={'under-processing-negotiations-for-lob--chart'} chartRef={underProcessingNegotiationsForLobChartRef}/>*/}
            {/*<UnderProcessingPremiumsForLobChart year={year} className={'under-processing-premiums-for-lob--chart'} chartRef={underProcessingPremiumsForLobChartRef}/>*/}

            <Row className={'status-negotiations-header'} style={{marginTop: '1rem'}}>
                <Col xs={{push: 1}} sm={{push: 0}}>
                    <Title level={3}>
                        Stato
                        trattative
                    </Title>
                </Col>
            </Row>
            <SubmissionReceptionChart className={'submission-reception--chart'} chartRef={submissionReceptionChartRef}/>
            <QuotedPremiumsOnReceivedChart className={'quoted-premiums-on-received--chart'}
                                           chartRef={quotedPremiumsOnReceivedChartRef}/>
            <BoundPremiumsOnTotalChart className={'bound-premiums-on-total--chart'}
                                       chartRef={boundPremiumsOnTotalChartRef}/>
            <NegotiationsResultsChart className={'negotiations-results--chart'} chartRef={negotiationsResultsChartRef}/>


            <HitRatioContainer chartRef={[monthlyHitRatioChartRef, annualHitRatioChartRef]}/>


            <NetPremiumOurShareChart className={'net-premium-our-share--chart'} chartRef={netPremiumOurShareChartRef}/>
            <NetPremiumOurShareTypeBusinessChart chartRef={netPremiumOurShareTypeBusinessRef}
                                                 className={'net-premium-our-share-type-business--chart'}/>
            <BrokerDataTotalsChart className={'broker-data-totals--chart'} chartRef={brokerDataTotalsChartRef}
                                   lob={{label: 'Tutte le lob', value: 'ALL'}}/>
            <AnnualGoalChart className={'annual-goal--chart'} chartRef={annualGoalChartRef}
                             lob={{label: 'Tutte le lob', value: 'ALL'}}/>
            <TrendAgencyChart className={'trend-agency--chart'}/>
            <UnderProcessingAgencyNegotiationsStatusChart
                className={'under-processing-agency-negotiations-status--chart'}/>
            <GapToPipelineChart className={'gap-to-pipeline--chart'} chartRef={gapToPipelineChartRef}/>

            <Row className={'monthly-data-header'} style={{marginTop: '1rem'}}>
                <Col xs={{push: 1}} sm={{push: 0}}>
                    <Title level={3}>Dati per mese</Title>
                </Col></Row>
            <NetPremiumOurShareSumChart className={'net-premium-our-share-sum--chart'}
                                        chartRef={netPremiumOurShareSumChartRef}/>
            <AccountNegotiationsCountChart className={'account-negotiations-count--chart'}
                                           chartRef={accountNegotiationsCountChartRef}/>
        </div>
    </React.Fragment>

}