import {Breadcrumb, Card, Col, Divider, Input, List, message, Pagination, Row, Spin} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    useLazyGetDelegatedInsurerQuery,
    useCreateDelegatedInsurerMutation,
    useUpdateDelegatedInsurerMutation
} from "../../redux/api/negotiationUsersApiSlice";
import EditName from "../../components/settings/EditName";
import {useNavigate} from "react-router-dom";
import CreateNewElement from "../../components/settings/CreateNewElement";
import {debounce} from "lodash";
import {LeftOutlined} from "@ant-design/icons";

const pageSize: number = 22;

export default function DelegatedInsurersSettings() {

    const [fetchDelegatedInsurers, {
        data: delegatedInsurersData,
        isLoading: isDelegatedInsurersLoading,
        isFetching: isDelegatedInsurerFetching,
        isError: isDelegatedInsurersError
    }] = useLazyGetDelegatedInsurerQuery()
    const [createDelegatedInsurerRequest, {isLoading: isCreateDelegatedInsurersLoading}] = useCreateDelegatedInsurerMutation()
    const [updateDelegatedInsurerRequest] = useUpdateDelegatedInsurerMutation()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debounceSetSearchTerm = debounce((value: string) => setSearchTerm(value), 600)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isOneItemModifing, setIsOneItemModifing] = useState({status: false, key: ""})

    const navigate = useNavigate()

    function fetchDelegatedInsurerData(searchTerm?: string) {
        try {
            fetchDelegatedInsurers({search: searchTerm}).unwrap()
        } catch (e: any) {
            message.error('Impossibile scaricare le delegatarie')
            console.error('fetchDelegatedInsurerData', e)
        }
    }

    useEffect(() => {
            fetchDelegatedInsurerData()
    }, [])

    useEffect(() => {
        setCurrentPage(1)
        searchTerm.length ? fetchDelegatedInsurerData(searchTerm) : fetchDelegatedInsurerData()
    }, [searchTerm])

    const addDelegatedInsurer = useCallback((newDelegatedInsurerName: string) => {
        createDelegatedInsurerRequest({name: newDelegatedInsurerName})
            .unwrap()
            .then(() => {
                searchTerm.length ? fetchDelegatedInsurerData(searchTerm) : fetchDelegatedInsurerData()
                message.success("Delegataria creata correttamente")
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiunta della delegataria')
                console.error('delegated insurer not created', error)
            })
    }, [createDelegatedInsurerRequest, searchTerm])

    const updateDelegatedInsurer = useCallback((name: string, uuid: string) => {
        return updateDelegatedInsurerRequest({name, uuid})
            .unwrap()
            .then(() => {
                searchTerm.length ? fetchDelegatedInsurerData(searchTerm) : fetchDelegatedInsurerData()
                message.success('Delegataria aggiornata correttamente')
                return true
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiornamento della delegataria')
                console.error('delegated insurer not updated', error)
                return false
            })

    }, [updateDelegatedInsurerRequest, searchTerm])

    return <div style={{padding: '1.5rem 3.75rem', backgroundColor: 'transparent'}}>
        <Card bodyStyle={{minHeight: '83vh', display: 'flex', flexDirection: 'column'}}
              title={<Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/settings')}><span
                      style={{cursor: "pointer"}}><LeftOutlined/> Impostazioni</span></Breadcrumb.Item>
                  <Breadcrumb.Item>Delegatarie</Breadcrumb.Item>
              </Breadcrumb>}
        >
            <Row justify={'space-between'} style={{marginBottom: '1rem'}}>
                <Col style={{width: '20rem'}}><Input.Search placeholder={'Filtra per nome...'} enterButton
                                                          defaultValue={""}
                                                          onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}
                /></Col>
                <Col><CreateNewElement buttonContent={"Crea  delegataria"} placeholder={"Nome delegataria"}
                                       modalTitle={"Creazione delegataria"}
                                       addElement={addDelegatedInsurer}/></Col>
            </Row>
            <Divider/>
            <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            {isDelegatedInsurersLoading ? <Spin/> :
                <List
                    grid={{column: 2, gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2}}
                    loading={!delegatedInsurersData || isDelegatedInsurerFetching || isDelegatedInsurersLoading}
                    dataSource={delegatedInsurersData?.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                    renderItem={item => <List.Item
                        style={{borderBottom: '1px solid rgba(0, 0, 0, 0.06)', paddingBottom: '0.1rem'}}>
                        <EditName id={item.uuid} text={item.name ? item.name : ""}
                                  updateText={(name) => updateDelegatedInsurer(name, item.uuid)}
                                  isOneItemModifing={isOneItemModifing}
                                  setIsOneItemModifing={setIsOneItemModifing}></EditName>
                    </List.Item>
                    }
                />}
            {delegatedInsurersData?.length ?
                <Row style={{marginTop: '2.5rem'}}
                    justify={'end'}><Pagination
                    simple
                    total={delegatedInsurersData.length}
                    current={currentPage}
                    pageSize={pageSize}
                    onChange={page => {
                        setIsOneItemModifing({status: false, key: ""})
                        setCurrentPage(page)
                    }}
                /></Row> : ""}</div>
        </Card>
    </div>
}

