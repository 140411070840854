import { apiSlice } from "redux/api/baseApiSlice";
import {SurveyModel} from "survey-react";

export const checklistApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getChecklist: builder.query<{ jsonSchema: SurveyModel, data: object }, {uuid: string}>({
            query: ({uuid}) => {
                return {
                    url: `negotiation/negotiations/${uuid}/checklist/`,
                }
            },
        }),

        updateChecklist: builder.mutation<{}, {uuid: string, data: object}>({
            query: (args) => {
                return {
                    url: `negotiation/negotiations/${args.uuid}/checklist/`,
                    method: 'POST',
                    body: args.data
                }
            }
        }),
    })
})

export const { useLazyGetChecklistQuery, useUpdateChecklistMutation } = checklistApiSlice