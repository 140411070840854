import {Breadcrumb, Button, Card, Col, Divider, Input, List, message, Modal, Pagination, Row, Spin} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CreateContractorModel} from "../../types/contractors";
import {
    useLazyGetContractorsQuery,
    useCreateContractorMutation,
    useUpdateContractorMutation
} from "../../redux/api/contractorsApiSlice";
import {useNavigate} from "react-router-dom";
import ContractorsItem from "../../components/settings/ContractorsItem";
import {debounce} from "lodash";
import {LeftOutlined, PlusOutlined} from "@ant-design/icons";
import ContractorsForm from "../../components/settings/ContractorsForm";

const pageSize: number = 22;

export default function ContractorsSettings() {

    const [fetchContractors, {
        data: contractorsData,
        isLoading: isContractorLoading,
        isError: isContractorError,
        isFetching: isContractorFetching
    }] = useLazyGetContractorsQuery()
    const [createContractorRequest, {isLoading: isCreateContractorLoading}] = useCreateContractorMutation()
    const [updateContractorRequest] = useUpdateContractorMutation()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debounceSetSearchTerm = debounce((value: string) => setSearchTerm(value), 600)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isOneItemModifing, setIsOneItemModifing] = useState({status: false, key: ""})

    const navigate = useNavigate()

    function fetchContractorsData(_searchTerm?: string, _page?: number, _pageSize?: number) {
        try {
            fetchContractors({search: _searchTerm, page_size: _pageSize, page: _page}).unwrap()
        }
        catch (e: any) {
            message.error('Impossibile scaricare i contraenti')
            console.error('fetchContractorsData', e)
        }
    }

    useEffect(() => {
        searchTerm.length ? fetchContractorsData(searchTerm,currentPage,pageSize) : fetchContractorsData(undefined, currentPage, pageSize)
    }, [currentPage])

    useEffect(() => {
        currentPage === 1 ?
        searchTerm.length ? fetchContractorsData(searchTerm,currentPage,pageSize) : fetchContractorsData(undefined, currentPage, pageSize)
        :  setCurrentPage(1)
    }, [searchTerm])

    const addContractor = useCallback((data: CreateContractorModel) => {
        setIsModalOpen(false)
        createContractorRequest(data)
            .unwrap()
            .then(() => {
                searchTerm.length ? fetchContractorsData(searchTerm,currentPage,pageSize) : fetchContractorsData(undefined, currentPage, pageSize)
                message.success("Contraente creato correttamente")
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiunta del contraente')
                console.error("contractor not created", error)
            })
    }, [createContractorRequest, searchTerm, currentPage])

    const updateContractor = useCallback((uuid: string, data: Partial<CreateContractorModel>) => {
        updateContractorRequest({uuid, data})
            .unwrap()
            .then(() => {
                searchTerm.length ? fetchContractorsData(searchTerm,currentPage,pageSize) : fetchContractorsData(undefined, currentPage, pageSize)
                message.success("Contraente aggiornato correttamente")
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiornamento del contraente')
                console.error("contractor not updated", error)
            })
    }, [updateContractorRequest, searchTerm, currentPage])

    return <div style={{padding: '1.5rem 3.75rem', backgroundColor: 'transparent'}}>
        <Card bodyStyle={{minHeight: '83vh', display: 'flex', flexDirection: 'column'}}
              title={<Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/settings')}><span
                      style={{cursor: "pointer"}}><LeftOutlined/> Impostazioni</span></Breadcrumb.Item>
                  <Breadcrumb.Item>Contraenti</Breadcrumb.Item>
              </Breadcrumb>
              }

        >
            <Row justify={'space-between'} style={{marginBottom: '1rem'}}>
                <Col style={{width: '20%'}}><Input.Search placeholder={'Filtra per nome...'} enterButton
                                                          defaultValue={""}
                                                          onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}
                /></Col>
                <Col><Button icon={<PlusOutlined/>} type={"primary"} onClick={() => setIsModalOpen(true)}>Crea
                    contraente</Button></Col>
            </Row>
            <Divider/>
            <div style={{flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            {isContractorLoading ? <Spin/> :
                <List
                    style={{marginBottom: '5px'}}
                    grid={{column: 2, gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2}}
                    loading={!contractorsData || isContractorLoading || isContractorFetching}
                    dataSource={contractorsData?.results}
                    renderItem={item => <List.Item
                        style={{borderBottom: '1px solid rgba(0, 0, 0, 0.06)', paddingBottom: '0.1rem'}}>
                        <ContractorsItem id={item.uuid} name={item.name}
                                         activityFullDescription={item.activity_full_description}
                                         activity={item.activity}
                                         yearlyRevenues={item.yearly_revenues} vatNumber={item.vat_number}
                                         updateContractors={(values: CreateContractorModel) => updateContractor(item.uuid, values)}
                                         isOneItemModifing={isOneItemModifing}
                                         setIsOneItemModifing={setIsOneItemModifing}
                        ></ContractorsItem>
                    </List.Item>
                    }
                />}
            {contractorsData?.count ?
                <Row justify={'end'}><Pagination
                    simple
                    total={contractorsData.count}
                    current={currentPage}
                    pageSize={pageSize}
                    onChange={page => {
                        setIsOneItemModifing({status: false, key: ""})
                        setCurrentPage(page)
                    }}
                /></Row> : ""}</div>
        </Card>
        <Modal open={isModalOpen} destroyOnClose footer={false} title={"Creazione contraente"}
               onCancel={() => setIsModalOpen(false)}>
            <ContractorsForm isNew={true} name={null}
                             activityFullDescription={null}
                             activity={null}
                             yearlyRevenues={null}
                             vatNumber={null} onsubmit={(values) => addContractor(values)}
                             onClose={() => setIsModalOpen(false)}
            /></Modal>
    </div>
}

