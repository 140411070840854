import {Button, Col, Divider, Modal, Row} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import dayjs from "dayjs";
import DatePicker from "../DatePicker";
import {
    useCreateBudgetMutation,
    useLazyGetBudgetGroupsQuery,
    useLazyGetBudgetsQuery
} from "../../redux/api/budgetsApiSlice";
import GapToPlanForm from "./GapToPlanForm";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {UserTypes} from "../../types";

export default function GapToPlanNegotiationsModal({
                                                       showModalGapToPlanModifier,
                                                       setShowModalGapToPlanModifier
                                                   }: { showModalGapToPlanModifier: boolean, setShowModalGapToPlanModifier: React.Dispatch<React.SetStateAction<boolean>> }) {


    const [triggerGetBudgets, {
        data: budgets,
        isLoading: budgetsIsLoading,
        isFetching: budgetsIsFetching,
    }] = useLazyGetBudgetsQuery()
    const [triggerGetGroupBudgets, {
        data: groupBudgets,
        isLoading: groupBudgetsIsLoading,
        isFetching: groupBudgetsIsFetching
    }] = useLazyGetBudgetGroupsQuery()
    const [selectedYear, setSelectedYear] = useState<dayjs.Dayjs>(dayjs(Date.now()))
    const [createBudget, {isLoading}] = useCreateBudgetMutation()

    const user = useSelector(selectUser)
    const userCode: string = user?.usertypes?.length ? user.usertypes[0].code : ''
    const isAuthorizedUser = userCode === UserTypes.ADMIN || userCode === UserTypes.CM || userCode === UserTypes.LBM

    useEffect(() => {
        triggerGetGroupBudgets()
    }, [triggerGetGroupBudgets])

    useEffect(() => {
            // get budgets
            triggerGetBudgets({uw_year: selectedYear.year()})
        },
        [selectedYear, triggerGetBudgets])

    const budgetsList = useMemo(() => groupBudgets && budgets ? <>{groupBudgets.map(gb => {
        let result: JSX.Element
        const relatedBudget = budgets.find(b => b.budget_group.uuid === gb.uuid)
        if (relatedBudget) {
            result = <GapToPlanForm key={`${gb.uuid}-${selectedYear}`} budgetGroup={gb} budget={relatedBudget}/>
        } else {
            result = <div key={gb.uuid}><Divider> {gb.name} </Divider>
                <Row justify={'space-between'} align={'middle'}>
                    <Col>
                        <p>Nessun gap definito</p>
                    </Col>

                    {isAuthorizedUser && < Col>
                      < Button
                          type={'primary'}
                          // todo optimize creation (redundant call)
                          onClick={() => {
                              createBudget({
                                  budget_group: gb.uuid,
                                  uw_year: selectedYear.year(),
                                  total_goal: 0,
                                  direct_isa_goal: 0,
                                  isia_goal: 0
                              }).then(() => triggerGetBudgets({uw_year: selectedYear.year()}))

                          }}> Crea
                      </Button></Col>}
                </Row>

            </div>
        }
        return result
    })}</> : <></>, [groupBudgets, budgets])


    return <Modal
        title={'Modifica gap to plan'} open={showModalGapToPlanModifier}
        onOk={() => setShowModalGapToPlanModifier(false)}
        onCancel={() => setShowModalGapToPlanModifier(false)}
        footer={false}
        destroyOnClose
    >
        <Row justify={"end"}><Col style={{border: '1px solid #D9D9D9'}}><DatePicker.YearPicker clearIcon={false}
                                                                                               value={selectedYear}
                                                                                               onChange={value => {
                                                                                                   if (value) {
                                                                                                       setSelectedYear(value)
                                                                                                   }
                                                                                               }}
                                                                                               style={{width: '7.5rem'}}
                                                                                               bordered={false}/></Col></Row>
        {budgetsList}
    </Modal>
}