import {Breadcrumb, Card, Col, Divider, Input, List, message, Pagination, Row} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    useLazyGetPaginatedBrokersQuery,
    useCreateBrokerMutation,
    useUpdateBrokerMutation
} from "../../redux/api/brokersApiSlice";
import EditName from "../../components/settings/EditName";
import {useNavigate} from "react-router-dom";
import CreateNewElement from "../../components/settings/CreateNewElement";
import {debounce} from "lodash";
import {LeftOutlined} from "@ant-design/icons";

const pageSize: number = 22;

export default function BrokersSettings() {

    const [fetchBrokers, {
        data: brokersData,
        isLoading: isBrokerLoading,
        isFetching: isBrokerFetching,
        isError: isBrokerError
    }] = useLazyGetPaginatedBrokersQuery()
    const [createBrokerRequest] = useCreateBrokerMutation()
    const [updateBrokerRequest] = useUpdateBrokerMutation()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debounceSetSearchTerm = debounce((value: string) => setSearchTerm(value), 600)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isOneItemModifing, setIsOneItemModifing] = useState({status: false, key: ""})

    const navigate = useNavigate()

    const fetchBrokersData = useCallback((pageParam?: number, searchParam?: string) => {
        try {
            fetchBrokers({search: searchParam, page_size: pageSize, page: pageParam}).unwrap()
        } catch (e: any) {
            message.error('Impossibile scaricare i brokers')
            console.error('fetchBrokersData', e)
        }
    }, [fetchBrokers])


    useEffect(() => {
        searchTerm ? fetchBrokersData(1, searchTerm) : fetchBrokersData()
        setCurrentPage(1)
    }, [fetchBrokersData, searchTerm])

    const addBroker = useCallback((newBrokerName: string) => {
        createBrokerRequest({name: newBrokerName})
            .unwrap()
            .then(() => {
                searchTerm ? fetchBrokersData(currentPage, searchTerm) : fetchBrokersData(currentPage)
                message.success('Broker creato correttamente')
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiunta del broker')
                console.error('broker not created', error)
            })
    }, [createBrokerRequest, currentPage, fetchBrokersData, searchTerm])

    const updateBroker = useCallback((name: string, uuid: string) => {
        return updateBrokerRequest({name, uuid})
            .unwrap()
            .then(() => {
                searchTerm ? fetchBrokersData(currentPage, searchTerm) : fetchBrokersData(currentPage)
                message.success('Broker aggiornato correttamente')
                return true
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiornamento del broker')
                console.error('broker not updated', error)
                return false
            })
    }, [currentPage, fetchBrokersData, searchTerm, updateBrokerRequest])

    return <div style={{padding: '1.5rem 3.75rem', backgroundColor: 'transparent'}}>
        <Card bodyStyle={{minHeight: '83vh', display: 'flex', flexDirection: 'column'}}
              title={<Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/settings')}><span
                      style={{cursor: "pointer"}}><LeftOutlined/> Impostazioni</span></Breadcrumb.Item>
                  <Breadcrumb.Item>Brokers</Breadcrumb.Item>
              </Breadcrumb>}
        >
            <Row justify={'space-between'} style={{marginBottom: '1rem'}}>
                <Col style={{width: '20rem'}}><Input.Search placeholder={'Filtra per nome...'} enterButton
                                                            defaultValue={searchTerm}
                                                            onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}/></Col>
                <Col><CreateNewElement buttonContent={"Crea broker"} placeholder={"Nome broker"}
                                       modalTitle={"Creazione broker"} addElement={addBroker}/></Col>
            </Row>
            <Divider/>
            <div style={{flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                {!!brokersData?.count &&
                    <><List
                        grid={{column: 2, gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2}}
                        loading={!brokersData || isBrokerFetching || isBrokerLoading}
                        dataSource={brokersData?.results}
                        renderItem={(item) => <List.Item
                            style={{borderBottom: '1px solid rgba(0, 0, 0, 0.06)', paddingBottom: '0.1rem'}}>
                            <EditName id={item.uuid} text={item.name ? item.name : ""}
                                      updateText={(name) => updateBroker(name, item.uuid)}
                                      isOneItemModifing={isOneItemModifing}
                                      setIsOneItemModifing={setIsOneItemModifing}/>
                        </List.Item>
                        }
                    />
                      <Row style={{marginTop: '2.5rem'}}
                           justify={'end'}><Pagination
                          simple
                          total={brokersData.count}
                          current={currentPage}
                          pageSize={pageSize}
                          onChange={page => {
                              setIsOneItemModifing({status: false, key: ""})
                              setCurrentPage(page)
                              searchTerm ? fetchBrokersData(page, searchTerm) : fetchBrokersData(page)
                          }}
                      /></Row></>}
                {brokersData?.count === 0 && searchTerm && <p>La ricerca con "<span style={{fontStyle: 'italic'}}>{searchTerm}</span>" come chiave di ricerca non ha prodotto risultati</p>}
                {brokersData?.count === 0 && !searchTerm && <p>Non sono presenti broker</p>}
            </div>
        </Card>
    </div>
}

