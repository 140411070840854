import {Button, Checkbox, Col, Divider, Row, Space} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {generalColumns, getStandardFilter} from "../columnsUtils";
import {ColumnsType} from "antd/lib/table";
import {GeneralNegotiation, States, UserTypes, Views} from "types";
import {InsertRowLeftOutlined} from "@ant-design/icons";
import CustomTable from "../CustomTable";
import {useDispatch, useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import {useLazyGetNegotiationsQuery} from "redux/api/negotiationsApiSlice";
import Tag from "antd/es/tag";
import {selectUser, setTablesPreferences} from "../../../../redux/features/userSlice";
import {useSetUserTablesPreferencesMutation} from "../../../../redux/api/userPreferencesApiSlice";
import ResetFiltersSortersButton from "../../../buttons/ResetFiltersSortersButton";
import {selectNegotiationUsers} from "../../../../redux/features/negotiationUsersSlice";
import ColumnSelectionGeneralTableFilter from "../../../modals/ColumnSelectionGeneralTableFilter";
import {LobModel} from "../../../../types/options";
import {getContrastYIQ} from "../../../../utils/utils";

const GeneralTable = () => {
    const [showColumnsSelection, setShowColumnsSelections] = useState<boolean>(false)
    const [defaultColumns, setDefaultColumns] = useState<ColumnsType<GeneralNegotiation>>(generalColumns)
    const [currentColumns, setCurrentColumns] = useState<ColumnsType<GeneralNegotiation>>([])
    const [columnsSelected, setColumnsSelected] = useState<string[]>([])
    const options = useSelector(selectOptions)

    const negotiationUsers = useSelector(selectNegotiationUsers)
    const [triggerGetNegotiations, {data: negotiations, isLoading, isFetching}] = useLazyGetNegotiationsQuery()

    const user = useSelector(selectUser)
    const userCode: string = user?.usertypes?.length ? user.usertypes[0].code : ''
    const isOtherViewsAvailable: boolean = userCode === UserTypes.UW || userCode === UserTypes.UWJ || userCode === UserTypes.UWS || userCode === UserTypes.LBM || userCode === UserTypes.CM || userCode === UserTypes.ADMIN
    const dispatch = useDispatch()
    const [updatePreferences] = useSetUserTablesPreferencesMutation()

    useEffect(() => {
        setDefaultColumns(prevState => prevState.map(el => {
            switch (el.key) {
                case 'state':
                    return {
                        ...el,
                        ...getStandardFilter(options.states.filter(el => el.value !== States.ReferralApproved)),
                        render: (value: number) => {

                            const foundState = options.states.find(el => el.value === value)
                            if (foundState) {
                                return <Tag
                                    style={{
                                        borderRadius: '100px',
                                        width: '7rem',
                                        height: '1.25rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 0.5rem',
                                        color: getContrastYIQ(foundState.color)
                                    }}
                                    color={foundState.color}
                                >
                                    {foundState.text}
                                </Tag>
                            }
                        }
                    }
                case 'lob':
                    return {
                        ...el,
                        render: (lob: LobModel) => {
                            const foundLob = options.lobs.find(el => el.uuid === lob.uuid)
                            if (foundLob) {
                                return <Tag
                                    style={{
                                        borderRadius: '100px',
                                        width: '7rem',
                                        height: '1.25rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 0.5rem',
                                        color: getContrastYIQ(foundLob.color)
                                    }}
                                    color={foundLob.color}
                                >
                                    {foundLob.name}
                                </Tag>
                            }
                        },
                        ...getStandardFilter(options.lobs.map(el => ({text: el.name, value: el.uuid})))
                    }
                case 'underwriter':
                    return {
                        ...el, ...getStandardFilter(options.underwriters.map(el => ({
                            text: el.user_full_name,
                            value: el.uuid
                        })))
                    }
                case 'installment_type':
                    return {
                        ...el, ...getStandardFilter(options.installmentTypes.map(el => ({
                            text: el.text,
                            value: el.value
                        })))
                    }
                case 'atecori':
                    return {
                        ...el, ...getStandardFilter(options.atecori.map(el => ({
                            text: el.full_description,
                            value: el.nace
                        })))
                    }
                case 'cancellation_terms_days':
                    return {...el, ...getStandardFilter(options.cancellationDays)}
                case 'isia_type':
                    return {...el, ...getStandardFilter(options.isiaTypes)}
                case 'reinsurance_type':
                    return {
                        ...el,
                        ...getStandardFilter(options.reinsuranceTypes),
                        render: (value: string) => {
                            if (value === 'T' || value === 'S' || value === 'F') {
                                const color = value === 'T' ? 'green' : value === 'S' ? 'blue' : 'red'
                                return <Tag color={color}>{value}</Tag>
                            }
                        }
                    }
                //todo add reinsurers_list filter - sorters
                // case 'reinsurers_list':
                //     return {...el, filters: reinsurers.map(el => ({text: el.name, value: el.uuid}))}
                //tmp
                case 'reinsurers_list':
                    return {...el, sorter: false}
                case 'isia_sale':
                    return {
                        ...el,
                        ...getStandardFilter(negotiationUsers.filter(el => el.usertypes.some(usertype => usertype.code === 'AA' || usertype.code === 'AM')).map(el => ({
                            text: el.user_full_name,
                            value: el.uuid
                        })))
                    }
                default:
                    return el
            }
        }))
        // }, [options, brokers, reinsurerBrokers, reinsurers])
    }, [options, negotiationUsers])

    useEffect(() => {
        setColumnsSelected(currentColumns.map(col => col.key as string))
    }, [currentColumns])

    const addRemoveColumnButton = useMemo(() => (title: 'uw_year' | 'lob' | 'policy_effective_date' | 'is_isia' | 'reinsurers_list') => {
        return <Button
            disabled={columnsSelected.length < 2 && columnsSelected.includes(title)}
            onClick={() => {
                let preferredCols: string[] = currentColumns.map(el => el.key as string)
                if (preferredCols.includes(title)) {
                    preferredCols = preferredCols.filter(el => el !== title)
                    const colToRemoveSorter = user.preferences.tables.general.sorters?.find(el => el.key === title)
                    const colToRemoveFilter = user.preferences.tables.general.filters?.find(el => el.key === title)
                    if (colToRemoveFilter || colToRemoveSorter) {
                        updatePreferences({
                            general: {
                                sorters: user.preferences.tables.general.sorters ? user.preferences.tables.general.sorters.filter(el => el.key !== title) : [],
                                filters: user.preferences.tables.general.filters ? user.preferences.tables.general.filters.filter(el => el.key !== title) : []
                            }
                        })
                    }
                } else {
                    preferredCols.push(title)
                }
                dispatch(setTablesPreferences({[Views[Views.general]]: {selected_columns: preferredCols}}))
                updatePreferences({[Views[Views.general]]: {selected_columns: preferredCols}})
            }}
            type={currentColumns.find(col => col.key === title) ? 'primary' : undefined}
        >
            {defaultColumns.find(c => c.key === title)?.title as string}
        </Button>
    }, [columnsSelected, currentColumns, defaultColumns, dispatch, updatePreferences, user.preferences.tables.general.filters, user.preferences.tables.general.sorters])

    return <>
        <ColumnSelectionGeneralTableFilter
            currentColumns={currentColumns}
            setShowColumnsSelections={setShowColumnsSelections}
            showColumnsSelection={showColumnsSelection}
            defaultColumns={defaultColumns}
            columnsSelected={columnsSelected}
            setColumnsSelected={setColumnsSelected}
        />

        <Col xs={{order: 4}} sm={{order: 4}} lg={{order: 2}} flex={1}>
            {isOtherViewsAvailable && <Divider type={'vertical'}/>}
            <Space wrap={true}>
                {addRemoveColumnButton('uw_year')}
                {addRemoveColumnButton('lob')}
                {addRemoveColumnButton('policy_effective_date')}
                {addRemoveColumnButton('is_isia')}
                {addRemoveColumnButton('reinsurers_list')}
            </Space>
            <ResetFiltersSortersButton view={Views.general}/>
            <Button size={'large'} icon={<InsertRowLeftOutlined/>}
                    style={{borderColor: 'transparent', backgroundColor: 'transparent', boxShadow: "none"}}
                    onClick={() => setShowColumnsSelections(true)}/>

        </Col>
        <Col order={4} span={24}>
            <CustomTable view={Views.general} cols={currentColumns}
                         defaultCols={defaultColumns}
                         setCols={setCurrentColumns} data={negotiations} getData={triggerGetNegotiations}
                         isLoading={isLoading || isFetching}
            />
        </Col>
    </>
}

export default GeneralTable