import {Table, TablePaginationConfig} from "antd";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/lib/table";
import {SorterResult} from 'antd/lib/table/interface';
import _, { cloneDeep} from "lodash";

import {
    TableFilter,
    GeneralNegotiation,
    RenewsNegotiation,
    RobotReaderNegotiation, TableSorter,
    Views
} from "../../../types";
import {NegotiationModel} from "types/negotiations";
import {QueryNegotiationsParam} from "redux/api/negotiationsApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setTablesPreferences} from "redux/features/userSlice";
import {useSetUserTablesPreferencesMutation} from "redux/api/userPreferencesApiSlice";
import {euro, isNegotiationExpired} from "utils/utils";
import dayjs from "dayjs";

const CustomTable = (props: {
    defaultCols?: ColumnsType<any>,
    cols: ColumnsType<any>,
    setCols: Dispatch<SetStateAction<ColumnsType<any>>>,
    view: Views,
    data: { count: number, premiums: number | null, results: NegotiationModel[] } | undefined,
    getData: (options: QueryNegotiationsParam | void) => void,
    isLoading: boolean,
}) => {

    const navigate = useNavigate()
    const {pathname} = useLocation()
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} trattative`,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        total: props.data?.count
    })
    const [apiOptions, setApiOptions] = useState<QueryNegotiationsParam>({})

    const [updatePreferences] = useSetUserTablesPreferencesMutation()
    const filterPreferences = user && user.preferences && user.preferences.tables && user.preferences.tables[Views[props.view]] && user.preferences.tables[Views[props.view]].filters ? user.preferences.tables[Views[props.view]].filters as TableFilter[] : [] as TableFilter[]
    const sorterPreferences = user && user.preferences && user.preferences.tables && user.preferences.tables[Views[props.view]] && user.preferences.tables[Views[props.view]].sorters ? user.preferences.tables[Views[props.view]].sorters as TableSorter[] : [] as TableSorter[]

    useEffect(() => {
        setPagination({current: 1})
        let options: QueryNegotiationsParam = {pagination: {page: 1, pageSize: pagination.pageSize}}
        if (filterPreferences && sorterPreferences) {
            if (filterPreferences.length) {
                let updatedFilters = cloneDeep(filterPreferences)

                let contractorFilter = filterPreferences.find(el => el.key === 'contractor')
                if (contractorFilter?.range.length) {
                    updatedFilters = [...updatedFilters.filter(el => el.key !== 'contractor'), {key: 'contractor', range: contractorFilter.range.map(el => (el as {label: string, value: string}).value)}]
                }

                let brokerFilter = filterPreferences.find(el => el.key === 'broker')
                if (brokerFilter?.range.length) {
                    updatedFilters = [...updatedFilters.filter(el => el.key !== 'broker'), {key: 'broker', range: brokerFilter.range.map(el => (el as {label: string, value: string}).value)}]
                }

                let reinsurerBrokerFilter = filterPreferences.find(el => el.key === 'reinsurer_broker')
                if (reinsurerBrokerFilter?.range.length) {
                    updatedFilters = [...updatedFilters.filter(el => el.key !== 'reinsurer_broker'), {key: 'reinsurer_broker', range: reinsurerBrokerFilter.range.map(el => (el as {label: string, value: string}).value)}]
                }

                let delegatedInsurerFilter = filterPreferences.find(el => el.key === 'delegated_insurer')
                if (delegatedInsurerFilter?.range.length) {
                    updatedFilters = [...updatedFilters.filter(el => el.key !== 'delegated_insurer'), {key: 'delegated_insurer', range: delegatedInsurerFilter.range.map(el => (el as {label: string, value: string}).value)}]
                }

                options = {...options, filters: updatedFilters }


            }
            if (sorterPreferences.length) {
                options = {...options, sorters: sorterPreferences}
            }
            setApiOptions(options)
            props.getData(options)
        }
    }, [filterPreferences, sorterPreferences])

    useEffect(() => {
        let updatedCols: ColumnsType<any>

        if (props.defaultCols) {
            updatedCols = props.defaultCols.filter(el => user.preferences.tables.general.selected_columns?.includes(el.key as string)).map(el => {
                el.filteredValue = []
                el.sortOrder = undefined
                return el
            })
        } else {
            updatedCols = [...props.cols].map(el => {
                el.filteredValue = []
                el.sortOrder = undefined
                return el
            })
        }

        if (filterPreferences.length) {
            for (let i = 0; i < filterPreferences.length; i++) {
                const col = updatedCols.find(el => el.key === filterPreferences[i].key)
                if (col) {
                    col.filteredValue = filterPreferences[i].range as string[]
                }
            }
        }

        if (sorterPreferences.length) {
            for (let i = 0; i < sorterPreferences.length; i++) {
                const col = updatedCols.find(el => el.key === sorterPreferences[i].key)
                if (col) {
                    col.sortOrder = sorterPreferences[i].type
                }
            }
        }

        props.setCols(updatedCols)

    }, [props.defaultCols, filterPreferences, sorterPreferences, user.preferences.tables.general.selected_columns])

    useEffect(()=> {

    }, [pagination])

    const premiumColumnIndex = props.cols.findIndex((col)=> col.title === 'Premio')

    return <Table
        <RenewsNegotiation | RobotReaderNegotiation | GeneralNegotiation>
        className={"negotiation-table"}
        style={{marginTop: "1rem"}}
        loading={props.isLoading}
        columns={props.cols}
        dataSource={props.data ? props.data.results.map(el => ({key: el.uuid, ...el})) : []}
        rowClassName={record => isNegotiationExpired(record.state, dayjs(record.policy_effective_date)) ? "table__negotiation-expired-row" : ""}
        pagination={{...pagination, total: props?.data?.count}}
        locale={{
            filterConfirm: 'Applica filtro',
            filterReset: 'Reset filtro',
        }}
        summary={() => {
            return (premiumColumnIndex !== -1 && typeof props.data?.premiums === 'number' && props.view === Views.general?
                <Table.Summary fixed>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}><b>Totale Premi</b></Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={premiumColumnIndex-1}></Table.Summary.Cell>
                        <Table.Summary.Cell align={'right'} index={premiumColumnIndex}><b>{euro(props.data?.premiums)}</b></Table.Summary.Cell>
                    </Table.Summary.Row>
                </Table.Summary> : null)
        }}
        onChange={(pag, fil, sor: SorterResult<any> | SorterResult<any>[], extra) => {
            switch (extra.action) {
                case "paginate":
                    setPagination(prevState => ({...prevState, current: pag.current, pageSize: pag.pageSize, total: pag.total}))
                    props.getData({...apiOptions, pagination: {page: pag.current, pageSize: pag.pageSize}})
                    break
                case "filter":
                    let f: TableFilter[] = []
                    for (let key of Object.keys(fil)) {
                        if (fil[key]) {
                            f.push({key: key, range: fil[key] as string[] | number[]})
                        } else {
                            f.push({key: key, range: []})
                        }
                    }
                    const filters = f.filter(el => el.range.length > 0)
                    if (!_.isEqual(filters, filterPreferences)) {
                        dispatch(setTablesPreferences({[Views[props.view]]: {filters}}))
                        updatePreferences({[Views[props.view]]: {filters: f.filter(el => el.range.length > 0)}})
                    }

                    break
                case "sort":
                    let s: TableSorter[] = []
                    if (Array.isArray(sor)) {
                        s = sor.map(el => ({
                            key: el.columnKey as string,
                            type: el.order as 'ascend' | 'descend'
                        }))

                    } else {
                        if (sor.order === undefined) {
                            s = []
                        } else {
                            s = [{
                                key: sor.columnKey as string,
                                type: sor.order as 'ascend' | 'descend'
                            }]
                        }

                    }
                    dispatch(setTablesPreferences({[Views[props.view]]: {sorters: s}}))
                    updatePreferences({[Views[props.view]]: {sorters: s}})
                    break
            }
        }}
        onRow={(record) => {
            return {
                onClick: () => {
                    navigate(record.uuid, {state: {previous_path: pathname}})
                },
            };
        }}
        scroll={{ scrollToFirstRowOnChange: true, x: true}}
    />
}

export default CustomTable