import { useParams } from "react-router-dom";
import {useEffect, useState} from 'react';

import {Col, Row, Card, Tag, Form, Input, Radio, Button, Select, InputNumber, Divider, Space, message} from 'antd';

import { Breadcrumb, Layout, Menu, Typography } from 'antd';



import { useTranslation } from 'react-i18next';
import { CommonFormComponentProps } from "types/negotiations/components";
import { useSelector } from "react-redux";
import { selectOptions } from "redux/features/optionsSlice";
import { ProfileOutlined, PlusOutlined } from '@ant-design/icons';
import { DebounceSelect } from "components/debounceSelect";
import { useCreateDelegatedInsurerMutation, useLazyGetDelegatedInsurerQuery } from "redux/api/negotiationUsersApiSlice";
import { AntCurrencyFormatInput } from "components/inputNumber";
import { rnfToNumber } from "utils/utils";
import { optionsApiSlice } from "redux/api/optionsApiSlice";
import {DelegatedInsurerSelect} from "../selects";
import {CreateContractorModel} from "../../../types/contractors";
const { Header, Content, Footer, Sider } = Layout;
const { Title, Paragraph } = Typography
const { Option } = Select

export default function Negotiation({ forwaredRef, formInstance, debounceInputChange, onFormValuesChange, disabled, setCalculateField, getCalculatedField }: CommonFormComponentProps) {

    const { cancellationDays, lobs, policy_types } = useSelector(selectOptions)
    const watchIsAutoRenewal = Form.useWatch('is_auto_renewal', formInstance)
    const currentLobId = formInstance.getFieldValue('lob')
    const currentLob = lobs.find(lob => lob.uuid === currentLobId)
    //anche infortuni
    const isPropertyLob = currentLob && ['property'].includes(currentLob.name.toLowerCase())
    return (
        <Card ref={forwaredRef} id="negotiation" bordered={false} style={{
            width: '100%'
        }}>
            <Row style={{paddingBottom:'24px'}}>
                <Col>
                    <Title level={3}>
                        <ProfileOutlined />
                    </Title>
                </Col>
                <Col style={{ marginLeft: '12px' }}>
                    <Title level={3}>Dati trattativa</Title>
                </Col>
            </Row>

            <Form
                form={formInstance}
                disabled={disabled}
                name='negotiation'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
            >
                <DelegatedInsurerSelect fieldName='delegated_insurer' label='Delegataria'
                                        onCreation={(newDelegatedInsurer: string) => {
                                            formInstance.setFieldsValue({delegated_insurer: newDelegatedInsurer})
                                            debounceInputChange({delegated_insurer: newDelegatedInsurer})
                                        }}
                />
                <Form.Item label="Tipo polizza" name='policy_product_type' required>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder="Seleziona il tipo polizza"
                        optionFilterProp="children"
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {policy_types.map(policy => <Option key={policy.value} value={policy.value}>{policy.text}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Tacito rinnovo" name='is_auto_renewal' required >
                    <Radio.Group>
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row align='middle' justify='space-between'>
                    {/* <Col style={{ width: '48%' }}>
                    <Form.Item label="Tipo termini di disdetta" name='cancellation_terms_type' required >
                        <Select
                            virtual={false}
                            showSearch
                            placeholder="Seleziona il tipo di termine di disdetta"
                            optionFilterProp="children"
                            onChange={(value) => debounceInputChange({ 'cancellation_terms_type': value })}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                            }
                        >
                            {cancellationTypes.map(type => <Option key={type.value} value={type.value}>{type.text}</Option>)}
                        </Select>
                    </Form.Item>
                </Col> */}
                    <Col style={{ width: '100%' }}>
                        {watchIsAutoRenewal &&
                            <Form.Item label="Termini di disdetta" name='cancellation_terms_days' required >
                                <Select
                                    virtual={false}
                                    showSearch
                                    placeholder="Seleziona il termine di disdetta"
                                    optionFilterProp="children"
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                                    }
                                >
                                    {cancellationDays.map(ct => <Option key={ct.value} value={ct.value}>{ct.text}</Option>)}
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>

                <Row align='middle' justify='space-between'>
                    {isPropertyLob &&
                        <Col style={{ width: '48%' }}>
                            <Form.Item label="TIV" name='tiv' required  >
                                <AntCurrencyFormatInput />
                            </Form.Item>
                        </Col>
                    }
                    {isPropertyLob &&
                        <Col style={{ width: '48%' }}>
                            <Form.Item label="Somma assicurata / Limiti 100% (SL)" name='insured_sum' required >
                                <AntCurrencyFormatInput />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Form.Item label="Tipo premio" name='is_new_business' required >
                    <Radio.Group>
                        <Radio value={true}>New business</Radio>
                        <Radio value={false}>Renewal</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Premio di riferimento al 100%" name='reference_premium' required  >
                    <AntCurrencyFormatInput disabled={getCalculatedField('reference_premium')}
                                    calculate={() => setCalculateField('reference_premium')}/>
                </Form.Item>
                <Form.Item label="Accessori" name='ancillary_charges' required  >
                    <AntCurrencyFormatInput />
                </Form.Item>

                { (formInstance.getFieldValue('delegated_insurer')?.label === "ISA" && !(formInstance.getFieldValue('isa_share_perc') === 100)) && <Form.Item label="Commissioni di ristoro" name='refreshment_commissions' required>
                    <AntCurrencyFormatInput suffix={' %'}/>
                </Form.Item>}
            </Form>
        </Card >

    )
}