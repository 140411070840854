import React, {useState} from "react";
import {Button, Col, Divider, Form, Input, Row} from "antd";
import {DebounceSelect} from "../debounceSelect";
import {useLazyGetAtecoCodesQuery} from "../../redux/api/contractorsApiSlice";
import {ContractorDataFormModel, CreateContractorModel} from "../../types/contractors";
import {AntCurrencyFormatInput} from "../inputNumber";
import {useForm} from "antd/lib/form/Form";

export default function ContractorsForm({
                                            isNew,
                                            name,
                                            activityFullDescription,
                                            activity,
                                            yearlyRevenues,
                                            vatNumber,
                                            onsubmit,
                                            onClose
                                        }: { isNew: boolean, name: string | null, activityFullDescription: string | null, activity: string | null, yearlyRevenues: number | null, vatNumber: string | null, onsubmit: (values: CreateContractorModel) => void, onClose: () => void }) {

    const [fetchAtecos, {isLoading: isAtecosLoading}] = useLazyGetAtecoCodesQuery()
    const [isValuesChanged, setIsValuesChanged] = useState<boolean>(false)
    const [form] = useForm()

    async function searchAtecos(text: string): Promise<{ label: string, value: string }[]> {
        try {
            const atecosSearchResult = await fetchAtecos({search: text}).unwrap()
            return atecosSearchResult.results.map(ateco => (
                {label: ateco.full_description || ateco.uuid, value: ateco.uuid}
            ))
        } catch (e: any) {
            console.error('Ateco search', e)
            return []
        }
    }

    function handleSubmit(values: ContractorDataFormModel) {
        if (isValuesChanged && values?.name && values.name.trim() !== "") {
                form.resetFields()
                const parsedValues: CreateContractorModel = {...values, activity: values?.activity?.value}
                onsubmit(parsedValues)
            }
    }

    return <Form
        form={form}
        initialValues={{
            name: name,
            yearly_revenues: yearlyRevenues,
            vat_number: vatNumber,
            activity: {value: activity, label: activityFullDescription}
        }}
        disabled={false}
        name='contractorData'
        layout="vertical"
        requiredMark={true}
        onFinish={(values) => handleSubmit(values)}
        onFieldsChange={(changedFields, allFields) => {
            setIsValuesChanged(allFields[0]?.value.trim() !== "" )
        }}
    >
        <Row align='middle'>
            <Col style={{width: '48%'}}>
                <Form.Item label="Nome contraente" required name='name'>
                    <Input disabled={false} placeholder="Nome contraente"></Input>
                </Form.Item>
            </Col>
        </Row>
        <Row align='middle' justify='space-between'>
            <Col style={{width: '48%'}}>
                <Form.Item label="Fatturato annuo" name='yearly_revenues'>
                    <AntCurrencyFormatInput></AntCurrencyFormatInput>
                </Form.Item>
            </Col>
            <Col style={{width: '48%'}}>
                <Form.Item label="Partita IVA" name={'vat_number'}>
                    <Input disabled={false} placeholder="Partita IVA" maxLength={16}/>
                </Form.Item>
            </Col>
        </Row>
        <Form.Item label="Descrizione attività" name={'activity'}>
            <DebounceSelect
                disabled={false}
                virtual={false}
                showSearch
                placeholder="Comincia a digitare per cercare un attività.. (nome o ateco) "
                fetchOptions={searchAtecos}
                style={{width: '100%'}}
            />
        </Form.Item>
        <Row style={{marginBottom: '1.5rem'}}><p style={{fontStyle: 'italic'}}>I campi contrassegnati da <span
            style={{color: '#ff4d4f'}}>*</span> sono obbligatori</p></Row>
        <Divider/>
        <Row justify={'space-between'}>
            <Col><Button type={"default"} onClick={() => onClose()}>Annulla</Button></Col>
            <Col><Button htmlType={"submit"} disabled={!isValuesChanged}
                         type={'primary'}>{isNew ? "Crea Nuovo Contraente" : "Salva Modifiche"}</Button></Col>
        </Row>
    </Form>
}