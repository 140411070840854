import dayjs from "dayjs"
import {NegotiationFormModel, NegotiationModel, UpdateNegotiationRequest} from "types/negotiations"
import {cloneDeep, isNil} from "lodash"
import {normalizePercTo1} from "./dataParser"
import {LobModel} from "types/options"
import {roundNumber} from "utils/utils"

//https://riskapp-it.atlassian.net/browse/ISATRA-295
export function calculateSubmissionFormSideEffects(changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) {
    let updatedValues = cloneDeep(values)
    if (values.policy_effective_date && !values.uw_year)
        updatedValues.uw_year = values.policy_effective_date
    if (values.policy_expiring_date && values.policy_effective_date && values.policy_expiring_date.isBefore(values.policy_effective_date))
        updatedValues.policy_expiring_date = null
    if (changedValues.lob)
        updatedValues = {...updatedValues, underwriter: null, attorney_from: null, product_type: null, branches: {}}
    if (changedValues.underwriter)
        updatedValues.attorney_from = null
    if (changedValues.is_isia === false)
        updatedValues = {...updatedValues, isia_type: null, isia_sale: null, fee_agency_perc: null}

    if (changedValues.is_calculated?.fee_total_perc || ((changedValues.fee_agency_perc || changedValues.fee_broker_perc) && values.is_calculated.fee_total_perc) ) {
        updatedValues.fee_total_perc = (updatedValues.fee_agency_perc || 0) + (updatedValues.fee_broker_perc || 0)
    }

    // if (changedValues.hasOwnProperty('quoted_premium'))
    if (changedValues.is_calculated?.isa_quoted_premium_share || ((changedValues.isa_share_perc || changedValues.quoted_premium) && values.is_calculated.isa_quoted_premium_share) ) {
        updatedValues.isa_quoted_premium_share = +((updatedValues.isa_share_perc || 0) * normalizePercTo1(updatedValues.quoted_premium || 0)).toFixed(2)
    }

    if (changedValues.is_calculated) {
        updatedValues.is_calculated = changedValues.is_calculated
    }

    return updatedValues

}


//https://riskapp-it.atlassian.net/browse/ISATRA-297
export function calculateReinsuranceFormSideEffects(changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) {
    const updatedValues = cloneDeep(values)
    if (values.reinsurance_type === 'T')
        return {
            ...updatedValues,
            reinsurer_broker: null,
            reinsured_share_perc: 0,
            reinsurer_broker_fee_perc: 0,
            isa_net_retention: 0,
            isa_fee: 0,
            fix_reinsurance_premium: 0,
            isa_reinsurance_net_premium_direct_and_fee: 0,
            saved_premium: 0,
            saved_plus_ceeding_fee: 0,
            attachment_point: 0,
            reinsurance_notes: ''
        }

    const reinsuredSharePerc = normalizePercTo1(updatedValues.reinsured_share_perc || 0)
    const reinsurerBrokerFeePerc = normalizePercTo1(updatedValues.reinsurer_broker_fee_perc || 0)
    const feeBrokerPerc = normalizePercTo1(updatedValues.fee_broker_perc || 0)

    if (changedValues.is_calculated?.isa_reinsurance_net_premium_direct_and_fee || values.is_calculated.isa_reinsurance_net_premium_direct_and_fee) {
        if (!updatedValues.fix_reinsurance_premium)
            updatedValues.isa_reinsurance_net_premium_direct_and_fee = roundNumber((updatedValues.isa_quoted_premium_share || 0) * reinsuredSharePerc * (1 - reinsurerBrokerFeePerc - feeBrokerPerc))
        else
            updatedValues.isa_reinsurance_net_premium_direct_and_fee = roundNumber(updatedValues.fix_reinsurance_premium)
    }

    if (changedValues.is_calculated?.isa_net_retention || values.is_calculated.isa_net_retention) {
        updatedValues.isa_net_retention = roundNumber((updatedValues.isa_reinsurance_net_premium_direct_and_fee || 0) * (1 - reinsuredSharePerc))
    }

    if (changedValues.is_calculated?.isa_fee || values.is_calculated.isa_fee) {
        updatedValues.isa_fee = roundNumber(((updatedValues.isa_reinsurance_net_premium_direct_and_fee || 0) * reinsurerBrokerFeePerc) / (1 - reinsurerBrokerFeePerc - feeBrokerPerc))
    }

    if (changedValues.is_calculated?.saved_premium || values.is_calculated.saved_premium) {
        updatedValues.saved_premium = roundNumber((updatedValues.isa_reinsurance_net_premium_direct_and_fee || 0) * (1 - reinsuredSharePerc))
    }

    if (changedValues.is_calculated?.saved_plus_ceeding_fee || values.is_calculated.saved_plus_ceeding_fee) {
        updatedValues.saved_plus_ceeding_fee = roundNumber((updatedValues.saved_premium || 0) + (updatedValues.isa_fee || 0))
    }


    if (changedValues.is_calculated) {
        updatedValues.is_calculated = changedValues.is_calculated
    }

    return updatedValues
}

//https://riskapp-it.atlassian.net/browse/ISATRA-298
export function calculateNegotiationFormSideEffect(changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel, lob: LobModel | undefined, firstDelegate: { label: string, value: string } | null) {

    let updatedValues = cloneDeep(values)
    if (updatedValues.is_auto_renewal === false)
        updatedValues.cancellation_terms_days = null
    if (changedValues.isa_share_perc && changedValues.isa_share_perc >= 100 && firstDelegate) {
        updatedValues.delegated_insurer = firstDelegate.value
    }
    if (!lob || !(['property'].includes(lob.name.toLowerCase()))) {
        updatedValues.tiv = 0
        updatedValues.insured_sum = 0
    }
    if (changedValues.is_calculated?.reference_premium || values.is_calculated.reference_premium) {
        updatedValues.reference_premium = values.quoted_premium
    }
    if (changedValues.is_calculated?.regulation_premium || values.is_calculated.regulation_premium) {
        updatedValues.regulation_premium = values.quoted_premium
    }
    return updatedValues

}

//https://riskapp-it.atlassian.net/browse/ISATRA-298
export function calculatePortfolioFormSideEffect(negotiation: NegotiationModel | undefined, changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) {
    let updatedValues = cloneDeep(values)
    if (changedValues.product_type) { //set defaultBranches
        const branches = negotiation && negotiation.lob.products.find(product => product.product === changedValues.product_type)?.branches
        const defaultBranches: { [key: number | string]: number } = {}
        if (branches) {
            for (const key in branches)
                defaultBranches[key] = branches[key as any].default*100

        }
        // branches && Object.keys(branches).forEach((key: number) => defaultBranches[key] = branches[key].default)
        return {...updatedValues, branches: defaultBranches}
    }
    return updatedValues

}

export function calculatePoliciesAndIssuesSideEffects(changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) {
    let updatedValues = cloneDeep(values)
    if (changedValues.is_framework_agreement === true)
        return {
            ...updatedValues,
            installment_type: null,
            installments_number: null,
            policy_premium: null,
            policy_insured_sum: null
        }
    if (changedValues.is_framework_agreement === false)
        return {...updatedValues, framework_agreement: null, other_policies: []}
    if (changedValues.is_calculated?.policy_premium || values.is_calculated.policy_premium) {
        updatedValues.policy_premium = values.quoted_premium
    }
    if (changedValues.has_regulation_premium || changedValues.regulation_premium)
        updatedValues.regulation_premium = values.regulation_premium
    else
        updatedValues.regulation_premium = null

    return updatedValues

}

