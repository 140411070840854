import {ConfigProvider, DatePickerProps} from "antd";
import itIT from "antd/es/locale/it_IT";
import DatePicker from "../DatePicker";
import dayjs from "dayjs";
import {PickerDateProps} from "antd/es/date-picker/generatePicker";
import {CommonPickerMethods} from "antd/es/date-picker/generatePicker/interface";
import {Component, Dispatch, SetStateAction} from "react";
import {capitalizeFirstLetter} from "../../utils/utils";

export default function SelectMonth({
                                        selectProps,
                                        month,
                                        setMonth,
                                    }: { selectProps?: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<Component<Omit<PickerDateProps<dayjs.Dayjs>, "picker">, unknown, any> & CommonPickerMethods> & Readonly<Omit<PickerDateProps<dayjs.Dayjs>, "picker">>, month: dayjs.Dayjs, setMonth: Dispatch<SetStateAction<dayjs.Dayjs>> }) {
    return <ConfigProvider locale={itIT} key={'month-picker'}>
        <DatePicker.MonthPicker
            style={{width: '9rem'}}
            value={month}
            onSelect={(value) => {
                    setMonth(value)
            }}
            format={'MMMM YYYY'}
            {...selectProps} />
    </ConfigProvider>
}
