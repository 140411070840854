import {Breadcrumb, Card, Col, Divider, Input, List, message, Pagination, Row, Spin} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    useLazyGetPaginatedReinsuresQuery,
    useCreateReinsurerMutation,
    useUpdateReinsurerInSettingsMutation
} from "../../redux/api/reinsurersApiSlice";
import EditName from "../../components/settings/EditName";
import {useNavigate} from "react-router-dom";
import CreateNewElement from "../../components/settings/CreateNewElement";
import {debounce} from "lodash";
import {LeftOutlined} from "@ant-design/icons";

const pageSize: number = 22;

export default function ReinsurersSettings() {

    const [fetchReinsurers, {
        data: reinsurersData,
        isLoading: isReinsurerLoading,
        isFetching: isReinsurerFetching,
        isError: isReinsurerError
    }] = useLazyGetPaginatedReinsuresQuery()
    const [createReinsurerRequest] = useCreateReinsurerMutation()
    const [updateReinsurerRequest] = useUpdateReinsurerInSettingsMutation()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debounceSetSearchTerm = debounce((value: string) => setSearchTerm(value), 600)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isOneItemModifing, setIsOneItemModifing] = useState({status: false, key: ""})

    const navigate = useNavigate()

    const fetchReinsurerData = useCallback((pageParam?: number, searchParam?: string) => {
        try {
            fetchReinsurers({search: searchParam, page_size: pageSize, page: pageParam}).unwrap()
        } catch (e: any) {
            message.error('Impossibile scaricare i brokers')
            console.error('fetchBrokersData', e)
        }
    }, [fetchReinsurers])

    useEffect(() => {
        searchTerm ? fetchReinsurerData(1, searchTerm) : fetchReinsurerData()
        setCurrentPage(1)
    }, [fetchReinsurerData, searchTerm])

    const addReinsurers = useCallback((newReinsurerName: string) => {
        createReinsurerRequest({name: newReinsurerName})
            .unwrap()
            .then(() => {
                searchTerm ? fetchReinsurerData(currentPage, searchTerm) : fetchReinsurerData(currentPage)
                message.success('Riassicuratore creato correttamente')
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiunta del riassicuratore')
                console.error('reinsurer not created', error)
            })
    }, [createReinsurerRequest, currentPage, fetchReinsurerData, searchTerm])

    const updateReinsurers = useCallback((name: string, uuid: string) => {
        return updateReinsurerRequest({name, uuid})
            .unwrap()
            .then(() => {
                searchTerm ? fetchReinsurerData(currentPage, searchTerm) : fetchReinsurerData(currentPage)
                message.success('Riassicuratore aggiornato correttamente')
                return true
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiornamento del riassicuratore')
                console.error('reinsurer not updated', error)
                return false
            })
    }, [currentPage, fetchReinsurerData, searchTerm, updateReinsurerRequest])

    return <div style={{padding: '1.5rem 3.75rem', backgroundColor: 'transparent'}}>
        <Card bodyStyle={{minHeight: '83vh', display: 'flex', flexDirection: 'column'}}
              title={<Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/settings')}><span
                      style={{cursor: "pointer"}}><LeftOutlined/> Impostazioni</span></Breadcrumb.Item>
                  <Breadcrumb.Item>Riassicuratori</Breadcrumb.Item>
              </Breadcrumb>}
        >
            <Row justify={'space-between'} style={{marginBottom: '1rem'}}>
                <Col style={{width: '20rem'}}><Input.Search placeholder={'Filtra per nome...'} enterButton
                                                            defaultValue={searchTerm}
                                                            onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}/></Col>
                <Col><CreateNewElement buttonContent={"Crea riassicuratore"} placeholder={"Nome riassicuratore"}
                                       modalTitle={"Creazione riassicuratore"} addElement={addReinsurers}/></Col>
            </Row>
            <Divider/>
            <div style={{flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                {!!reinsurersData?.count &&
                    <><List
                        grid={{column: 2, gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2}}
                        loading={!reinsurersData || isReinsurerFetching || isReinsurerLoading}
                        dataSource={reinsurersData?.results}
                        renderItem={item => <List.Item
                            style={{borderBottom: '1px solid rgba(0, 0, 0, 0.06)', paddingBottom: '0.1rem'}}>
                            <EditName id={item.uuid} text={item.name ? item.name : ""}
                                      updateText={(name) => updateReinsurers(name, item.uuid)}
                                      isOneItemModifing={isOneItemModifing}
                                      setIsOneItemModifing={setIsOneItemModifing}/>
                        </List.Item>
                        }
                    />
                      <Row style={{marginTop: '2.5rem'}}
                           justify={'end'}><Pagination
                          simple
                          total={reinsurersData.count}
                          current={currentPage}
                          pageSize={pageSize}
                          onChange={page => {
                              setIsOneItemModifing({status: false, key: ""})
                              setCurrentPage(page)
                              searchTerm ? fetchReinsurerData(page, searchTerm) : fetchReinsurerData(page)
                          }}
                      /></Row></>}
                {reinsurersData?.count === 0 && searchTerm && <p>La ricerca con "<span style={{fontStyle: 'italic'}}>{searchTerm}</span>" come chiave di ricerca non ha prodotto risultati</p>}
                {reinsurersData?.count === 0 && !searchTerm && <p>Non sono presenti broker</p>}
            </div>
        </Card>
    </div>
}

