import {Alert, message, Modal, Skeleton} from "antd";
import { NegotiationModel, UpdateNegotiationRequest } from "types/negotiations";

import { Survey as SurveyReact } from "survey-react-ui";
import * as Survey from "survey-react";

import NumberFormat from "react-number-format";
import { Question, SurveyModel } from "survey-react";
import { useCallback, useEffect, useState } from "react";
import {useLazyGetChecklistQuery, useUpdateChecklistMutation} from "redux/api/checklistApiSlice"
    ;
import { debounce } from "lodash";

function CustomNumericInput({ question }: { question: Question }) {
    return (
        <NumberFormat
            className={`ant-input ant-item-form ${question.isReadOnly ? 'ant-input-disabled' : ''}`}
            value={question.value}
            displayType={"input"}
            decimalSeparator={","}
            thousandSeparator={"."}
            placeholder={"0.00 €"}
            suffix={" €"}
            fixedDecimalScale={true}
            readOnly={question.isReadOnly}
            decimalScale={2}
            onValueChange={(values) => {
                question.value = values.floatValue
            }}
        />
    )
}

function CustomPercentageInput({ question }: { question: Question }) {
    return (
        <NumberFormat
            className={`ant-input ant-item-form ${question.isReadOnly ? 'ant-input-disabled' : ''}`}
            value={question.value}
            displayType={"input"}
            decimalSeparator={","}
            thousandSeparator={"."}
            placeholder={"0 %"}
            suffix={" %"}
            readOnly={question.isReadOnly}
            onValueChange={(values) => {
                question.value = values.floatValue
            }}
        />
    )
}


export function ChecklistFormDialog({
    isOpen,
    onClose,
    negotiation,
    updateNegotiation,
}: { isOpen: boolean, onClose: () => void, negotiation: NegotiationModel, updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => void }) {

    const [survey, setSurvey] = useState(new Survey.Model())
    const [updateChecklist] = useUpdateChecklistMutation()
    const debounceUpdate = useCallback(debounce(({ uuid, data }: { uuid: string, data: object }) => updateChecklist({ uuid: uuid, data: data }), 500), [])
    const debounceMessage = useCallback(debounce((success: boolean) => success ? message.success('Checklist aggiornata') : message.error('Checklist non aggiornata'), 500), [])
    const [getChecklist, {
        data: checklist,
        isLoading: checklistIsLoading,
        isFetching: checklistIsFetching
    }] = useLazyGetChecklistQuery()

    useEffect(() => {
        // getData
        if (isOpen){
            getChecklist({ uuid: negotiation.uuid })
        }
    }, [getChecklist, negotiation.uuid, isOpen])

    useEffect(() => {
        // set jsonSchem
        if (checklist?.jsonSchema) {
            const updatedSurvey = new Survey.Model(checklist?.jsonSchema)
            updatedSurvey.onValueChanged.add(() => {
                let results = updatedSurvey.data
                checklist?.jsonSchema.pages[0].elements.forEach((el: Survey.Question) => {
                    if (!(el.name in updatedSurvey.data)) {
                        results[el.name] = null
                    }
                })
                debounceUpdate({ uuid: negotiation.uuid, data: results })
                    ?.unwrap()
                    .then(() => debounceMessage(true))
                    .catch(() => debounceMessage(false))

            });
            updatedSurvey.focusFirstQuestionAutomatic = false;
            updatedSurvey.setCss(
                {
                    container: "ant-form ant-form-vertical",
                    row: "ant-row ant-form-item",
                    question: {
                        header: "ant-col ant-form-item-label ant-form-item-required",
                        title: "ant-form ant-form-item-label question-title",
                        content: "ant-col ant-form-item-control",
                        description: "ant-form-item-explain",
                        hasError: "ant-form-item-explain-error",
                    },
                    text: {
                        root: "ant-input",
                    },
                    radiogroup: {
                        item: "ant-radio-wrapper ant-radio-wrapper-in-form-item",
                        materialDecorator: "ant-radio",
                    },
                    comment: {
                        root: "ant-input ant-input-textarea ant-input-textarea-in-form-item",
                    },
                }
            )
            setSurvey(updatedSurvey)
        }
    }, [checklist?.jsonSchema, debounceUpdate, negotiation.uuid])

    useEffect(() => {
        // add custom widget
        Survey.CustomWidgetCollection.Instance.addCustomWidget({
            name: 'customnumeric',
            title: 'custom Numeric',
            render: (question: Question) => {
                return <CustomNumericInput question={question} />
            },
            isFit: (question: Question) => {
                return question.name.charAt(0) === '$';
            },
        }, "customnumeric")
        Survey.CustomWidgetCollection.Instance.addCustomWidget({
            name: 'custompercentage',
            title: 'custom Percentage',
            render: (question: Question) => {
                return <CustomPercentageInput question={question} />
            },
            isFit: (question: Question) => {
                return question.name.charAt(0) === '%';
            },
        }, "custompercentage")
    }, [])

    useEffect(() => {
        // set data
        if (checklist?.data) {
            survey.data = checklist.data
        }
    }, [checklist?.data, survey])

    return (
        <div className='checklist-modal'>
            <Modal
                title="Checklist"
                centered
                open={isOpen}
                onOk={onClose}
                onCancel={onClose}
                footer={null}
            >
                <Alert
                    style={{marginBottom: '1rem'}}
                    message={"I campi disabilitati recuperano automaticamente i dati dal form della trattativa. La modifica di quei campi deve avvenire nel form."}
                    type={"info"} showIcon/>
                {!(checklistIsLoading || checklistIsFetching) && <SurveyReact model={survey} />}
                {(checklistIsLoading || checklistIsFetching) && <Skeleton style={{ height: '100vh' }} paragraph={{ rows: 40 }} />}
            </Modal>
        </div>
    )
}