import { useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {BudgetGroup, UserTypes} from "../../types";
import PortfolioDashboard from "../../components/dashboard/PortfolioDashboard";
import React, {useState} from "react";
import UnderwriterDashboard from "components/dashboard/UnderwriterDashboard";
import {canSeePortfolioCharts, canSeeUWCharts} from "../../utils/permission";
import {Col, Row} from "antd";


export default function Dashboard() {

    const user = useSelector(selectUser)
    const [budgetGroups, setBudgetGroups] = useState<BudgetGroup[]>(user.budget_groups)

    // <div style={{padding: '20px 3.75rem'}}>

    return <Row justify={'space-evenly'}>
            {user.usertypes && user.usertypes.length && canSeePortfolioCharts(user.usertypes.map(el=> el.code)) && <Col style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}} xs={24} sm={22} lg={23}><PortfolioDashboard user={user} budgetGroups={budgetGroups}/></Col>}
            {user.usertypes && user.usertypes.length && canSeeUWCharts(user.usertypes.map(el=> el.code)) && <Col style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}} xs={24} sm={22} lg={23}><UnderwriterDashboard user={user} budgetGroups={budgetGroups} /></Col>}
        </Row>
}