import { useEffect, useState } from 'react';

import { Typography, Col, Row, Card, Input, Button, } from 'antd';

import { useTranslation } from 'react-i18next';
import { RightOutlined, HistoryOutlined, EditOutlined, FormOutlined, CheckOutlined, } from '@ant-design/icons';


import { StatusIndicator } from '../utils';
import { NegotiationDetailStatusPropsType, NegotiationStatusViewBaseProps } from '..';

const { Title, Paragraph } = Typography


export function PortfolioNegotiationTakenOverBy({ negotiation, options, updateNegotiation }: NegotiationDetailStatusPropsType) {

    const [uploadName, setUploadName] = useState<string>('')
    const [unMountName, setUnmountName] = useState<string>('')
    const [isUploadNameEdit, setIsUploadNameEdit] = useState<boolean>(false)
    const [isUnmountNameEdit, setIsUnmountNameEdit] = useState<boolean>(false)

    useEffect(() => {
        setUploadName(negotiation.portfolio_upload_user || '')
        setUnmountName(negotiation.portfolio_unmount_user || '')
    }, [negotiation])


    return (
        <Card bordered={false} style={{ height: '100%' }}  >

            <Row>
                <Paragraph>Presa in carico da</Paragraph>
            </Row>
            <Row justify='space-between' align='middle' >
                <Col>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Paragraph style={{ marginLeft: '16px' }}>
                            Caricamento
                        </Paragraph>
                        <span style={{ marginLeft: '16px' }}>

                            {!isUploadNameEdit ?
                                <Title level={5}>{uploadName || 'Nome e cognome'}</Title> :
                                <Input value={uploadName} autoFocus style={{ padding: 0 }} bordered={false} placeholder='Nome e cognome' onChange={(e) => {
                                    setUploadName(e.target.value);
                                    updateNegotiation({ portfolio_upload_user: e.target.value })
                                }} />}

                        </span>

                    </div>
                </Col>
                <Col>
                    <Title level={5}>
                        {isUploadNameEdit ? <CheckOutlined style={{ cursor: 'pointer', color: 'var(--primary-color, black)' }} onClick={() => setIsUploadNameEdit(false)} />
                            : <EditOutlined style={{ cursor: 'pointer', color: 'var(--primary-color, black)' }} onClick={() => setIsUploadNameEdit(true)} />}
                    </Title>
                </Col>


            </Row>

            <Row justify='space-between' align='middle' >
                <Col>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Paragraph>
                            Smontamento
                        </Paragraph>
                        <span style={{ marginLeft: '16px' }}>


                            {!isUnmountNameEdit ? <Title level={5} >{unMountName || 'Nome e cognome'}</Title> :
                                <Input value={unMountName} autoFocus style={{ padding: 0 }} bordered={false} placeholder='Nome e cognome' onChange={(e) => {
                                    setUnmountName(e.target.value);
                                    updateNegotiation({ portfolio_unmount_user: e.target.value })
                                }} />}

                        </span>

                    </div>
                </Col>
                <Col>
                    <Title level={5}>
                        {isUnmountNameEdit ? <CheckOutlined style={{ transition: 'all 1s ease', cursor: 'pointer', color: 'var(--primary-color, black)' }} onClick={() => setIsUnmountNameEdit(false)} />
                            : <EditOutlined style={{ transition: 'all 1s ease ', cursor: 'pointer', color: 'var(--primary-color, black)' }} onClick={() => {
                                setIsUnmountNameEdit(true)
                            }
                            } />}
                    </Title>
                </Col>
            </Row>
        </Card >
    )
}


export function SimplifiedNegotiationStatusView({ negotiation, options, fieldNotAvailableMessage, openRestoreState, openHistory, openMoveNegotiation, openEditNegotiation }: NegotiationStatusViewBaseProps) {

    const { available_state_transitions } = negotiation

    const { states } = options


    return (
        <Card bordered={false} style={{ height: '100%' }} >
            <Row justify="space-between" gutter={[8, 12]}>
                <Col span={24}>
                    <Row align='middle' justify='space-between'>
                        <Col>
                            <div className='btn-text' onClick={openRestoreState}>
                                <p style={{ color: 'inherit !important' }}>Stato Trattativa</p>
                                <HistoryOutlined style={{ marginLeft: '4px' }} />
                            </div>
                        </Col>
                        <Col>
                            <div className='btn-text' onClick={openHistory}>
                                <p style={{ color: 'inherit !important' }}>Storico</p>
                                <RightOutlined style={{ marginLeft: '4px' }} />
                            </div>

                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row align='middle' justify='space-between'>
                        <Col>
                            <StatusIndicator state={negotiation.state} options={options} />
                        </Col>
                        <Col>
                            {available_state_transitions.length === 1 ? (
                                <Col span={24}>
                                    <Button block type='primary' icon={<EditOutlined />} onClick={openMoveNegotiation}> {`Passa trattativa a ${states.find(state => state.value === available_state_transitions[0].state)?.text}`} </Button>
                                </Col>
                            )
                                : !!available_state_transitions.length && (
                                    <Col span={24}>
                                        <Button block type='primary' icon={<FormOutlined />} onClick={openEditNegotiation}> {'Modifica stato trattativa'} </Button>
                                    </Col>
                                )}

                        </Col>
                    </Row>
                </Col>
            </Row >

        </Card >
    )
}