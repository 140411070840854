import {Button, Col, Divider, Row} from "antd";
import React from "react";
import {LabeledValue} from "antd/lib/select";
import DelegatedInsurerMultiSelect from "../../select/MultiSelect/DelegatedInsurerMultiSelect";
import {FilterDropdownProps} from "antd/lib/table/interface";
import FilterContainer from "./FilterContainer";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter} from "./columnsUtils";

export default function DelegatedInsurerFilter(props: FilterDropdownProps) {

    return <FilterContainer {...props}>
        <DelegatedInsurerMultiSelect
            style={{width: '20rem'}}
            onChange={(values: LabeledValue[]) => {
                // @ts-ignore
                props.setSelectedKeys(values.reverse().map(el => ({label: el.label, value: el.value})))
                endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
            }}
            value={props.selectedKeys}
        />
    </FilterContainer>

}