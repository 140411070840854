import {Col, Divider, Row} from "antd";
import 'antd/es/spin/style/css';
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {euro} from "utils/utils";
import HighchartsReact from "highcharts-react-official";
import ChartContainer from "./ChartContainer";
import BarPercentageChart from "../HighCharts/BarPercentageChart";
import {useLazyGetRenewRetentionChartQuery} from "../../../redux/api/chartsApiSlice";
import {use} from "i18next";
import {selectUser} from "../../../redux/features/userSlice";

export default function RenewRetention(props: { chartRef: React.RefObject<HighchartsReact.RefObject> }) {
    const title = "Retention su rinnovi"
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetRenewRetentionChartQuery()
    const [percentageToDisplay, setPercentageToDisplay] = useState<string>('')
    const [renewables, setRenewables] = useState<string>('')
    const [averageRenewables, setAverageRenewables] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const description = <>
        Il grafico mostra, sulle trattative in rinnovo, la percentuale dei bound (come sommatoria dei premi) rispetto il
        totale che ha già superato la data di decorrenza.
        <dl>
            <dt>Premi rinnovabili:</dt>
            <dd>rappresenta la sommatoria dei premi che possono essere rinnovati.</dd>
            <dt>Rinnovo medio (90%):</dt>
            <dd>rappresenta il rinnovo medio target.</dd>
            <dt>Premi rinnovati con successo:</dt>
            <dd>rappresenta la sommatoria dei premi già rinnovati con successo.</dd>
        </dl>
    </>


    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (data) {
            setPercentageToDisplay(data.percentage ? (data?.percentage * 100).toFixed(2) : '0')
            //setAmountToDisplay(data.amount ? (data?.amount).toFixed(2) : '0')
            setRenewables(data.renewables ? (data?.renewables).toFixed(2) : '0')
            setAverageRenewables(data.average_renewables ? (data?.average_renewables).toFixed(2) : '0')
            setAmount(data.amount ? (data?.amount).toFixed(2) : '0')
        }
    }, [data])

    return <ChartContainer
        title={title}
        loading={isLoading || isFetching}
        description={description}
        /*buttons={<DownloadButton chartRef={props.chartRef}/>}*/>
        <BarPercentageChart chartRef={props.chartRef} options={{
            exporting: {
                chartOptions: {
                    title: {
                        text: title,
                    },
                    subtitle: {
                        text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}, Premi rinnovabili: ${renewables}, Rinnovo medio (90%): ${averageRenewables}, Premi rinnovati con successo: ${amount}`
                    },
                    chart: {
                        height: 150,
                        spacing: [10, 10, 15, 10],
                        // margin: [undefined, undefined, undefined, undefined]
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true,
                                x: 0,
                                y: -25
                            },
                        },
                    },

                },
            },
            series: [
                {
                    type: 'bar',
                    stacking: 'percent',
                    data: [100 - Number(percentageToDisplay)],
                    color: '#F5F5F5',
                    dataLabels: {
                        enabled: false,
                    },
                },
                {
                    type: 'bar',
                    stacking: 'percent',
                    data: Array.from({length: 1}, () => Number(percentageToDisplay)),
                    color: '#BEA8FB',
                    dataLabels: {
                        enabled: true,
                        format: `{y}%`
                    },
                },

            ]
        }}/>
        <Divider style={{marginTop: 0, marginBottom: '12px'}}/>
        <Row justify={'space-between'}>
            <Col>Premi rinnovabili:</Col>
            <Col style={{fontWeight: 'bolder'}}>{euro(Number(renewables))}</Col>
        </Row>
        <Row justify={'space-between'}>
            <Col>Rinnovo medio (90%):</Col>
            <Col style={{fontWeight: 'bolder'}}>{euro(Number(averageRenewables))}</Col>
        </Row>
        <Row justify={'space-between'}>
            <Col>Premi rinnovati chiusi con successo:</Col>
            <Col style={{fontWeight: 'bolder'}}>{euro(Number(amount))}</Col>
        </Row>


    </ChartContainer>
}